<template>
  <v-form>
    <v-autocomplete
      v-if="users"
      v-model="form.user_id"
      :error-messages="errors.user_id"
      :items="users"
      item-text="full_name"
      item-value="id"
      label="Выберите пользователя"
      name="user_id"
      required
    ></v-autocomplete>
    <v-text-field
      v-model="form.user_name"
      :error-messages="errors.user_name"
      label="ФИО пользователя (при отсутствии в списке)"
      name="user_name"
      required
    ></v-text-field>
    <v-autocomplete
      v-if="types"
      v-model="form.type_id"
      :error-messages="errors.type_id"
      :items="types"
      item-text="title"
      item-value="id"
      label="Выберите тип отзыва"
      name="type_id"
      required
    ></v-autocomplete>
    <v-text-field
      v-model="form.reviewable_id"
      :error-messages="errors.reviewable_id"
      label="ID сущности"
      name="reviewable_id"
      required
    ></v-text-field>
    <v-text-field
      v-model="form.reviewable_type"
      :error-messages="errors.reviewable_type"
      label="Класс сущности"
      name="reviewable_type"
      required
    ></v-text-field>
    <v-textarea
      v-model="form.text"
      :error-messages="errors.text"
      label="Текст отзыва"
      name="text"
      required
    ></v-textarea>
    <v-btn
      class="mr-4"
      color="primary"
      :disabled="isSaveDisabled"
      :loading="isSaveDisabled"
      @click="submit(true)"
    >
      Сохранить
    </v-btn>
    <v-btn
      class="mr-4"
      color="primary"
      :disabled="isSaveDisabled"
      :loading="isSaveDisabled"
      @click="submit(false)"
    >
      Сохранить и продолжить
    </v-btn>
  </v-form>
</template>

<script>
import {User} from "../../models/user";
import {ReviewsType} from "../../models/reviewsType";

export default {
  name: 'ReviewForm',
  props: {
    review: {
      type: Object,
      default: () => ({})
    },
    errors: {
      type: Object,
      required: true,
    },
    isEdit: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    form: {
      id: null,
      text: null,
      user_id: null,
      reviewable_id: null,
      reviewable_type: null,
      type_id: null,
      user_name: null,
    },
    isSaveDisabled: false,
    types: [],
    users: [],
  }),
  created() {
    this.getTypes();
    this.getUsers();
  },
  methods: {
    async submit(isNeedRedirect) {
      this.isSaveDisabled = true;
      this.$emit('onSubmit', {
        form: this.form,
        isNeedRedirect: isNeedRedirect,
      });
    },
    async getTypes() {
      try {
        const types = await ReviewsType.$query().get(10000);
        this.types = types.map(person => person.$attributes);
        this.loading = false;
      } catch (e) {
        throw e;
      }
    },
    async getUsers() {
      try {
        const users = await User.$query().get(10000);
        this.users = users.map(person => person.$attributes);
        this.loading = false;
      } catch (e) {
        throw e;
      }
    },
  },
  watch: {
    review: {
      handler(review) {
        if (review && Object.keys(review).length > 0) {
          this.form = review;
          this.isSaveDisabled = false;
        }
      },
      immediate: true,
      deep: true,
    },
  },
};
</script>
