import axiosInstance from "../../../axiosInstance";
import {Block} from "../../../models/block";

export default {
  namespaced: true,
  state: {
    fetchedListing: [],
    listingTotalItems: 0,
    isListingLoading: false,
    actionButtonDisabled: false,
    fetchedDetail: {},
  },
  getters: {
    getFetchedListing: (state) => state.fetchedListing,
    getListingTotalItems: (state) => state.listingTotalItems,
    isListingLoading: (state) => state.isListingLoading,
    getActionButtonDisabled: (state) => state.actionButtonDisabled,
    getFetchedDetail: (state) => state.fetchedDetail,
  },
  mutations: {
    setFetchedListing(state, value) {
      state.fetchedListing = value;
    },
    setListingTotalItems(state, value) {
      state.listingTotalItems = value;
    },
    setListingLoading(state, value) {
      state.isListingLoading = value;
    },
    setActionButtonDisabled(state, value) {
      state.actionButtonDisabled = value;
    },
    setFetchedDetail(state, value) {
      state.fetchedDetail = value;
    }
  },
  actions: {
    async fetchListing({ commit }, { options, only_trashed, search }) {
      commit('setListingLoading', true);

      const {sortBy, sortDesc, page, itemsPerPage} = options;
      const onlyTrashed = only_trashed ? `&only_trashed=1` : '';
      let data = {};

      search ? data.search = {value: search} : null;
      sortBy && sortBy[0] ? data.sort = [{field: sortBy[0], direction: sortDesc[0] ? 'desc' : 'asc'}] : null;

      await axiosInstance.post(`/module/blocks/search?limit=${itemsPerPage}&page=${page}${onlyTrashed}`, data).then((response) => {
        commit('setFetchedListing', response.data.data);
        commit('setListingTotalItems', response.data.meta.total);
      })

      commit('setListingLoading', false);
    },
    async destroyItem({ commit, dispatch }, { id }) {
      commit('setActionButtonDisabled', true);

      await Block.$query().destroy(id, 0).then(() => {
        dispatch('snackbar/showSuccessfullyDelete', null, { root: true });
      }).catch(() => {
        dispatch('snackbar/showDeleteError', null, { root: true });
      })

      commit('setActionButtonDisabled', false);
    },
    async restoreItem({ commit, dispatch }, { id }) {
      commit('setActionButtonDisabled', true);

      await Block.$query().restore(id).then(() => {
        dispatch('snackbar/showSuccessfullyRestore', null, { root: true });
      }).catch(() => {
        dispatch('snackbar/showErrorRestore', null, { root: true });
      })

      commit('setActionButtonDisabled', false);
    },
    async fetchDetail({ commit, dispatch }, id) {
      commit('setActionButtonDisabled', true)

      Block.$query().find(id).then((response) => {
        commit('setFetchedDetail', response.$attributes);
      }).catch(() => {
        dispatch('snackbar/showUnknownError', null, { root: true });
      })

      commit('setActionButtonDisabled', false);
    },
    async updateItem({ commit, dispatch }, form) {
      commit('setActionButtonDisabled', true);

      Block.$query().update(form.id, form).then((response) => {
        commit('setFetchedDetail', response.$attributes);
        dispatch('snackbar/showSuccessfullySaved', null, { root: true });
      }).catch(() => {
        dispatch('snackbar/showSaveError', null, { root: true });
      })

      commit('setActionButtonDisabled', false);
    },
    async storeItem({ commit, dispatch }, { form, router, isNeedRedirect }) {
      commit('setActionButtonDisabled', true);

      Block.$query().store(form).then((response) => {
        dispatch('snackbar/showSuccessfullySaved', null, { root: true });
        commit('setActionButtonDisabled', false);

        if (isNeedRedirect)
          router.push({ name: 'blocks-index'});
        else
          router.push({ name: 'blocks-edit', params: { id: response.$attributes.id } });
      }).catch(() => {
        dispatch('snackbar/showSaveError', null, { root: true });
      })

      commit('setActionButtonDisabled', false);
    }
  }
}
