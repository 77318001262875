import {SortDirection} from "@tailflow/laravel-orion/lib/drivers/default/enums/sortDirection";
import {ChimeraLesson} from "../../../models/chimeraLesson";
import axiosInstance from "../../../axiosInstance";

export default {
  namespaced: true,
  state: {
    fetchedListing: [],
    listingTotalItems: 0,
    isLoading: false,
    fetchedDetail: {},
    childrens: [],
    childrenDetail: {},
  },
  getters: {
    getFetchedListing: (state) => state.fetchedListing,
    getListingTotalItems: (state) => state.listingTotalItems,
    getIsLoading: (state) => state.isLoading,
    getFetchedDetail: (state) => state.fetchedDetail,
    getChildrens: (state) => state.childrens,
    getChildrenDetail: (state) => state.childrenDetail,
  },
  mutations: {
    setFetchedListing(state, value) {
      state.fetchedListing = value;
    },
    setListingTotalItems(state, value) {
      state.listingTotalItems = value;
    },
    setIsLoading(state, value) {
      state.isLoading = value;
    },
    setFetchedDetail(state, value) {
      state.fetchedDetail = value;
    },
    setChildrens(state, value) {
      state.childrens = value;
    },
    setChildrenDetail(state, value) {
      state.childrenDetail = value;
    }
  },
  actions: {
    fetchListing({commit, dispatch}, {options, search, only_trashed, course_id}) {
      commit('setIsLoading', true);

      const {sortBy, sortDesc, page} = options;

      let queryBuilder = ChimeraLesson
        .$query()
        .lookFor(search)
        .scope('sortPosition')
        .filter('course_id', '=', course_id)
        .filter('slug', 'not like', '%-lesson-0')
        .filter('parent_id', '=', null);

      if (only_trashed) {
        queryBuilder.onlyTrashed();
      }

      if (sortBy[0]) {
        queryBuilder.sortBy(sortBy[0], sortDesc[0] ? SortDirection.Desc : SortDirection.Asc)
      }

      queryBuilder.search(10000000, page).then((response) => {
        if (response.length > 0) {
          commit('setListingTotalItems', response[0].$response.data.meta.total);
        } else {
          commit('setListingTotalItems', 0);
        }

        commit('setFetchedListing', response.map((item) => item.$attributes));
      }).catch(() => {
        dispatch('snackbar/showUnknownError', null, {root: true})
      }).finally(() => {
        commit('setIsLoading', false);
      })
    },
    storeLesson({commit, dispatch}, {form, needRedirect, router}) {
      commit('setIsLoading', true);

      return ChimeraLesson.$query().store(form).then((response) => {
        dispatch("snackbar/showSuccessfullySaved", null, {root: true});
        if (needRedirect) {
          if (form.parent_id) {
            router.push({ name: 'chimera-lessons-edit', params: { id: router.currentRoute.params.id, lessonID: form.parent_id }});
          } else {
            router.push({name: 'chimera-lessons-index', params: {id: router.currentRoute.params.id}});
          }
        } else {
          router.push({
            name: 'chimera-lessons-edit',
            params: {lessonID: response.$attributes.id, id: router.currentRoute.params.id},
            query: router.currentRoute.query,
          })
        }

      }).catch(() => {
        dispatch("snackbar/showUnknownError", null, {root: true})
      }).finally(() => {
        commit('setIsLoading', true);
      })
    },
    fetchDetail({commit, dispatch}, lessonID) {
      commit('setIsLoading', true);

      ChimeraLesson.$query().with(['collectionItems']).find(lessonID).then((response) => {
        commit('setFetchedDetail', response.$attributes);
      }).catch(() => {
        dispatch('snackbar/showUnknownError', null, {root: true})
      }).finally(() => {
        commit('setIsLoading', false);
      })
    },
    updateLesson({commit, dispatch}, {form, needRedirect, router, isChildren}) {
      commit('setIsLoading', true);

      return ChimeraLesson.$query().update(form.id, form).then(async (response) => {
        if (needRedirect) {
          if (form.parent_id) {
            await router.push({ name: 'chimera-lessons-edit', params: { id: router.currentRoute.params.id, lessonID: form.parent_id }, query: { tab: 1 }});
            window.location.reload();
          } else {
            router.push({name: 'chimera-lessons-index', params: {id: router.currentRoute.params.id}});
          }
        }
        if (!isChildren) {
          commit('setFetchedDetail', response.$attributes);
        }
        dispatch('snackbar/showSuccessfullySaved', null, {root: true})
      }).catch(() => {
        dispatch('snackbar/showSaveError', null, {root: true})
      }).finally(() => {
        commit('setIsLoading', false);
      })
    },
    deleteItem({commit, dispatch}, {id, isForce}) {
      commit('setIsLoading', true);

      return ChimeraLesson.$query().destroy(id, isForce).then(() => {
        dispatch("snackbar/showSuccessfullyDelete", null, {root: true})
      }).catch(() => {
        dispatch("snackbar/showDeleteError", null, {root: true})
      }).finally(() => {
        commit('setIsLoading', false);
      })
    },
    restoreItem({commit, dispatch}, id) {
      commit('setIsLoading', true);

      return ChimeraLesson.$query().restore(id).then(() => {
        dispatch("snackbar/showSuccessfullyRestore", null, {root: true})
      }).catch(() => {
        dispatch("snackbar/showErrorRestore", null, {root: true})
      }).finally(() => {
        commit('setIsLoading', false);
      })
    },
    storePositions({commit, dispatch}, lessons) {
      commit('setIsLoading', true);

      return axiosInstance.patch(`/module/chimera-courses/lessons/batch`, {resources: lessons}).then(() => {
        dispatch("snackbar/showSuccessfullySaved", null, {root: true});
      }).catch(() => {
        dispatch("snackbar/showSaveError", null, {root: true});
      }).finally(() => {
        commit('setIsLoading', false);
      });
    },
    fetchChildrens({commit, dispatch}, {lessonID, onlyTrashed}) {
      commit('setIsLoading', true);

      let query = ChimeraLesson.$query();

      if (onlyTrashed) {
        query.onlyTrashed();
      }

      query.filter('parent_id', '=', lessonID).search(10000).then((resp) => {
        commit('setChildrens', resp.map((item) => item.$attributes));
      }).catch(() => {
        dispatch("snackbar/showUnknownError", null, {root: true})
      }).finally(() => {
        commit('setIsLoading', false);
      })
    },
    fetchChildrenDetail({commit, dispatch}, lessonID) {
      commit('setIsLoading', true);

      ChimeraLesson.$query().find(lessonID).then((response) => {
        commit('setChildrenDetail', response.$attributes);
      }).catch(() => {
        dispatch('snackbar/showUnknownError', null, {root: true})
      }).finally(() => {
        commit('setIsLoading', false);
      })
    },
    resetChildrenDetailPage({commit}) {
      commit('setChildrenDetail', {});
    },
    async submitCollections({dispatch, state}, {items}) {
      await axiosInstance.post(`module/chimera-courses/lessons/${state.fetchedDetail.id}/collection-items/sync`, {items: items}).then(() => {
        dispatch('snackbar/showSuccessfullySaved', null, {root: true})
        dispatch('fetchDetail', state.fetchedDetail.id)
      }).catch(() => {
        dispatch('snackbar/showSuccessfullySaved', null, {root: true});
      })
    },
    async makeMainCollectionItem({dispatch, state}, {items}) {
      await axiosInstance.post(`module/chimera-courses/lessons/${state.fetchedDetail.id}/collection-items/make-main`, {items: items}).then(() => {
        dispatch('snackbar/showSuccessfullySaved', null, {root: true})
        dispatch('fetchDetail', state.fetchedDetail.id)
      }).catch(() => {
        dispatch('snackbar/showSuccessfullySaved', null, {root: true});
      })
    },
  }
}
