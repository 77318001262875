<template>
  <v-row>
    <v-col cols="12">
      <div class="mb-5">
        <v-row class="mt-2">
          <v-toolbar flat>
            <v-switch label="Только удаленные" v-model="onlyTrashed"/>
            <v-spacer></v-spacer>
            <v-btn class="primary" :href="`/chimera-courses/${$route.params.id}/lessons/create?parent_id=${$route.params.lessonID}`">
              <v-icon class="mr-2" >mdi-clipboard-list</v-icon>
              Добавить подстраницу
            </v-btn>
          </v-toolbar>
        </v-row>
        <v-row>
          <v-col cols="3" class="mt-5" v-for="lesson in childrens" :key="lesson.id">
            <v-card elevation="2">
              <v-img :src="lesson.image" height="250"/>
              <v-card-title>{{ lesson.title }}</v-card-title>
              <v-card-actions>
                <v-btn icon color="primary" @click="editLesson(lesson.id)" v-if="!lesson.deleted_at">
                  <v-icon>mdi-pen</v-icon>
                </v-btn>
                <v-btn icon color="primary" :href="lesson.client_url" target="_blank" v-if="!lesson.deleted_at">
                  <v-icon>mdi-eye</v-icon>
                </v-btn>
                <v-btn icon v-if="!lesson.deleted_at" @click="deleteItem(lesson.id, false)">
                  <v-icon color="red">
                    mdi-delete
                  </v-icon>
                </v-btn>
                <v-btn v-else icon @click="restoreItem(lesson.id)">
                  <v-icon color="green">
                    mdi-backup-restore
                  </v-icon>
                </v-btn>
                <v-btn icon v-if="lesson.deleted_at" @click="deleteItem(lesson.id, true)">
                  <v-icon color="red">
                    mdi-delete
                  </v-icon>
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </div>
    </v-col>
    <v-col>

    </v-col>
  </v-row>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  name: "SubLessonComponent",
  data: () => ({
    onlyTrashed: false,
  }),
  created() {
    this.fetchChildrens({ lessonID: this.$route.params.lessonID, onlyTrashed: this.onlyTrashed });
  },
  computed: {
    ...mapGetters({
      childrens: "ChimeraLessons/getChildrens",
      childrenDetail: "ChimeraLessons/getChildrenDetail",
    })
  },
  methods: {
    ...mapActions({
      fetchChildrens: "ChimeraLessons/fetchChildrens",
      fetchChildrenDetail: "ChimeraLessons/fetchChildrenDetail",
      resetChildrenDetailPage: "ChimeraLessons/resetChildrenDetailPage",
      updateLesson: "ChimeraLessons/updateLesson",
      createLesson: "ChimeraLessons/storeLesson",
      deleteLesson: "ChimeraLessons/deleteItem",
      restoreLesson: "ChimeraLessons/restoreItem",
    }),
    fetchDetail(id) {
      this.fetchChildrenDetail(id);
    },
    resetForm() {
      this.resetChildrenDetailPage();
      this.$refs.lessonForm.resetForm();
      this.$refs.lessonForm.setParentId(this.$route.params.lessonID);
    },
    submit({ form }) {
      if (form.id) {
        this.updateLesson({
          form: form,
          needRedirect: false,
          router: this.$router,
          isChildren: true,
        }).then(() => {
          this.resetForm();
          this.fetchChildrens({ lessonID: this.$route.params.lessonID, onlyTrashed: this.onlyTrashed });
        })
      } else {
        this.createLesson({
          form: form,
          needRedirect: false,
          router: this.$router,
          isChildren: true,
        }).then(() => {
          this.resetForm();
          this.fetchChildrens({ lessonID: this.$route.params.lessonID, onlyTrashed: this.onlyTrashed });
        })
      }
    },
    deleteItem(id, isForce) {
      this.deleteLesson({ id: id, isForce: isForce }).then(() => {
        this.fetchChildrens({ lessonID: this.$route.params.lessonID, onlyTrashed: this.onlyTrashed });
      });
    },
    restoreItem(id) {
      this.restoreLesson(id).then(() => {
        this.fetchChildrens({ lessonID: this.$route.params.lessonID, onlyTrashed: this.onlyTrashed });
      })
    },
    editUrl(id) {
      return `/chimera-courses/${this.$route.params.id}/lessons/edit/${id}?parent_id=${this.$route.params.lessonID}`;
    },
    async editLesson(id) {
      await this.$router.push(this.editUrl(id));
      window.location.reload();
    }
  },
  watch: {
    onlyTrashed: {
      handler() {
        this.fetchChildrens({ lessonID: this.$route.params.lessonID, onlyTrashed: this.onlyTrashed });
        this.resetForm();
      }
    }
  }
}
</script>

<style scoped>

</style>
