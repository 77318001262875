<template>
  <div>
    <v-row>
      <v-col cols="8">
        <v-row>
          <v-col>
            <h3>Элементы коллекции</h3>
          </v-col>
          <v-col>
            <v-checkbox
              v-model="only_trashed"
              label="Показать удаленные"
              name="only_trashed"
              value="1"
            ></v-checkbox>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-data-table
              :headers="headers"
              :items="items"
            >
              <template v-slot:item.actions="{ item }">
                <v-icon
                  class="mr-2"
                  color="primary"
                  @click="editItem(item)"
                >
                  mdi-pencil
                </v-icon>
                <v-icon
                  v-if="item.deleted_at"
                  color="green"
                  @click="restoreItem(item)"
                >
                  mdi-backup-restore
                </v-icon>
                <v-icon
                  v-else
                  color="red"
                  @click="deleteItem(item)"
                >
                  mdi-delete
                </v-icon>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="4">
        <h3>Форма</h3>
        <v-form>
          <v-text-field
            v-model="form.title"
            :error-messages="errors.title"
            label="Заголовок"
            name="title"
            required
          ></v-text-field>
          <v-text-field
            v-model="form.slug"
            :error-messages="errors.slug"
            label="Slug"
            name="slug"
            :disabled="form.id"
          ></v-text-field>
          <v-text-field
            v-model="form.color_code"
            :error-messages="errors.color_code"
            label="Цветовой код"
            name="color_code"
            required
          ></v-text-field>
          <v-select
            v-model="form.parent_id"
            :error-messages="errors.parent_id"
            :items="items"
            label="Родительский элемент"
            item-text="title"
            item-value="id"
            name="parent_id"
            required
          ></v-select>
          <v-checkbox
            v-model="form.publish"
            label="Опубликовано"
          />
          <media-widget
            class="mb-1"
            label="Изображение"
            :medias="form.image"
            :is-multiple="false"
            @onChange="setImage"
          />
          <media-widget
            class="mb-1"
            label="Иллюстрация"
            :medias="form.illustration_image"
            :is-multiple="false"
            @onChange="setIllustrationImage"
          />
          <v-btn
            color="primary"
            :disabled="isSaveDisabled"
            :loading="isSaveDisabled"
            @click="submit"
          >
            Сохранить
          </v-btn>
        </v-form>
      </v-col>
    </v-row>
    <media-uploader/>
  </div>
</template>

<script>
import {CollectionItem} from "../../models/collectionItem";
import MediaUploader from "../MediaUploader.vue";
import MediaWidget from "../MediaWidget.vue";
import slugify from "slugify";
import optionsMixin from "../../mixins/optionsMixin";

export default {
  name: "CollectionItemTabForm",
  mixins: [optionsMixin],
  components: {
    MediaUploader,
    MediaWidget,
  },
  props: {
    collectionId: {
      type: Number,
      required: true
    },
    errors: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    only_trashed: null,
    form: {
      id: null,
      title: null,
      slug: null,
      language: null,
      collection_id: null,
      parent_id: null,
      color_code: null,
      image: null,
      illustration_image: null,
      publish: false,
    },
    languages: [
      {text: 'Русский', value: 'ru'},
      {text: 'English', value: 'en'},
    ],
    headers: [
      {text: 'Id', value: 'id'},
      {text: 'Заголовок', value: 'title'},
      {text: 'Родительский элемент', value: 'parent.title'},
      {text: 'Дата создания', value: 'created_at'},
      {text: 'Дата обновления', value: 'updated_at'},
      {text: '', value: 'actions'},
    ],
    items: [],
    isSaveDisabled: false,
  }),
  created() {
    this.form.collection_id = this.collectionId;
    this.get();
  },
  mounted() {
    this.form.language = this.language;
  },
  methods: {
    async submit() {
      this.isSaveDisabled = true;
      try {
        this.errors = {};
        if (this.form.id) {
          await CollectionItem.$query().update(this.form.id, this.form);
        } else {
          await CollectionItem.$query().store(this.form);
        }
        await this.get();
        this.form = {
          id: null,
          title: null,
          slug: null,
          language: null,
          image: null,
          collection_id: this.collectionId,
        }
        this.form.language = this.language;
      } catch (e) {
        if (e.response?.data) {
          this.errors = e.response.data.errors
        }
        throw e;
      }
      this.isSaveDisabled = false;
    },
    editItem(item) {
      this.find(item.id);
    },
    async deleteItem(item) {
      this.$confirm('Вы уверены?', 'Удаление').then(() => {
        CollectionItem.$query().destroy(item.id, 0);
        this.get();
      });
    },
    async restoreItem(item) {
      await CollectionItem.$query().restore(item.id);
      await this.get();
    },
    async get() {
      let items = [];

      if (this.only_trashed) {
        items = await CollectionItem.$query()
          .onlyTrashed()
          .filter('collection_id', '=', this.collectionId)
          .search(10000);
      } else {
        items = await CollectionItem.$query()
          .filter('collection_id', '=', this.collectionId)
          .search(10000);
      }

      this.items = items.map(collectionItem => collectionItem.$attributes);
    },
    async find(id) {
      const collectionItem = await CollectionItem.$query().find(id);
      this.form = collectionItem.$attributes;
    },
    setImage(image) {
      this.form.image = image;
    },
    setIllustrationImage(image) {
      this.form.illustration_image = image;
    },
  },
  watch: {
    only_trashed: {
      handler() {
        this.get();
      }
    },
    'form.title': {
      handler(value) {
        if (value && !this.form.id) {
          this.form.slug = slugify(value.toLowerCase()).match(/[a-z]|[0-9]|[-]/gm).join('');
        }
      }
    }
  },
}
</script>
