<template>
  <div
    class="pa-8"
    style="width: 650px;"
  >
    <vue-editor
      ref="editor"
      :config="config"
      :initialized="onInitialized"
    />
    <v-btn
      v-if="isShowSave"
      color="primary"
      @click="submit"
    >
      Сохранить
    </v-btn>
  </div>
</template>

<script>
import Undo from 'editorjs-undo';
import Header from 'editorjs-header-with-anchor';
import List from '@editorjs/list';
import CodeTool from '@editorjs/code';
import Embed from '@editorjs/embed';
import Table from '@editorjs/table';
import Marker from '@editorjs/marker';
import Warning from '@editorjs/warning';
import RawTool from '@editorjs/raw';
import Quote from '@editorjs/quote';
import InlineCode from '@editorjs/inline-code';
import Delimiter from '@editorjs/delimiter';
// import ImageTool from '@editorjs/image';
import Alert from 'editorjs-alert';
import Paragraph from 'editorjs-paragraph-with-alignment';
import Hyperlink  from 'editorjs-hyperlink';
import ImageTool from './vue-editor/plugins/image-tool/image-tool.js';
import AnyButton from './vue-editor/plugins/button/button';
// import Personality from './vue-editor/plugins/personality/personality';
import VueEditor from "./vue-editor/VueEditor";
import WidgetLibrary from './vue-editor/plugins/widget-library/widgetLibrary';
import dynamicPersons from './vue-editor/plugins/dynamic-persons/dynamicPersons';
import dynamicPartners from './vue-editor/plugins/dynamic-partners/dynamicPartners';
import titles from './vue-editor/plugins/titles/titles';
import Products from './vue-editor/plugins/products/products';
import Checklist from './vue-editor/plugins/checklist/checklist';
import Forms from './vue-editor/plugins/forms/forms';
import Contacts from './vue-editor/plugins/contacts/contacts';
import EmojiList from './vue-editor/plugins/emoji-list/emoji-list';
import Smiles from './vue-editor/plugins/smiles/smiles';
import ContactsV2 from './vue-editor/plugins/contacts-v2/contacts-v2';

export default {
  name: 'MediaForm',
  components: {VueEditor},
  props: {
    isShowSave: {
      type: Boolean,
      default: true,
    },
    structure: {
      type: Object,
      default: () => ({}),
    },
  },
  data: () => ({
    config: {
      tools: {
        paragraph: {
          class: Paragraph,
          inlineToolbar: true,
        },
        alert: {
          class: Alert,
          inlineToolbar: true,
          shortcut: 'CMD+SHIFT+A',
          config: {
            defaultType: 'primary',
            messagePlaceholder: 'Enter something',
          },
        },
        imageTool: {
          class: ImageTool,
          inlineToolbar: true,
          config: {
            dropMedia: true,
            mediaResize: {
              width: 900,
            },
            endpoints: {
              byFile: `${process.env.VUE_APP_API_URL}/api/media/editor?width=900`,
              byDragMedia: `${process.env.VUE_APP_API_URL}/api/media/resize`,
            },
          },
        },
        header: {
          class: Header,
          inlineToolbar: true,
          config: {
            placeholder: 'Header',
            levels: [2, 3, 4],
            allowAnchor: true,
            anchorLength: 100,
            defaultLevel: 2
          },
          shortcut: 'CMD+SHIFT+H',
        },
        list: {
          class: List,
          inlineToolbar: true,
        },
        code: {
          class: CodeTool
        },
        embed: {
          class: Embed,
          config: {
            services: {
              youtube: true,
              coub: true,
              imgur: true
            }
          }
        },
        table: {
          class: Table,
          inlineToolbar: true,
          config: {
            rows: 2,
            cols: 3,
          },
        },
        checklist: {
          class: Checklist,
        },
        Marker: {
          class: Marker,
          shortcut: 'CMD+SHIFT+M',
        },
        Smiles: {
          class: Smiles,
        },
        emojiList: {
          inlineToolbar: true,
          class: EmojiList,
        },
        warning: {
          class: Warning,
          inlineToolbar: true,
          shortcut: 'CMD+SHIFT+W',
          config: {
            titlePlaceholder: 'Title',
            messagePlaceholder: 'Message',
          },
        },
        raw: RawTool,
        quote: {
          class: Quote,
          inlineToolbar: true,
          shortcut: 'CMD+SHIFT+O',
          config: {
            quotePlaceholder: 'Enter a quote',
            captionPlaceholder: 'Quote\'s author',
          },
        },
        inlineCode: {
          class: InlineCode,
          shortcut: 'CMD+SHIFT+M',
        },
        delimiter: Delimiter,

        hyperlink: {
          class: Hyperlink,
          config: {
            shortcut: 'CMD+L',
            target: '_blank',
            rel: 'nofollow',
            availableTargets: ['_blank', '_self'],
            availableRels: ['author', 'noreferrer'],
            validate: false,
          }
        },
        AnyButton: {
          class: AnyButton,
          inlineToolbar: false,
          config:{
            css:{
              btn: ["primary", 'btn', 'px-4', 'py-2'],
              btnColor: 'white--text'
            }
          }
        },
        // personality: {
        //   class: Personality,
        //   config: {
        //     namePlaceholder: 'Заголовок с настройками|border|transparent|circle',
        //     endpoint: `${process.env.VUE_APP_API_URL}/api/media/editor?width=200&height=200`
        //   }
        // },
        widgetLibrary: {
          class: WidgetLibrary,
          config: {
            namePlaceholder: 'Заголовок с настройками',
            descriptionPlaceholder: 'Описание',
            linkPlaceholder: 'Ссылка кнопки',
            buttonTextPlaceholder: 'Текст кнопки',
            endpoint: `${process.env.VUE_APP_API_URL}/api/media/editor?width=200`
          }
        },
        dynamicPersons: {
          class: dynamicPersons,
          config: {
            namePlaceholder: 'Имя',
            descriptionPlaceholder: 'Описание',
            linkPlaceholder: 'Ссылка кнопки',
            buttonTextPlaceholder: 'Текст кнопки',
            endpoint: `${process.env.VUE_APP_API_URL}/api/entity/search?entity=App%5CModels%5CPerson&page=1`
          }
        },
        dynamicPartners: {
          class: dynamicPartners,
          config: {
            namePlaceholder: 'Имя',
            descriptionPlaceholder: 'Описание',
            linkPlaceholder: 'Ссылка кнопки',
            buttonTextPlaceholder: 'Текст кнопки',
            endpoint: `${process.env.VUE_APP_API_URL}/api/partners/search`
          }
        },
        forms: {
          class: Forms,
          config: {
            endpoint: `${process.env.VUE_APP_API_URL}/api/formConstructors/`
          }
        },
        products: {
          class: Products,
          config: {
            showListButtonText: 'Показать продукты',
            hideListButtonText: 'Скрыть продукты',
            blockTitleText: 'Пример отображения товара',
            endpoint: `${process.env.VUE_APP_API_URL}/api/module/catalog-item/search`,
            catalogEndpoint: `${process.env.VUE_APP_API_URL}/api/module/catalog/search`,
          }
        },
        titles: {
          class: titles,
          config: {
            titles: [
              'Ключевые темы',
              'Целевая аудитория',
              'Программа',
              'Спикеры',
              'Расписание',
              'Материалы мероприятия',
              'Модератор',
              'Участники',
            ],
            tagName: 'h2'
          }
        },
        contacts: {
          inlineToolbar: true,
          class: Contacts,
          config: {
            options: [
              { value: null, text: 'Нет'},
              { value: 'email', text: 'email'},
              { value: 'phone', text: 'Телефон'},
              { value: 'site', text: 'Сайт'},
              { value: 'vk', text: 'vk'},
              { value: 'ok', text: 'ok'},
              { value: 'tg', text: 'Telegram' },
              { value: 'flag', text: 'Флажок' },
            ]
          }
        },
        contactsV2: {
          inlineToolbar: true,
          class: ContactsV2,
          config: {
            options: [
              { value: null, text: 'Нет'},
              { value: 'email', text: 'email'},
              { value: 'phone', text: 'Телефон'},
              { value: 'site', text: 'Сайт'},
              { value: 'vk', text: 'vk'},
              { value: 'ok', text: 'ok'},
              { value: 'tg', text: 'Telegram' },
              { value: 'flag', text: 'Флажок' },
            ]
          }
        },
      },
    },
  }),
  created() {
    if (this.structure && Object.keys(this.structure).length > 0) {
      this.config.data = this.structure;
    }
    // this.config.tools.image.config.additionalRequestHeaders = {
    //   Authorization: `Bearer ${this.$store.getters['auth/getToken']}`
    // }
  },
  methods: {
    async submit() {
      const data = await this.$refs.editor._data.editor.save();
      this.$emit('onSubmit', data);
      return data;
    },
    async onInitialized(editor) {
      await editor.isReady;
      const undo = new Undo({editor});
      undo.initialize(this.$refs.editor._data.editor.configuration.data);
    },
  },
};
</script>

<style scoped>

</style>
