<template>
  <v-container fluid class="down-top-padding">
    <BaseBreadcrumb :title="page.module_title" :icon="page.icon" :breadcrumbs="breadcrumbs"></BaseBreadcrumb>
        <CourseForm @onSubmit="submit"/>
  </v-container>
</template>

<script>
import BaseBreadcrumb from "../../../components/commonComponents/BaseBreadcrumb";
import CourseForm from "../../../components/chimeraCourses/CourseForm";
import { mapActions } from "vuex";
export default {
  name: "Create",
  components: {CourseForm, BaseBreadcrumb},
  data: () => ({
    page: {
      title: 'Создание курса',
      module_title: 'Образовательные курсы',
      icon: 'mdi-book-plus-outline',
    },
    breadcrumbs: [
      {
        text: "Главная",
        disabled: false,
        to: '/',
      },
      {
        text: "Образовательные курсы",
        disabled: false,
        href: '/chimera-courses/',
      },
      {
        text: 'Создание курса',
        disabled: true,
      }
    ]
  }),
  methods: {
    ...mapActions({
      storeCourse: "ChimeraCourses/storeCourse"
    }),
    submit({ form, needRedirect }) {
      this.storeCourse({
        form: form,
        needRedirect: needRedirect,
        router: this.$router,
      })
    }
  }
}
</script>

<style scoped>

</style>
