<template>
  <v-form>
    <v-text-field
      label="Название"
      v-model="role.$attributes.name"
    />
    <v-select
      v-model="selectedPermissions"
      :items="searchablePermissions"
      item-text="$attributes.name"
      label="Выберите права"
      multiple
      return-object>

      <template v-slot:prepend-item>
        <v-list-item>
          <v-text-field label="Поиск" v-model.lazy="searchableString"/>
        </v-list-item>

        <v-list-item ripple>
          <v-checkbox label="Выбрать все" v-model="selectAll"/>
        </v-list-item>
        <v-divider class="mt-2"></v-divider>
      </template>

    </v-select>
    <v-flex>
      <v-btn
        color="primary"
        :disabled="isSaveDisabled"
        :loading="isSaveDisabled"
        @click="submit"
      >
        Сохранить
      </v-btn>
    </v-flex>
  </v-form>
</template>

<script>
import {Permission} from "../../models/permission";
import {Role} from "../../models/role";

export default {
  name: 'ArticleForm',
  props: {
    role: {
      type: Role,
      default: () => (new Role())
    },
    role_permissions: {
      type: Array,
      default: () => ([]),
    },
    errors: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    form: {
      id: null,
      name: null,
    },
    permissions: [],
    searchablePermissions: [],
    searchableString: '',
    selectAll: false,
    selectedPermissions: [],
    isSaveDisabled: false,
  }),
  created() {
    this.getPermissions();
  },
  methods: {
    submit() {
      this.isSaveDisabled = true;
      this.$emit('onSubmit', this.selectedPermissions, this.role);
    },
    async getPermissions() {
      this.permissions = await Permission.$query().get(100000);
      this.searchablePermissions = this.permissions;
    },
  },
  watch: {
    role: {
      handler(role) {
        if (role && Object.keys(role).length > 0) {
          this.form = {...this.form, ...JSON.parse(JSON.stringify(role))};
          this.isSaveDisabled = false;
        }
      },
      immediate: true,
    },
    role_permissions: {
      handler(role_permissions) {
        if (role_permissions && role_permissions.length > 0) {
          this.selectedPermissions = JSON.parse(JSON.stringify(role_permissions));
        }
      },
      immediate: true,
    },
    searchableString: {
      handler(searchableString) {
        if (searchableString !== '') {
          this.searchablePermissions = this.permissions.filter(item => {
            return item.$attributes.name.toLowerCase().indexOf(this.searchableString.toLowerCase()) > -1;
          });
        }
      }
    },
    selectAll: {
      handler(selectAll) {
        if (selectAll) {
          this.selectedPermissions = this.permissions;
        } else {
          this.selectedPermissions = [];
        }
      }
    }
  },
};
</script>

<style scoped>

</style>
