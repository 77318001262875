<template>
  <v-form>
    <v-text-field
      v-model="form.first_name"
      :error-messages="errors.first_name"
      label="Имя"
      name="first_name"
      required
    ></v-text-field>
    <v-text-field
      v-model="form.last_name"
      :error-messages="errors.last_name"
      label="Фамилия"
      name="last_name"
    ></v-text-field>
    <v-text-field
      v-model="form.patronymic"
      :error-messages="errors.patronymic"
      label="Отчество"
      name="patronymic"
    ></v-text-field>
    <v-text-field
      v-model="form.post"
      :error-messages="errors.post"
      label="Должность"
      name="post"
    ></v-text-field>

    <v-text-field
      v-model="form.phone"
      :error-messages="errors.phone"
      label="Телефон"
      prepend-icon="mdi-phone-classic"
      hint="К примеру, +7(926)000-00-00"
      name="phone"
    ></v-text-field>

    <v-text-field
      v-model="form.email"
      prepend-icon="mdi-at"
      :rules="[rules.email]"
      :error-messages="errors.email"
      label="Email"
      name="email"
    ></v-text-field>


    <v-text-field
      v-model="form.video_url"
      :error-messages="errors.video_url"
      label="Ссылка на видео"
      name="video_url"
    ></v-text-field>


    <v-textarea
      v-model="form.short_description"
      label="Краткая биография"
    />
    <label>Биография</label>
    <tip-tap v-model="form.biography" />
    <media-widget
      class="mb-1"
      label="Анонсное изображение"
      :medias="form.image"
      :is-multiple="false"
      @onChange="setPreviewImage"
    />
    <media-uploader
      :loaded-medias="person.loaded_medias"
      @completeUploads="onCompleteUploads"
    />
    <v-card-actions class="pa-5 text-left">
    <v-btn
      color="primary"
      :disabled="isSaveDisabled"
      :loading="isSaveDisabled"
      @click="submit(true)"
    >
      Сохранить
    </v-btn>
    <v-btn
      color="primary"
      :disabled="isSaveDisabled"
      :loading="isSaveDisabled"
      @click="submit(false)"
    >
      Сохранить и продолжить
    </v-btn>
    </v-card-actions>
  </v-form>
</template>

<script>
import MediaUploader from '../MediaUploader';
import MediaWidget from '../MediaWidget';
import TipTap from "../editor/TipTap";

export default {
  name: "PersonForm",
  components: {
    MediaUploader,
    MediaWidget,
    TipTap,
  },
  props: {
    person: {
      type: Object,
      default: () => ({})
    },
    errors: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    form: {
      id: null,
      first_name: null,
      last_name: null,
      patronymic: null,
      post: null,
      image: null,
      biography: null,
      video_url: null,
      email: null,
      phone: null,
      short_description: null,
    },
    mediaIds: [],
    isSaveDisabled: false,
    rules: {
      required: value => !!value || 'Required.',
      counter: value => value.length <= 20 || 'Max 20 characters',
      email: value => {
        const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        return pattern.test(value) || 'Введите правильный email адрес'
      },
    },
  }),
  created() {
    if (this.$route.params.data) {
      this.form = JSON.parse(this.$route.params.data);
    }
  },
  methods: {
    submit(isNeedRedirect) {
      this.isSaveDisabled = true;
      this.$emit('onSubmit', {form: this.form, mediaIds: this.mediaIds,  isNeedRedirect: isNeedRedirect,});
    },
    async onCompleteUploads(ids) {
      this.mediaIds = ids;
      await this.submit(true);
      this.mediaIds = [];
    },
    setPreviewImage(image) {
      this.form.image = image;
    },
  },
  watch: {
    person: {
      handler(person) {
        if (person && Object.keys(person).length > 0) {
          this.form = person;
          this.isSaveDisabled = false;
        }
      },
      immediate: true,
      deep: true,
    },
  },
}
</script>
