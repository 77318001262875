<template>
  <v-container fluid class="down-top-padding">
    <BaseBreadcrumb :title="page.title" :icon="page.icon" :breadcrumbs="breadcrumbs"></BaseBreadcrumb>
    <v-row>
      <v-col>
        <LessonForm @onSubmit="submit"/>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import BaseBreadcrumb from "../../../../components/commonComponents/BaseBreadcrumb";
import LessonForm from "../../../../components/chimeraCourses/lessons/LessonForm";
import {mapActions, mapGetters} from "vuex";

export default {
  name: "Create",
  components: {LessonForm, BaseBreadcrumb},
  created() {
    if (this.$route.query.parent_id) {
      this.fetchLessonDetail(this.$route.query.parent_id);
    }

    this.fetchCourseDetail(this.$route.params.id);
  },
  computed: {
    ...mapGetters({
      fetchedParentLesson: "ChimeraLessons/getFetchedDetail",
      fetchedCourse: "ChimeraCourses/getFetchedDetail",
    }),
    breadcrumbs() {
      let arr = [
        {
          text: "Главная",
          disabled: false,
          href: '/',
        },
        {
          text: "Образовательные курсы",
          disabled: false,
          href: '/chimera-courses/',
        },
        {
          text: this.fetchedCourse.title || "Курс",
          disabled: false,
          href: `/chimera-courses/edit/${this.$route.params.id}`,
        },
        {
          text: 'Список уроков',
          disabled: false,
          href: `/chimera-courses/${this.$route.params.id}/lessons`
        }
      ]

      if (this.$route.query.parent_id) {
        arr.push(
          {
            text: this.fetchedParentLesson.title,
            disabled: false,
            href: `/chimera-courses/${this.$route.params.id}/lessons/edit/${this.$route.query.parent_id}`
          },
          {
            text: 'Подстраницы',
            disabled: false,
            href: `/chimera-courses/${this.$route.params.id}/lessons/edit/${this.$route.query.parent_id}?tab=1`
          },
          {
            text: 'Создание подстраницы',
            disabled: true,
          }
        )
      } else {
        arr.push(
          {
            text: 'Создание урока',
            disabled: true,
          }
        )
      }

      return arr;
    },
    page() {
      return {
        title: this.$route.query.parent_id ? 'Создание подстраницы' : 'Создание урока',
        icon: 'mdi-book-plus-outline'
      }
    }
  },
  methods: {
    ...mapActions({
      storeLesson: "ChimeraLessons/storeLesson",
      fetchLessonDetail: "ChimeraLessons/fetchDetail",
      fetchCourseDetail: "ChimeraCourses/fetchDetail",
    }),
    submit({form, needRedirect}) {
      if (this.$route.query.parent_id) {
        form.parent_id = this.$route.query.parent_id;
      }
      this.storeLesson({
        form: form,
        needRedirect: needRedirect,
        router: this.$router,
      })
    }
  }
}
</script>

<style scoped>

</style>
