import {Lesson} from "../../../models/lesson";
import axiosInstance from "../../../axiosInstance";

export default {
  namespaced: true,
  state: {
    fetchedListing: [],
    fetchedDetail: {},
    isListingLoad: false,
    isButtonDisabled: false,
  },
  getters: {
    getFetchedListing: (state) => state.fetchedListing,
    getFetchedDetail: (state) => state.fetchedDetail,
    getIsButtonDisabled: (state) => state.isButtonDisabled,
    isListingLoad: (state) => state.isListingLoad,
  },
  mutations: {
    setFetchedListing(state, val) {
      state.fetchedListing = val;
    },
    setIsListingLoad(state, val) {
      state.isListingLoad = val;
    },
    setFetchedDetail(state, val) {
      state.fetchedDetail = val;
    },
    setIsSaveButtonDisabled(state, val) {
      state.isButtonDisabled = val;
    }
  },
  actions: {
    async fetchListing({commit}, {courseID, onlyTrashed}) {
      commit('setIsSaveButtonDisabled', true);

      if (onlyTrashed) {
        await Lesson.$query()
          .sortBy('position')
          .filter('course_id', '=', courseID)
          .onlyTrashed()
          .search(1000)
          .then((response) => {
            commit('setFetchedListing', response.map((el) => el.$attributes));
          });
      } else {
        await Lesson.$query()
          .sortBy('position')
          .filter('course_id', '=', courseID)
          .search(10000)
          .then((response) => {
            commit('setFetchedListing', response.map((el) => el.$attributes));
          });
      }

      commit('setIsSaveButtonDisabled');
    },
    async destroyItem({dispatch, commit}, lessonID) {
      commit('setIsSaveButtonDisabled', true);

      return Lesson.$query().destroy(lessonID, false).then(() => {
        dispatch('snackbar/showSuccessfullyDelete', null, {root: true});
      }).catch(() => {
        dispatch('snackbar/showDeleteError', null, {root: true});
      }).finally(() => {
        commit('setIsSaveButtonDisabled', false);
      });
    },
    async restoreItem({dispatch, commit}, lessonID) {
      commit('setIsSaveButtonDisabled', true);

      return await Lesson.$query().restore(lessonID).then(() => {
        dispatch('snackbar/showSuccessfullyRestore', null, {root: true});
      }).catch(() => {
        dispatch('snackbar/showErrorRestore', null, {root: true});
      }).finally(() => {
        commit('setIsSaveButtonDisabled', false);
      });
    },
    async storeItem({dispatch, commit}, form) {
      commit('setIsSaveButtonDisabled', true);

      let newID = null;

      await Lesson.$query().store(form).then((response) => {
        newID = response.$attributes.id;
        dispatch('snackbar/showSuccessfullySaved', null, {root: true});
      }).catch(() => {
        dispatch('snackbar/showSaveError', null, {root: true});
      }).finally(() => {
        commit('setIsSaveButtonDisabled', false);
      });

      return newID;
    },
    async fetchDetail({dispatch, commit}, lessonID) {
      await Lesson.$query().find(lessonID).then((response) => {
        commit('setFetchedDetail', response.$attributes);
      }).catch(() => {
        dispatch('snackbar/showUnknownError', null, {root: true});
      })
    },
    async updateItem({dispatch}, form) {
      return await Lesson.$query().update(form.id, form).then(() => {
        dispatch('snackbar/showSuccessfullySaved', null, {root: true});
      }).catch(() => {
        dispatch('snackbar/showSaveError', null, {root: true});
      })
    },
    async uploadFile({dispatch}, {form, lessonID}) {

      const formData = new FormData();
      formData.set('key', form.key);
      formData.set('file', form.file);

      await axiosInstance.post(`/module/lessons/${lessonID}/upload-file`, formData)
        .then(() => {
          dispatch('snackbar/showFileUploaded', null, {root: true});
        }).catch(() => {
          dispatch('snackbar/showFileError', null, {root: true});
        })
    },
    async syncQuestions({dispatch, state}, { form, lessonId}) {
      console.log(form);
      await axiosInstance.post(`/module/lessons/${lessonId}/questions/sync-questions`, form).then(() => {
        dispatch('snackbar/showSuccessfullySaved', null, {root: true});
        dispatch('fetchDetail', state.fetchedDetail.id)
      }).catch(() => {
        dispatch('snackbar/showSaveError', null, {root: true});
      })
    }
  }
}
