import { Model } from "@tailflow/laravel-orion/lib/model";

export class Person extends Model<{
    first_name: string,
    last_name: string,
    patronymic: string,
    post: string,
    created_at: string,
    updated_at: string,
    preview_image?: object,
}> {

    $resource(): string {
        return "persons";
    }
}
