<template>
  <v-dialog v-model="show" max-width="800">
    <v-card>
      <v-card>
        <v-card-title class="justify-center">
          <div class="text-center">
            Сортировка FAQ
          </div>
        </v-card-title>
        <v-card-text>
          <div>
            <v-list>
              <v-list-item-group color="indigo">
                <draggable
                  :list="items"
                >
                  <v-card
                    class="d-flex"
                    v-for="(element, index) in items"
                    :key="element.id">

                    <v-card-text class="mr-auto text-h5 black--text">
                      {{ element.question }}
                    </v-card-text>
                    <v-card-text class="text-right black--text">
                      <v-row>
                        Старая позиция: {{ element.position === null ? '-' : element.position + 1 }}
                      </v-row>
                      <v-row>
                        Новая позиция:{{ index + 1 }}
                      </v-row>
                    </v-card-text>
                  </v-card>
                </draggable>
              </v-list-item-group>
            </v-list>
          </div>
        </v-card-text>
        <v-card-actions class="justify-center">
          <v-btn
            @click="show=false"
            text
            color="green darken-1"
          >Закрыть
          </v-btn>
          <v-btn
            @click="submitSort"
            text
            color="green darken-1"
          >Сохранить
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-card>
  </v-dialog>
</template>

<script>
import {mapActions, mapGetters, mapMutations} from "vuex";
import draggable from 'vuedraggable';

export default {
  name: "SortFAQ",
  components: {
    draggable,
  },
  computed: {
    ...mapGetters({
      allItems: 'FAQ/getFetchedAllItems',
      isModalShow: 'FAQ/isModalVisible'
    }),
    items: {
      get() {
        return this.allItems;
      },
      set(val) {
        this.mutationAllItems(val);
      }
    },
    show: {
      get() {
        return this.isModalShow
      },
      set(val) {
        this.mutationIsModalVisible(val);
      }
    }
  },
  methods: {
    ...mapActions({
      fetchAllItems: 'FAQ/fetchAllItems',
      submitSort: 'FAQ/storeSort',
    }),
    ...mapMutations({
      mutationAllItems: 'FAQ/setFetchedAllItems',
      mutationIsModalVisible: 'FAQ/setIsModalVisible',
    })
  },
  created() {
    this.fetchAllItems();
  }
}
</script>

<style scoped>

</style>
