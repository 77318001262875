<template>
  <v-container fluid class="down-top-padding">
    <BaseBreadcrumb :title="page.title" :icon="page.icon" :breadcrumbs="breadcrumbs"></BaseBreadcrumb>
    <v-card>
      <v-card-text class="pa-5">
        <v-row>
          <v-col>
            <v-data-table
              :headers="headers"
              :items="fetchedListing"
              :options.sync="options"
              :loading="isListingLoading"
              :server-items-length="totalItems"
              :footer-props="{ 'items-per-page-options': [5, 10, 15, 20, -1] }"
              >
              <template v-slot:top>
                <v-toolbar flat class="mb-8">
                  <v-row>
                    <v-col cols="12" lg="6">
                      <v-text-field
                        v-model="search"
                        append-icon="mdi-magnify"
                        label="Поиск..."
                        filled
                        background-color="transparent"
                        hide-details
                        name="title"
                      >
                        <v-btn v-if="search" class="reset-button"
                               slot="append"
                               icon
                               @click="search = null"
                               color="red"
                        >
                          <v-icon>mdi-close</v-icon>
                        </v-btn>
                      </v-text-field>
                    </v-col>
                    <v-col>
                      <v-switch v-model="only_trashed" label="Показать удаленные" name="only_trashed" value="1" style="margin-top: 16px;"></v-switch>
                    </v-col>
                  </v-row>

                  <v-spacer></v-spacer>
                  <v-dialog max-width="700">
                    <template v-slot:activator="{ on }">
                      <v-btn
                        color="primary"
                        to="/blocks/create"
                        dark
                        class="mb-2 text-capitalize"
                      >
                        <v-icon class="mr-2">mdi-shape-square-plus</v-icon>
                        Создать блок
                      </v-btn>
                    </template>
                  </v-dialog>
                </v-toolbar>
              </template>
              <template v-slot:item.id="{item}">
                <v-chip pill>{{ item.id }}</v-chip>
              </template>
              <template v-slot:item.actions="{ item }">
                <v-btn
                  :href="editItem(item)"
                  icon
                >
                  <v-icon small color="primary">
                    mdi-pencil
                  </v-icon>
                </v-btn>
                <v-icon
                  v-if="!item.deleted_at"
                  color="red"
                  small
                  @click="deleteItem(item)"
                >
                  mdi-delete
                </v-icon>
                <v-icon
                  v-else
                  color="green"
                  small
                  @click="restore(item)"
                >
                  mdi-backup-restore
                </v-icon>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
  name: 'Index',
  data: () => ({
    search: '',
    options: {
      sortBy: ['id'],
      sortDesc: [true],
      page: 1,
      itemsPerPage: 20,
    },
    only_trashed: null,
    headers: [
      { text: 'Id', value: 'id' },
      { text: 'Заголовок', value: 'title' },
      { text: 'Группа', value: 'group.title' },
      { text: 'Техническое название', value: 'component' },
      { text: 'Дата создания', value: 'created_at' },
      { text: 'Дата обновления', value: 'updated_at' },
      { text: '', value: 'actions' },
    ],
    page: {
      title: "Блоки"
    },
    breadcrumbs: [
      {
        text: "Админ панель",
        disabled: false,
        to: '/'
      },
      {
        text: "Блоки",
        disabled: true,
        to: '/blocks'
      },
    ]
  }),
  created() {
    this.fetchListing(this.listingPayload);
  },
  computed: {
    ...mapGetters({
      isListingLoading: 'Blocks/isListingLoading',
      fetchedListing: 'Blocks/getFetchedListing',
      totalItems: 'Blocks/getListingTotalItems',
    }),
    listingPayload() {
      return {
        options: this.options,
        only_trashed: this.only_trashed,
        search: this.search,
      }
    }
  },
  methods: {
    ...mapActions({
      fetchListing: 'Blocks/fetchListing',
      destroyItem: 'Blocks/destroyItem',
      restoreItem: 'Blocks/restoreItem',
    }),
    editItem(item) {
      return this.$router.resolve({ name: 'blocks-edit', params: { id: item.id }}).href
    },
    async deleteItem(item) {
      await this.destroyItem(item).then(() => {
        this.fetchListing(this.listingPayload);
      })
    },
    async restore(item) {
      await this.restoreItem(item).then(() => {
        this.fetchListing(this.listingPayload);
      })
    }
  },
  watch: {
    search: {
      async handler(newSearch, oldSearch) {
        newSearch != oldSearch ? this.options.page = 1 : '';
        await this.fetchListing(this.listingPayload)
      }
    },
    options: {
      async handler() {
        await this.fetchListing(this.listingPayload)
      },
      deep: true,
    },
    only_trashed: {
      async handler() {
        await this.fetchListing(this.listingPayload)
      }
    }
  },
};
</script>

<style scoped>

</style>
