import Index from '../../views/main/blocks/Index'
import Create from '../../views/main/blocks/Create'
import Edit from '../../views/main/blocks/Edit';
import GroupIndex from '../../views/main/blocks/group/Index'
import GroupCreate from '../../views/main/blocks/group/Create'
import GroupEdit from '../../views/main/blocks/group/Edit';
import SettingIndex from '../../views/main/blocks/settings/Index';
import SettingCreate from '../../views/main/blocks/settings/Create';
import SettingEdit from '../../views/main/blocks/settings/Edit';

export default [
  {
    component: Index,
    name: 'blocks-index',
    path: '/blocks',
    meta: {
      layout: 'main-layout',
      requiresAuth: true,
      module: 'blocks',
    }
  },
  {
    component: Create,
    name: 'blocks-create',
    path: '/blocks/create',
    meta: {
      layout: 'main-layout',
      requiresAuth: true,
      module: 'blocks',
    }
  },
  {
    component: Edit,
    name: 'blocks-edit',
    path: '/blocks/edit/:id',
    meta: {
      layout: 'main-layout',
      requiresAuth: true,
      module: 'blocks',
    }
  },
  {
    component: GroupIndex,
    name: 'block-groups-index',
    path: '/block-groups',
    meta: {
      layout: 'main-layout',
      requiresAuth: true,
      module: 'blocks',
    }
  },
  {
    component: GroupCreate,
    name: 'block-groups-create',
    path: '/block-groups/create',
    meta: {
      layout: 'main-layout',
      requiresAuth: true,
      module: 'blocks',
    }
  },
  {
    component: GroupEdit,
    name: 'block-groups-edit',
    path: '/block-groups/edit/:id',
    meta: {
      layout: 'main-layout',
      requiresAuth: true,
      module: 'blocks',
    }
  },
  {
    component: SettingIndex,
    name: 'block-settings-index',
    path: '/block-settings',
    meta: {
      layout: 'main-layout',
      requiresAuth: true,
      module: 'blocks',
    }
  },
  {
    component: SettingCreate,
    name: 'block-settings-create',
    path: '/block-settings/create',
    meta: {
      layout: 'main-layout',
      requiresAuth: true,
      module: 'blocks',
    }
  },
  {
    component: SettingEdit,
    name: 'block-settings-edit',
    path: '/block-settings/edit/:id',
    meta: {
      layout: 'main-layout',
      requiresAuth: true,
      module: 'blocks'
    }
  }
]
