import {Site} from "../../models/site";
import Cookies from 'js-cookie';

export default {
  namespaced: true,
  state: {
    sites: [],
    selectedSite: {},
  },
  getters: {
    getSelectedSite: state => state.selectedSite,
    getDefaultSite: (state) => {
      return state.sites.find(el => el.is_default === true)
    },
    getSites: state => state.sites,
  },
  mutations: {
    setSelectedSite(state, value) {
      state.selectedSite = value;
      Cookies.set('site_id', value.id);
    },
    setSites(state, value) {
      state.sites = value;
    }
  },
  actions: {
    async fetchSites({commit, dispatch}) {
      await Site.$query().get(10000).then((response) => {
        commit('setSites', response.map(el => el.$attributes));
        dispatch('setSelectedSites');
      })
    },
    setSelectedSites({ commit, getters, state }) {
      const selectedSiteID = Cookies.get('site_id');

      if (selectedSiteID) {
        const selectedSite = state.sites.find(el => el.id == selectedSiteID);

        if (selectedSite) {
          commit('options/setOptions', selectedSite.options, {root: true});
          commit('setSelectedSite', selectedSite);
        } else if (getters.getDefaultSite) {
          commit('options/setOptions', getters.getDefaultSite.options, {root: true});
          commit('setSelectedSite', getters.getDefaultSite);
        }
      } else if (Object.keys(getters.getDefaultSite).length > 0) {
        commit('options/setOptions', getters.getDefaultSite.options, {root: true});
        commit('setSelectedSite', getters.getDefaultSite);
      }
    }
  }
}
