<template>
  <v-container fluid class="down-top-padding">
    <BaseBreadcrumb :title="page.title" :icon="page.icon" :breadcrumbs="breadcrumbs"/>

    <v-row>  <v-col    >
    <v-bottom-navigation>
      <v-btn :href="`/chimera-courses/`"   >
        <span>Список курсов</span>

        <v-icon>mdi-book</v-icon>
      </v-btn>

      <v-btn  color="grey lighten-2" >
        <span>Редактирование курса</span>

        <v-icon>mdi-book-open-page-variant-outline</v-icon>
      </v-btn>

      <v-btn    :to="`/chimera-courses/${$route.params.id}/lessons/`">
        <span>Редактирование уроков</span>

        <v-icon>mdi-format-page-break</v-icon>
      </v-btn>

    </v-bottom-navigation>
    </v-col>
    </v-row>

    <v-row>  <v-col    >
    <v-tabs    background-color="transparent"

               color="basil"
                vertical>
      <v-tab>
        Основная информация
      </v-tab>
      <v-tab>
        Таксономия
      </v-tab>
      <v-tab>
        SEO
      </v-tab>
      <v-tab>
        SMM
      </v-tab>

      <v-tab-item  >
        <v-container fluid>
        <CourseForm :course="fetchedDetail" @onSubmit="submit"/>
        </v-container>
      </v-tab-item>
      <v-tab-item>
        <collection-relation-form
          module-name="Courses"
          :selected-collection-items="fetchedDetail.selected_collection_items"
          :main-items="fetchedDetail.main_taxonomy_ids"
          @onSubmit="submitCollections"
          @onSubmitMain="submitCollectionsMainItem"
        />
      </v-tab-item>
      <v-tab-item>
        <seo-form-separate :entity="fetchedDetail"/>
      </v-tab-item>
      <v-tab-item>
        <s-m-m-form-separate :entity="fetchedDetail"/>
      </v-tab-item>

    </v-tabs>    </v-col>
    </v-row>
  </v-container>
</template>

<script>
import BaseBreadcrumb from "../../../components/commonComponents/BaseBreadcrumb";
import { mapGetters, mapActions } from "vuex";
import CourseForm from "../../../components/chimeraCourses/CourseForm";
import SeoFormSeparate from "../../../components/SEOFormSeparate";
import SMMFormSeparate from "../../../components/SMMFormSeparate";
import CollectionRelationForm from "../../../components/collections/CollectionRelationForm";

export default {
  name: "Edit",
  components: {
    CollectionRelationForm,
    CourseForm,
    BaseBreadcrumb,
    SeoFormSeparate,
    SMMFormSeparate
  },
  created() {
    this.fetchDetail(this.$route.params.id);
  },
  computed: {
    ...mapGetters({
      isLoading: "ChimeraCourses/getIsLoading",
      fetchedDetail: "ChimeraCourses/getFetchedDetail",
    }),
    page() {
      return {
        title: this.fetchedDetail.title || "Образовательные курсы",
        icon: "mdi-book-edit-outline",
      }
    },
    breadcrumbs() {
      return [
        {
          text: "Главная",
          disabled: false,
          href: '/',
        },
        {
          text: "Образовательные курсы",
          disabled: false,
          href: '/chimera-courses',
        },
        {
          text: this.fetchedDetail.title || 'Редактирование курса',
          disabled: true,
        }
      ];
    }
  },
  methods: {
    ...mapActions({
      fetchDetail: "ChimeraCourses/fetchDetail",
      updateCourse: "ChimeraCourses/updateCourse",
      submitCollections: "ChimeraCourses/submitCollections",
      submitMainCollectionItems: "ChimeraCourses/makeMainCollectionItem"
    }),
    submit({ form, needRedirect }) {
      this.updateCourse({
        form: form,
        needRedirect: needRedirect,
        router: this.$router,
      })
    },
    async submitCollection({ items, isNeedRedirect }) {
      await this.submitCollections({ items: items}).then(() => {
        if (isNeedRedirect) {
          this.$router.push({ name: 'chimera-courses-index' });
        }
      });
    },
    async submitCollectionsMainItem(items) {
      await this.submitMainCollectionItems({ items: items });
    }
  }
}
</script>

<style>
.v-tabs-items {
  background-color: transparent!important;
}
</style>
