<template>
  <v-container fluid class="down-top-padding">
    <BaseBreadcrumb :title="pagebc.title" :icon="pagebc.icon" :breadcrumbs="breadcrumbs"></BaseBreadcrumb>
    <v-card>
      <v-card-text class="pa-5">
        <v-row>
          <v-col>
            <h1>Редактирование страницы {{ page.id }}</h1>
          </v-col>
        </v-row>
        <v-tabs
          v-model="tab"
          background-color="transparent"
          grow
          class="mb-5"
        >
          <v-tab>
            Основная информация
          </v-tab>
          <v-tab v-if="page.form && page.form.length > 0">
            Дополнительные поля
          </v-tab>
          <v-tab v-if="!page.parent && !isPryamaya">
            Дочерние элементы
          </v-tab>
          <v-tab>
            Таксономия
          </v-tab>
          <v-tab>
            SEO
          </v-tab>
          <v-tab>
            SMM
          </v-tab>
          <v-tab v-if="false">
            Меню
          </v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab">
          <v-tab-item>
            <page-form
              :page="page"
              :is-loading="isLoading"
              :media="media"
              @onSubmit="submit"
              :is-edit="true"
            />
          </v-tab-item>
          <v-tab-item v-if="page.form && page.form.length > 0">
            <component
              v-if="page.form[0].component"
              :is="page.form[0].component"
              :form="page.form[0].data"
              @mounted="handleFormMounted"
              ref="form$"
              class="additional-fields"
            />
          </v-tab-item>
          <v-tab-item
            v-if="!page.parent && !isPryamaya"
          >
            <page-children
              :page="page"
              :errors="errors"
              @onSubmit="submit"
            />
          </v-tab-item>
          <v-tab-item>
            <CollectionRelationForm
              module-name="Page"
              :errors="errors"
              :selected-collection-items="page.selected_collection_items"
              :main-items="page.main_taxonomy_ids"
              @onSubmit="submitCollection"
              @onSubmitMain="submitMainCollection"
            />
          </v-tab-item>
          <v-tab-item>
            <seo-form-separate
              :entity="page"
              :errors="errors"
              @onSubmit="submit"
            />
          </v-tab-item>
          <v-tab-item>
            <smm-form-separate
              :entity="page"
              :errors="errors"
              @onSubmit="submit"
            />
          </v-tab-item>
        </v-tabs-items>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import PageForm from '../../../components/pages/PageForm';
import PageChildren from "../../../components/pages/PageChildren";
import SeoFormSeparate from "../../../components/SEOFormSeparate";
import SmmFormSeparate from "../../../components/SMMFormSeparate";
import CollectionRelationForm from "../../../components/collections/CollectionRelationForm";
import {mapActions, mapGetters} from "vuex";

export default {
  name: 'Edit',
  components: {
    PageForm,
    PageChildren,
    SeoFormSeparate,
    SmmFormSeparate,
    CollectionRelationForm
  },
  data: () => ({
    errors: {},
    tab: 0,
    pagebc: {
      title: "Редактирование страницы"
    },
    breadcrumbs: [
      {
        text: "Админ панель",
        disabled: false,
        to: '/'
      },
      {
        text: "Страницы",
        disabled: false,
        to: '/pages/'
      },
      {
        text: "Редактирование",
        disabled: true,
        to: '/pages/edit/',
      },
    ],
    isLoading: true,
  }),
  async created() {
    await this.fetchDetail(this.$route.params.id).then(() => {
      if (this.page.is_permission_view) {
        if (!this.can('page.permission.view')) {
          this.$router.push('/');
        }
      }

      this.isLoading = false;
    });
  },
  computed: {
    ...mapGetters({
      page: 'Pages/getFetchedDetail',
      media: 'Pages/getFetchedDetailMedia',
    }),
    isPryamaya() {
      return process.env.VUE_APP_IS_PRYAMAYA === 'true';
    }
  },
  methods: {
    ...mapActions({
      fetchDetail: 'Pages/fetchDetail',
      update: 'Pages/update',
      submitCollections: 'Pages/submitCollections',
      submitMainCollections: 'Pages/submitMainCollections',
    }),
    async submit(form) {
      this.update(form).then(() => {
        if (form.isNeedRedirect) {
          this.$router.push({name: 'pages-index'});
        }

        this.fetchDetail(this.$route.params.id);
      });
    },
    handleFormMounted() {
      this.form$ = this.$refs.form$

      this.$emit('load', this.form$)
    },
    async submitCollection({ items, isNeedRedirect }) {
      await this.submitCollections({ items: items}).then(() => {
        if (isNeedRedirect) {
          this.$router.push({ name: 'sites-index' });
        }
      });
    },
    async submitMainCollection(items) {
      await this.submitMainCollections({items: items});
    }
  },
};
</script>

<style lang="scss">
  .additional-fields {
    input {
      max-width: 100%;
      border-bottom: 1px solid rgba(0, 0, 0, 0.42);
      padding: 8px 0 8px;
      max-height: 32px;
      width: 100%;
    }

    .form-group .row .col-sm-12:nth-child(1) {
      padding-top: 0;
      padding-bottom: 0;
    }

    .form-group .row .col-sm-12:nth-child(2) {
      padding-top: 0;
    }

    .group-element > .row > .col-sm-12 > label.control-label {
      font-weight: 600;
      font-size: 20px;
    }
  }
</style>
