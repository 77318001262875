<template>
  <v-form class="row">
    <v-text-field
      class="col"
      v-model="form.url"
      label="Полная ссылка"
      name="url"
      required
    ></v-text-field>
    <v-btn
      color="primary"
      class="col-4 mr-2"
      :disabled="isSaveButtonDisabled"
      :loading="isSaveButtonDisabled"
      @click="submit(redirect)"
    >
      <template v-if="buttonName">
        {{ buttonName }}
      </template>
      <template v-else>
        Сохранить
      </template>
    </v-btn>
  </v-form>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: 'ShortUrlsForm',
  data: () => ({
    form: {},
    isSaveDisabled: false,
  }),
  props: {
    url: {
      type: String,
    },
    buttonName: {
      type: String,
    },
    redirect: {
      type: String,
    },
    shortUrl: {
      type: String,
    },
  },
  computed: {
    ...mapGetters({
      isSaveButtonDisabled: "ShortUrls/isSaveButtonDisabled",
    }),
  },
  created() {
    this.form.url = this.url;
  },
  methods: {
    async submit(isNeedRedirect) {
      this.isSaveDisabled = true;

      this.$emit('onSubmit', {form: this.form, isNeedRedirect: isNeedRedirect, router: this.$router});
    },
  }
}
</script>
