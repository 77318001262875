import {ChimeraCourse} from "../../../models/chimeraCourse";
import {SortDirection} from "@tailflow/laravel-orion/lib/drivers/default/enums/sortDirection";
import {ChimeraLesson} from "../../../models/chimeraLesson";
import axiosInstance from "../../../axiosInstance";

export default {
  namespaced: true,
  state: {
    fetchedListing: [],
    listingTotalItems: 0,
    isLoading: false,
    fetchedDetail: {},
    lessonList: [],
    patternStatuses: {},
  },
  getters: {
    getFetchedListing: (state) => state.fetchedListing,
    getListingTotalItems: (state) => state.listingTotalItems,
    getIsLoading: (state) => state.isLoading,
    getFetchedDetail: (state) => state.fetchedDetail,
    getLessonList: (state) => state.lessonList,
    getPatternStatuses: (state) => state.patternStatuses,
  },
  mutations: {
    setFetchedListing(state, value) {
      state.fetchedListing = value;
    },
    setListingTotalItems(state, value) {
      state.listingTotalItems = value;
    },
    setIsLoading(state, value) {
      state.isLoading = value;
    },
    setFetchedDetail(state, value) {
      state.fetchedDetail = value;
    },
    setLessonList(state, value) {
      state.lessonList = value;
    },
    setPatternStatuses(state, value) {
      state.patternStatuses = value;
    }
  },
  actions: {
    fetchListing({commit, dispatch}, {options, search, only_trashed}) {
      commit('setIsLoading', true);

      const {sortBy, sortDesc, page } = options;

      let queryBuilder = ChimeraCourse.$query().lookFor(search);

      if (only_trashed) {
        queryBuilder.onlyTrashed();
      }

      if (sortBy[0]) {
        queryBuilder.sortBy(sortBy[0], sortDesc[0] ? SortDirection.Desc : SortDirection.Asc)
      }

      queryBuilder.search(1000000, page).then((response) => {
        if (response.length > 0) {
          commit('setListingTotalItems', response[0].$response.data.meta.total);
        } else {
          commit('setListingTotalItems', 0);
        }

        commit('setFetchedListing', response.map((item) => item.$attributes));
      }).catch(() => {
        dispatch('snackbar/showUnknownError', null, {root: true})
      }).finally(() => {
        commit('setIsLoading', false);
      })
    },
    storeCourse({commit, dispatch}, {form, needRedirect, router}) {
      commit('setIsLoading', true);

      ChimeraCourse.$query().store(form).then((response) => {
        dispatch("snackbar/showSuccessfullySaved", null, {root: true});

        if (needRedirect) {
          router.push({name: 'chimera-courses-index'})
        } else {
          router.push({name: 'chimera-courses-edit', params: {id: response.$attributes.id}})
        }
      }).catch(() => {
        dispatch("snackbar/showUnknownError", null, {root: true})
      }).finally(() => {
        commit('setIsLoading', true);
      })
    },
    fetchDetail({commit, dispatch}, courseID) {
      commit('setIsLoading', true);

      ChimeraCourse.$query().with(['collectionItems']).find(courseID).then((response) => {
        commit('setFetchedDetail', response.$attributes);
      }).catch(() => {
        dispatch('snackbar/showUnknownError', null, {root: true})
      }).finally(() => {
        commit('setIsLoading', false);
      })
    },
    updateCourse({commit, dispatch}, {form, needRedirect, router}) {
      commit('setIsLoading', true);

      ChimeraCourse.$query().update(form.id, form).then((response) => {
        if (needRedirect) {
          router.push({name: "chimera-courses-index"})
        }
        dispatch('snackbar/showSuccessfullySaved', null, {root: true})
        commit('setFetchedDetail', response.$attributes);
      }).catch(() => {
        dispatch('snackbar/showSaveError', null, {root: true})
      }).finally(() => {
        commit('setIsLoading', false);
      })
    },
    deleteItem({commit, dispatch}, {id, isForce}) {
      commit('setIsLoading', true);

      return ChimeraCourse.$query().destroy(id, isForce).then(() => {
        dispatch("snackbar/showSuccessfullyDelete", null, {root: true})
      }).catch(() => {
        dispatch("snackbar/showDeleteError", null, {root: true})
      }).finally(() => {
        commit('setIsLoading', false);
      })
    },
    restoreItem({commit, dispatch}, id) {
      commit('setIsLoading', true);

      return ChimeraCourse.$query().restore(id).then(() => {
        dispatch("snackbar/showSuccessfullyRestore", null, {root: true})
      }).catch(() => {
        dispatch("snackbar/showErrorRestore", null, {root: true})
      }).finally(() => {
        commit('setIsLoading', false);
      })
    },
    fetchLessonList({commit, dispatch}, id) {
      commit('setIsLoading', true);

      ChimeraLesson.$query()
        .filter('course_id', '=', id)
        .filter('slug', 'not like', '%-lesson-0')
        .filter('parent_id', '=', null)
        .scope('sortPosition')
        .search(1000000).then((resp) => {
          commit('setLessonList', resp.map((item) => item.$attributes));
        }).catch(() => {
          dispatch("snackbar/showUnknownError", null, {root: true})
        }).finally(() => {
          commit('setIsLoading', false);
        })
    },
    async submitCollections({dispatch, state}, {items}) {
      await axiosInstance.post(`module/chimera-courses/${state.fetchedDetail.id}/collection-items/sync`, {items: items}).then(() => {
        dispatch('snackbar/showSuccessfullySaved', null, {root: true})
        dispatch('fetchDetail', state.fetchedDetail.id)
      }).catch(() => {
        dispatch('snackbar/showSuccessfullySaved', null, {root: true});
      })
    },
    async makeMainCollectionItem({dispatch, state}, {items}) {
      await axiosInstance.post(`module/chimera-courses/${state.fetchedDetail.id}/collection-items/make-main`, {items: items}).then(() => {
        dispatch('snackbar/showSuccessfullySaved', null, {root: true})
        dispatch('fetchDetail', state.fetchedDetail.id)
      }).catch(() => {
        dispatch('snackbar/showSuccessfullySaved', null, {root: true});
      })
    },
    fetchPatternStatuses({dispatch, commit}) {
      axiosInstance.get('/module/chimera-courses/pattern/status').then((resp) => {
        commit('setPatternStatuses', resp.data);
      }).catch(() => {
        dispatch("snackbar/showUnknownError", null, {root: true})
      })
    },
    patternCreate({dispatch}) {
      axiosInstance.post('/module/chimera-courses/pattern/create').then(() => {
        dispatch('fetchPatternStatuses');
      })
    },
    createFromTemplate({dispatch}, {form, endPoint}) {
      return axiosInstance.post(endPoint, form).then(() => {
        dispatch('snackbar/showSuccessfullySaved', null, {root: true})
      }).catch(() => {
        dispatch('snackbar/showUnknownError', null, {root: true});
      })
    },
    regenerateTemplate({ dispatch }, { lesson, template }) {
      return axiosInstance.post(`/module/chimera-courses/${template}/regenerate/${lesson}`).then(() => {
        dispatch("snackbar/showSuccessfullySaved", null, { root: true })
      }).catch(() => {
        dispatch("snackbar/showUnknownError", null, { root: true })
      })
    }
  }
};

