import Index from '../../views/main/chimera-courses/lessons/Index';
import Create from '../../views/main/chimera-courses/lessons/Create';
import Edit from '../../views/main/chimera-courses/lessons/Edit';

export default [
  {
    component: Index,
    name: 'chimera-lessons-index',
    path: '/chimera-courses/:id/lessons',
    meta: {
      layout: 'main-layout',
      requiresAuth: true,
    }
  },
  {
    component: Create,
    name: 'chimera-lessons-create',
    path: '/chimera-courses/:id/lessons/create',
    meta: {
      layout: 'main-layout',
      requiresAuth: true,
    }
  },
  {
    component: Edit,
    name: 'chimera-lessons-edit',
    path: '/chimera-courses/:id/lessons/edit/:lessonID',
    meta: {
      layout: 'main-layout',
      requiresAuth: true,
    }
  }
]
