<template>
  <v-form>
    <v-text-field
      v-model="form.title"
      :error-messages="errors.title"
      label="Название"
      name="title"
      required
    ></v-text-field>
    <v-text-field
      v-model="form.slug"
      :error-messages="errors.slug"
      label="Slug"
      name="slug"
      :disabled="!(Object.keys(event).length === 0)"
    ></v-text-field>
    <a
      v-if="event.preview_url"
      :href="event.preview_url"
      target="_blank"
      class="mr-3"
    >
      {{ event.preview_url }}
    </a>
    <preview v-if="event.preview_url" :previewUrl="event.preview_url"/>
    <v-datetime-picker
      v-model="form.published_at"
      :time-picker-props="timeProps"
      scrollable
      label="Дата публикации. По умолчанию текущая дата и время"
      clear-text="Очистить"
    ></v-datetime-picker>
    <v-datetime-picker
      v-model="form.event_date"
      :time-picker-props="timeProps"
      scrollable
      label="Дата начала"
      clear-text="Очистить"
    ></v-datetime-picker>
    <v-datetime-picker
      v-model="form.event_date_end"
      scrollable
      :time-picker-props="timeProps"
      label="Дата окончания"
      clear-text="Очистить"
    ></v-datetime-picker>
    <v-checkbox
      v-model="form.date_is_not_confirmed"
      :true-value="1"
      :false-value="0"
      label="Точная дата уточняется"
      class="mt-auto mb-auto"
    />
    <v-checkbox
      v-model="form.is_pinned"
      label="Закрепить на главной"
      class="mt-auto mb-auto"
      />
    <v-text-field
      v-model="form.location"
      :error-messages="errors.location"
      label="Адрес проведения"
      name="location"
      required
    ></v-text-field>
    <v-autocomplete
      v-if="regions"
      v-model="form.region_id"
      :error-messages="errors.region_id"
      :items="regions"
      item-text="title"
      item-value="id"
      label="Выберите регион"
      name="region_id"
      chips
      required
    ></v-autocomplete>
    <v-autocomplete
      v-if="partners"
      v-model="form.partner_id"
      :error-messages="errors.partner_id"
      :items="partners"
      item-text="title"
      item-value="id"
      label="Выберите ответственного оператора"
      name="partner_id"
      chips
      required
    ></v-autocomplete>
    <v-autocomplete
      v-model="form.selected_organizers"
      v-if="partners"
      :items="partners"
      item-text="title"
      item-value="id"
      label="Выберите организаторов"
      multiple
    >
      <template v-slot:selection="data">
        <v-chip
          v-bind="data.attrs"
          :input-value="data.selected"
          close
          @click="data.select"
          @click:close="removeOrganizers(data.item)"
        >
          <v-avatar left>
            <v-img :src="data.item.image"/>
          </v-avatar>
          {{ data.item.title }}
        </v-chip>
      </template>
      <template v-slot:item="data">
        <v-list-item-avatar>
          <img :src="data.item.image">
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title>{{ data.item.title }}</v-list-item-title>
        </v-list-item-content>
      </template>
    </v-autocomplete>
    <v-autocomplete
      v-model="form.selected_partners"
      v-if="partners"
      :items="partners"
      item-text="title"
      item-value="id"
      label="Выберите партнеров"
      multiple
    >
      <template v-slot:selection="data">
        <v-chip
          v-bind="data.attrs"
          :input-value="data.selected"
          close
          @click="data.select"
          @click:close="removePartners(data.item)"
        >
          <v-avatar left>
            <v-img :src="data.item.image"/>
          </v-avatar>
          {{ data.item.title }}
        </v-chip>
      </template>
      <template v-slot:item="data">
        <v-list-item-avatar>
          <img :src="data.item.image">
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title>{{ data.item.title }}</v-list-item-title>
        </v-list-item-content>
      </template>
    </v-autocomplete>
    <v-select
      v-if="statuses"
      v-model="form.status"
      :error-messages="errors.status"
      :items="statuses"
      label="Статус"
      name="status"
      required
    ></v-select>
    <v-text-field
      v-model="form.notification_email"
      :error-messages="errors.notification_email"
      label="Email для уведомления"
      name="notification_email"
      required
    ></v-text-field>
    <v-card v-if="!event.parent && events" class="mb-5">
      <v-card-title>
        Привязка мероприятий для серии мероприятий
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col>
            <v-autocomplete
              item-text="title"
              item-value="id"
              placeholder="Выберите мероприятие"
              multiple
              :items="events"
              v-model="form.selected_events"
            >
              <template v-slot:selection="data">
                <v-chip
                  v-bind="data.attrs"
                  :input-value="data.selected"
                  close
                  @click="data.select"
                  @click:close="removeChild(data.item)"
                >
                  <v-avatar left>
                    <v-img :src="data.item.icon"/>
                  </v-avatar>
                  {{ data.item.title }}
                </v-chip>
              </template>
              <template v-slot:item="data">
                <v-list-item-avatar>
                  <img :src="data.item.icon">
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title>{{ data.item.title }}</v-list-item-title>
                </v-list-item-content>
              </template>
            </v-autocomplete>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-card v-else-if="event.parent" class="mb-5">
      <v-card-title>
        Родительское мероприятие
      </v-card-title>
      <v-card-subtitle>
        К данному элементу невозможно привязать дочерние мероприятия, т.к он уже используется
      </v-card-subtitle>
      <v-card-text>
        <v-row>
          <v-col>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title v-text="event.parent.title"/>
              </v-list-item-content>
              <v-list-item-action>
                <v-btn fab dark color="primary" small :href="editParent(event.parent.id)">
                  <v-icon color="white">mdi-pen</v-icon>
                </v-btn>
              </v-list-item-action>
            </v-list-item>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <media-widget
      class="mb-1"
      label="Анонсное изображение"
      :medias="form.preview_image"
      :is-multiple="false"
      @onChange="setPreviewImage"
    />
    <v-textarea
      name="lead"
      label="Лид"
      v-model="form.lead"
      :error-messages="errors.lead"
      required
    ></v-textarea>
    <label>Контент</label>
    <media-form
      :key="reRenderMedia"
      ref="media-form"
      :structure="currentMedia.structure"
      :is-show-save="false"
      style="margin: 0 auto"
    />
<v-checkbox v-model="form.is_hidden" label="Скрыть со списочных"/>
    <v-flex class="mb-2">
      <v-btn
        v-if="isEdit && isModuleBlockEnabled"
        :href="`${clientUrl}/events/${form.slug}?edit_mode=true&admin_url=${this.$route.fullPath}`"
        color="primary"
        class="mr-2 mt-2"
        @click="toBlocks"
      >
        Редактирование блоков
      </v-btn>
    </v-flex>
    <v-flex>
      <v-btn
        class="mr-4"
        color="primary"
        :disabled="isSaveDisabled"
        :loading="isSaveDisabled"
        @click="submit(true)"
      >
        Сохранить
      </v-btn>
      <v-btn
        class="mr-4"
        color="primary"
        :disabled="isSaveDisabled"
        :loading="isSaveDisabled"
        @click="submit(false)"
      >
        Сохранить и продолжить
      </v-btn>
    </v-flex>

    <media-uploader
      :loaded-medias="event.loaded_medias"
      @completeUploads="onCompleteUploads"
    />
  </v-form>
</template>

<script>
import MediaForm from '../medias/MediaForm';
import slugify from 'slugify';
import moment from 'moment';
import MediaUploader from '../MediaUploader';
import MediaWidget from '../MediaWidget';
import axiosInstance from "../../axiosInstance";

export default {
  name: "EventForm",
  props: {
    event: {
      type: Object,
      default: () => ({})
    },
    media: {
      type: Object,
      default: () => ({}),
    },
    errors: {
      type: Object,
      required: true,
    },
    isEdit: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    MediaForm,
    MediaUploader,
    MediaWidget,
  },
  data: () => ({
    form: {
      id: null,
      region_id: null,
      partner_id: null,
      title: null,
      slug: null,
      published_at: null,
      event_date: null,
      event_date_end: null,
      location: null,
      preview_image: null,
      status: null,
      selected_partners: null,
      selected_organizers: null,
      selected_events: [],
      lead: null,
      notification_email: null,
      date_is_not_confirmed: 0,
      is_hidden: false,
      is_pinned: false,
    },
    timeProps: {
      format: '25hr',
      useSeconds: false,
    },
    currentMedia: {
      id: null,
      entity: 'Modules\\Events\\Entities\\Event',
      entity_id: null,
      structure: null,
    },
    statuses: [
      {text: 'Черновик', value: 0},
      {text: 'Опубликовано', value: 1},
    ],
    reRenderMedia: 1,
    mediaIds: [],
    regions: [],
    partners: [],
    events: null,
    isSaveDisabled: false,
    clientUrl: process.env.VUE_APP_CLIENT_URL,
  }),
  created() {
    if (this.$route.params.media) {
      this.currentMedia = JSON.parse(this.$route.params.media);
    }

    if (this.$route.params.data) {
      this.form = JSON.parse(this.$route.params.data);
    }

    this.getRegions();
    this.getPartners();
    this.getEvents();
  },
  computed: {
    isModuleBlockEnabled() {
      return process.env.VUE_APP_M_BLOCKS === 'true';
    }
  },
  methods: {
    async onCompleteUploads(ids) {
      this.mediaIds = ids;
      await this.submit(true);
      this.mediaIds = [];
    },
    async submit(isNeedRedirect) {
      this.isSaveDisabled = true;

      const data = await this.$refs['media-form'].submit();

      if (data) {
        this.currentMedia.structure = data;
      }

      this.form.published_at = this.form.published_at
        ? moment(this.form.published_at).format('YYYY-MM-DD HH:mm')
        : null;
      this.form.event_date = this.form.event_date
        ? moment(this.form.event_date).format('YYYY-MM-DD HH:mm')
        : null;
      this.form.event_date_end = this.form.event_date_end
        ? moment(this.form.event_date_end).format('YYYY-MM-DD HH:mm')
        : null;

      this.$emit('onSubmit', {
        form: this.form,
        media: this.currentMedia,
        mediaIds: this.mediaIds,
        isNeedRedirect: isNeedRedirect,
      });
    },
    setPreviewImage(image) {
      this.form.preview_image = image;
    },
    async getRegions() {
      await axiosInstance.get('module/admin/regions/select-list').then((response) => {
        this.regions = response.data.data;
      });
    },
    async getPartners() {
      await axiosInstance.get('partners/select-list').then((response) => {
        this.partners = response.data.data;
      });
    },
    async getOrganizers() {
      await axiosInstance.get('organizers/select-list').then((response) => {
        this.partners = response.data.data;
      });
    },
    toBlocks() {
      this.$store.dispatch('block/updatePageForm', this.form);
    },
    async getEvents() {
      await axiosInstance.get(`module/events/select-list/${this.$route.params.id}`).then((response) => {
        this.events = response.data.data;
      });
    },
    deleteSelectedElement(index) {
      this.form.selected_events.splice(index, 1);
    },
    editParent(id) {
      return this.$router.resolve({name: 'events-edit', params: {id: id}}).href;
    },
    removeOrganizers(item) {
      const index = this.form.selected_organizers.indexOf(item.id);
      if (index >= 0) this.form.selected_organizers.splice(index, 1);
    },
    removePartners(item) {
      const index = this.form.selected_partners.indexOf(item.id);
      if (index >= 0) this.form.selected_partners.splice(index, 1);
    },
    removeChild(item) {
      const index = this.form.selected_events.indexOf(item.id);
      if (index >= 0) this.form.selected_events.splice(index, 1);
    }
  },
  watch: {
    event: {
      handler(event) {
        if (event && Object.keys(event).length > 0) {
          this.form = event;
          this.isSaveDisabled = false;
        }
      },
      immediate: true,
      deep: true,
    },
    media: {
      handler(media) {
        if (media && Object.keys(media).length > 0) {
          this.currentMedia = JSON.parse(JSON.stringify(media));
          this.reRenderMedia += 1;
        }
      },
      immediate: true,
      deep: true,
    },
    'form.title': {
      handler(value) {
        if (value && Object.keys(this.event).length === 0) {
          this.form.slug = slugify(value.toLowerCase()).match(/[a-z]|[0-9]|[-]/gm).join('');
        }
      }
    }
  },
}
</script>
