<template>
  <div>
    <div class="mb-5">
      <v-btn color="primary" href="/temp/region-statistic" disabled>Статистика</v-btn>
      <v-btn color="primary" href="/temp/region-monitoring">Мониторинги</v-btn>
      <v-btn color="primary" href="/temp/region-olympic">Олимпиады</v-btn>
    </div>
    <div v-if="regions">
      <div>
        <v-btn color="primary" @click="onCreate">Добавить</v-btn>
        <v-btn color="error" @click="onDelete">Удалить</v-btn>
        <v-btn color="green" @click="onSave">Сохранить</v-btn>
      </div>
      <vue-excel-editor ref="grid" v-model="tempModel">
        <vue-excel-column field="field_1" label="Субъект" type="map" :options="regions.title"/>
        <vue-excel-column field="field_2" label="ФО" type="select" :options="regions.district"/>
        <vue-excel-column field="field_3" label="педагогов и преподавателей СПО, чел."/>
        <vue-excel-column field="field_4" label="преподавателей вузов ФСМЦ МГУ, чел."/>
        <vue-excel-column field="field_5" label="консультантов-методистов, чел."/>
        <vue-excel-column field="field_6" label="педагогов и преподавателей СПО, чел."/>
        <vue-excel-column field="field_7" label="педагогов и преподавателей СПО, чел"/>
        <vue-excel-column field="field_8" label="преподавателей вузов ФСМЦ МГУ, чел."/>
        <vue-excel-column field="field_9" label="консультантов-методистов, чел."/>
        <vue-excel-column field="field_10" label="педагогов и преподавателей СПО, чел."/>
        <vue-excel-column field="field_11" label="педагогов и преподавателей СПО, чел."/>
        <vue-excel-column field="field_12" label="преподавателей вузов ФСМЦ МГУ, чел."/>
        <vue-excel-column field="field_13" label="консультантов-методистов, чел."/>
        <vue-excel-column field="field_14" label="педагогов и преподавателей СПО, чел."/>
      </vue-excel-editor>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex';
import axiosInstance from "../../../axiosInstance";

export default {
  name: "RegionStatistic",
  data: () => ({
    regions: null,
  }),
  created() {
    axiosInstance.get('temp-data/regions').then((resp) => {
      this.regions = resp.data;
      this.fetchTempData(1);
    });
  },
  computed: {
    ...mapGetters({
      tempData: "TempData/getTempData",
    }),
    tempModel: {
      set(val) {
        this.setTempData(val);
      },
      get() {
        return this.tempData;
      }
    }
  },
  methods: {
    ...mapActions({
      fetchTempData: "TempData/fetchTempData",
      saveTempData: "TempData/saveTempData",
    }),
    ...mapMutations({
      setTempData: "TempData/setTempData",
    }),
    onCreate(){
      this.$refs.grid.newRecord();
    },
    onDelete(){
      this.$refs.grid.deleteRecord(0);
    },
    onSave() {
      this.saveTempData(1);
    }
  }
}
</script>

<style scoped>

</style>
