<template>
  <v-container fluid class="down-top-padding">
    <BaseBreadcrumb :title="page.title" :icon="page.icon" :breadcrumbs="breadcrumbs"></BaseBreadcrumb>
    <v-row>
      <v-col>
        <h1>Короткие ссылки</h1>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-btn color="primary" to="/ShortUrls/create">
          Создать короткую ссылку
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-data-table
          :headers="headers"
          :items="items"
          :options.sync="options"
          :loading="loading"
          :server-items-length="listingTotal"
          :search="search"
        >
          <template v-slot:item.fullUrlHref="{item}">
            <a :href="item.url" target="_blank">{{ item.url }}</a>
          </template>
          <template v-slot:item.shortUrlHref="{item}">
            <a :href="item.shortUrl" target="_blank">{{ item.shortUrl }}</a>
          </template>
          <template v-slot:item.action="{item}">
            <v-btn
              :href="itemStat(item)"
              icon
            >
              <v-icon color="primary">
                mdi-chart-timeline-variant
              </v-icon>
            </v-btn>
            <v-icon
              color="red"
              @click="deleteItem(item)"
            >
              mdi-delete
            </v-icon>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
  name: 'Index',
  data: () => ({
    search: '',
    only_trashed: null,
    options: {
      sortBy: ['id'],
      sortDesc: [true],
      page: 1,
      itemsPerPage: 10,
    },
    headers: [
      {text: 'Id', value: 'id'},
      {text: 'Полный url', value: 'fullUrlHref'},
      {text: 'Короткая ссылка', value: 'shortUrlHref'},
      {text: 'Количество переходов', value: 'show_count'},
      {text: 'Дата создания', value: 'created_at'},
      {text: 'Дата обновления', value: 'updated_at'},
      {text: '', value: 'action'},
    ],
    page: {
      title: "Короткие ссылки"
    },
    breadcrumbs: [
      {
        text: "Админ панель",
        disabled: false,
        to: '/'
      },
      {
        text: "Короткие ссылки",
        disabled: true,
        to: '/shortuls'
      },
    ],
  }),
  created() {
    this.fetchedListing(this.listingPayload);
  },
  computed: {
    ...mapGetters({
        items: 'ShortUrls/getFetchedListing',
        listingTotal: 'ShortUrls/getListingTotalItems',
        loading: 'ShortUrls/getIsListingLoad',
    }),
    listingPayload() {
      return {
        options: this.options,
        search: this.search,
        only_trashed: this.only_trashed,
      }
    }
  },
  methods: {
    ...mapActions({
      fetchedListing: 'ShortUrls/fetchListing',
      destroyItem: 'ShortUrls/destroyItem',
    }),
    deleteItem(item) {
      this.destroyItem(item.id).then(this.fetchedListing(this.listingPayload));
    },
    itemStat(item) {
      return this.$router.resolve({name: 'shortUrls-stats', params: {id: item.id}}).href
    },
  },
  watch: {
    options: {
      handler() {
        this.fetchedListing(this.listingPayload);
      },
      deep: true,
      immediate: true,
    },
  }
}
</script>
