<template>
  <v-container fluid class="down-top-padding">
    <BaseBreadcrumb :title="page.title" :icon="page.icon" :breadcrumbs="breadcrumbs"></BaseBreadcrumb>
    <v-card>
      <v-card-text class="pa-5">
        <v-row>
          <v-col>
            <h1>Редактирование FAQ</h1>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <FAQForm
              :FAQ="fetchedDetail"
              @onSubmit="save"
              />
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>

import FAQForm from "../../../components/faq/FAQForm";
import {mapActions, mapGetters} from "vuex";

export default {
  name: "Edit",
  components: {FAQForm},
  data: () => ({
    page: {
      title: "Редактирование FAQ"
    },
    breadcrumbs: [
      {
        text: "Админ панель",
        disabled: false,
        to: '/'
      },
      {
        text: "FAQ",
        disabled: true,
        to: '/FAQ'
      },
      {
        text: "Редактирование",
        disabled: true,
        to: '/FAQ/edit'
      },
    ],
  }),
  computed: {
    ...mapGetters({
      fetchedDetail: 'FAQ/getFetchedDetail',
    })
  },
  created(){
    this.get();
  },
  methods: {
    ...mapActions({
      fetchDetail: 'FAQ/fetchDetail',
      updateItem: 'FAQ/updateItem'
    }),
    async get() {
      await this.fetchDetail(this.$route.params.id);
    },
    save({form, isNeedRedirect}){
      this.updateItem({
        id: form.id,
        form,
        isNeedRedirect,
        router: this.$router,
      }).then(() => this.$router.push({ name: 'faq-index' }));
    }
  }
}
</script>

<style scoped>

</style>
