<template>
  <v-container fluid class="down-top-padding">
    <BaseBreadcrumb :title="page.title" :icon="page.icon" :breadcrumbs="breadcrumbs"></BaseBreadcrumb>
    <v-card>
      <v-card-text class="pa-5">
        <v-card v-if="Object.keys(statistic).length > 0">
          <v-card-title>
            <v-row align="center">
              <v-col>
                <v-autocomplete :items="socialList" v-model="selectedSocial" multiple chips deletable-chips>
                  <template v-slot:prepend-item>
                    <v-list-item ripple @mousedown.prevent @click="selectedSocialToggle">
                      <v-list-item-action>
                        <v-icon :color="selectedSocial.length > 0 ? 'primary' : ''">
                          {{ autoCompleteIcon }}
                        </v-icon>
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title>
                          Выбрать всё
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-divider class="mt-2"/>
                  </template>
                  <template v-slot:selection="{ item, index }">
                    <v-chip v-if="index < 3">
                      <span>{{ item }}</span>
                    </v-chip>
                    <span
                      v-if="index === 3"
                      class="grey--text text-caption"
                    >
                      (+{{ selectedSocial.length - 3 }} других)
                   </span>
                  </template>
                </v-autocomplete>
              </v-col>
              <v-col>
                <v-dialog
                  ref="dialog"
                  v-model="dateDialog"
                  :return-value.sync="date"
                  persistent
                  width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="date"
                      label="Выберите промежуток"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    />
                  </template>
                  <v-date-picker
                    v-model="date"
                    range
                    scrollable
                    locale="ru-RU"
                  >
                    <v-spacer></v-spacer>
                    <v-btn
                      text
                      color="primary"
                      @click="dateDialog = false"
                    >
                      Закрыть
                    </v-btn>
                    <v-btn
                      text
                      color="primary"
                      @click="$refs.dialog.save(date)"
                    >
                      Oк
                    </v-btn>
                  </v-date-picker>
                </v-dialog>
              </v-col>
            </v-row>
          </v-card-title>
        </v-card>
        <v-card class="mt-5" v-if="statistic.general">
          <v-card-title>
            <h2>Основные показатели</h2>
          </v-card-title>
          <v-card-text>
            <div>
              <v-row>
                <v-col offset="1">
                  <div class="text text-subtitle-1">Пользователи</div>
                  <div class="h2">{{ statistic.general.Users }}</div>
                </v-col>
                <v-col>
                  <div class="text text-subtitle-1">Просмотры страниц</div>
                  <div class="h2">{{ statistic.general.Hits }}</div>
                </v-col>
                <v-col>
                  <div class="text text-subtitle-1">Сред. длительность сеанса</div>
                  <div class="h2">{{ statistic.general.AvgVisitDuration }}</div>
                </v-col>
                <v-col>
                  <div class="text text-subtitle-1">Показатель отказов</div>
                  <div class="h2">{{ statistic.general.BounceRate }}%</div>
                </v-col>
              </v-row>
              <v-row>
                <v-col offset="1">
                  <div class="text text-subtitle-1">Сеансы</div>
                  <div class="h2">{{ statistic.general.Visits }}</div>
                </v-col>
                <v-col>
                  <div class="text text-subtitle-1">Страниц/сеанс</div>
                  <div class="h2">{{ statistic.general.AvgPagePerSession }}</div>
                </v-col>
                <v-col>
                  <div class="text text-subtitle-1">Ср. длительность просмотра стр.</div>
                  <div class="h2">{{ statistic.general.AvgPageDuration }}</div>
                </v-col>
                <v-col>
                  <div class="text text-subtitle-1">Длительность сеанса</div>
                  <div class="h2">{{ statistic.general.TotalDuration }}</div>
                </v-col>
              </v-row>
            </div>
          </v-card-text>
        </v-card>
        <v-card class="mt-5" v-show="statistic.user_graphic">
          <v-card-title>
            Пользователи
          </v-card-title>
          <v-card-text>
            <div id="users33">
              <apexchart ref="firstChar" type="line" height="320" :options="userChartOptions" :series="userChartSeries"/>
              <apexchart ref="secondChar" type="line" height="320" :options="userPreviousChartOptions" :series="userPreviousChartSeries"/>
            </div>
          </v-card-text>
        </v-card>
        <v-card class="mt-2" v-if="statistic.trafic && isMounted">
          <v-card-title>
            Распределение по каналам трафика
          </v-card-title>
          <v-card-text>
            <apexchart type="bar" height="400" :series="statistic.trafic.series" :options="chartOptions"/>
          </v-card-text>
        </v-card>
        <v-row>
          <v-col>
            <v-card class="mt-2" v-if="statistic.bounce && isMounted">
              <v-card-title>
                Показатель отказов
              </v-card-title>
              <v-card-text>
                <apexchart type="bar" height="400" :series="statistic.bounce.series" :options="bounceChartOptions"/>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col>
            <v-card class="mt-2" v-if="statistic.bounce && isMounted">
              <v-card-title>
                Средняя длительность сеансов
              </v-card-title>
              <v-card-text>
                <apexchart type="bar" height="400" :series="statistic.avgSession.series" :options="avgSessionChartOptions"/>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-card class="mt-2">
          <v-card-title>
            <v-row>
              <v-col>
                <h4>Регионы</h4>
              </v-col>
            </v-row>
          </v-card-title>
          <v-card-text>
            <v-data-table
              :headers="regionsHeaders"
              :items="statistic.regions"
              :items-per-page="5"
            />
          </v-card-text>
        </v-card>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
  name: "Social",
  data: () => ({
    dateDialog: false,
    date: [],
    isMounted: false,
    regionsHeaders: [
      { text: 'Регион', value: 'RegionCity' },
      { text: 'Пользователи', value: 'Users' },
      { text: 'Просмотры страниц', value: 'PageViews' },
    ],
    selectedSocial: [],
    page: {
      title: "Социальные сети"
    },
    breadcrumbs: [
      {
        text: "Админ панель",
        disabled: false,
        to: '/'
      },
      {
        text: "Статистика",
        disabled: true,
        to: '/'
      },
      {
        text: "Социальные сети",
        disabled: true,
        to: '/statisticsV2/social'
      },
    ],
  }),
  async created() {
    const currentDate = new Date();
    const currentMonth = currentDate.getMonth() < 10 ? `0${currentDate.getMonth() + 1}` : currentDate.getMonth() + 1;
    const lastDay = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0)

    this.date = [
      `${lastDay.getFullYear()}-${currentMonth}-01`,
      `${lastDay.getFullYear()}-${currentMonth}-${lastDay.getDate()}`
    ];

    await this.fetch(this.payload)
  },
  mounted() {
    this.isMounted = true;
  },
  computed: {
    ...mapGetters({
      "statistic": "Statistics/getSocialStatistic",
    }),
    payload() {
      return {
        interval: {
          from: this.date[0],
          to: this.date[1],
        },
        list: this.selectedAllSocial ? '' : this.selectedSocial,
      }
    },
    chartOptions() {
      return {
        chart: {
          type: 'bar',
          height: 400
        },
        plotOptions: {
          bar: {
            borderRadius: 10,
            dataLabels: {
              position: 'top',
            }
          }
        },
        dataLabels: {
          enabled: true,
          offsetY: -20,
          style: {
            fontSize: '12px',
            colors: ['#304758'],
          }
        },
        xaxis: {
          categories: this.statistic.trafic.xaxis.categories,
        },
        yaxis: [
          {
            title: {
              text: "Пользователи"
            }
          },
          {
            opposite: true,
            title: {
              text: 'Просмотры страниц'
            }
          }
        ]
      }
    },
    userChartSeries() {
      const series = this.statistic?.user_graphic?.currentIntervalData;
      if (series) {
        return [
          series,
        ]
      } else {
        return [];
      }
    },
    userPreviousChartSeries() {
      const series = this.statistic?.user_graphic?.previousIntervalData;
      if (series) {
        return [
          series,
        ]
      } else {
        return [];
      }
    },
    userChartOptions() {
      return {
        chart: {
          id: 'current',
          // group: 'users',
          type: 'line',
          height: 320,
        },
        title: {
          text: 'Текущий период',
          align: 'left',
        },
        colors: ['#008FFB'],
        xaxis: {

        }
      }
    },
    userPreviousChartOptions() {
      return {
        chart: {
          id: 'previous',
          group: 'users',
          type: 'line',
          height: 320,
        },
        title: {
          text: 'Предыдущий месяц текущего периода',
          align: 'left',
        },
        colors: ['#00FF66FF'],
        xaxis: {

        }
      }
    },
    bounceChartOptions() {
      return {
        chart: {
          type: 'bar',
          height: 380
        },
        plotOptions: {
          bar: {
            borderRadius: 4,
            horizontal: true,
          }
        },

        dataLabels: {
          enabled: true,
          formatter: (val) => {
            return val + "%";
          },
        },
        xaxis: this.statistic.trafic.xaxis,
      }
    },
    avgSessionChartOptions() {
      return {
        chart: {
          type: 'bar',
          height: 380
        },
        plotOptions: {
          bar: {
            barHeight: '50%',
            distributted: true,
            horizontal: true,
            datalLabels: {
              positio: 'bottom',
            }
          }
        },
        dataLabels: {
          enables: true,
          textAnchor: 'start',
          style: {
            colors: ['#fff'],
          },
          formatter: (val, opt) => {
            return this.statistic.avgSession.formattedSeries[opt.dataPointIndex];
          }
        },
        xaxis: this.statistic.avgSession.xaxis,
      }
    },
    socialList() {
      if (this.statistic.socialList) {
        return this.statistic.socialList;
      }

      return [];
    },
    selectedAllSocial() {
      return this.socialList.length === this.selectedSocial.length;
    },
    likesSomeSocial() {
      return this.selectedSocial.length > 0 && !this.selectedAllSocial;
    },
    autoCompleteIcon() {
      if (this.selectedAllSocial) return 'mdi-close-box'
      if (this.likesSomeSocial) return 'mdi-minus-box'
      return 'mdi-checkbox-blank-outline';
    }
  },
  methods: {
    ...mapActions({
      "fetch": "Statistics/fetchSocialStatistic",
    }),
    selectedSocialToggle() {
      this.$nextTick(() => {
        if (this.selectedAllSocial) {
          this.selectedSocial = [];
        } else {
          this.selectedSocial = this.socialList;
        }
      })
    },
    formatDate(date) {
      const currentMonth = date.getMonth() < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1;
      const currentDay = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate();
      return `${date.getFullYear()}-${currentMonth}-${currentDay}`
    }
  },
  watch: {
    date: {
      async handler() {
        if (this.date.length === 2) {
          const firstDate = new Date(this.date[0]);
          const secondDate = new Date(this.date[1]);

          if (firstDate > secondDate) {
            this.date[0] = this.formatDate(secondDate);
            this.date[1] = this.formatDate(firstDate);
          }

          await this.fetch(this.payload)
        }
      }
    },
    selectedSocial: {
      async handler() {
        await this.fetch(this.payload);
      }
    },
    statistic: {
      handler() {
        if(this.statistic.user_graphic) {
          this.$refs.firstChar.updateOptions({
            xaxis: {
              categories: this.statistic?.user_graphic?.currentInterval
            }
          })
          this.$refs.secondChar.updateOptions({
            xaxis: {
              categories: this.statistic?.user_graphic?.previousInterval,
            }
          })
        }
      },
      deep: true,
      immediate: true,
    }
  }
}
</script>

<style scoped>

</style>
