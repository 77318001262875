import RegionStatistic from "../../views/main/temp/RegionStatistic"
import RegionMonitoring from "../../views/main/temp/RegionMonitoring"
import RegionOlympic from "../../views/main/temp/RegionOlympic"

export default [
  {
    component: RegionStatistic,
    name: 'region-statistic',
    path: '/temp/region-statistic',
    meta: {
      layout: 'main-layout',
      requiresAuth: true,
    }
  },
  {
    component: RegionMonitoring,
    name: 'region-statistic',
    path: '/temp/region-monitoring',
    meta: {
      layout: 'main-layout',
      requiresAuth: true,
    }
  },
  {
    component: RegionOlympic,
    name: 'region-statistic',
    path: '/temp/region-olympic',
    meta: {
      layout: 'main-layout',
      requiresAuth: true,
    }
  }
]
