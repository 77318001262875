import Index from '../../views/main/chimera-courses/Index';
import Edit from '../../views/main/chimera-courses/Edit';
import Create from '../../views/main/chimera-courses/Create';
export default [
  {
    component: Index,
    name: 'chimera-courses-index',
    path: '/chimera-courses',
    meta: {
      layout: 'main-layout',
      requiresAuth: true,
    }
  },
  {
    component: Create,
    name: 'chimera-courses-create',
    path: '/chimera-courses/create',
    meta: {
      layout: 'main-layout',
      requiresAuth: true
    }
  },
  {
    component: Edit,
    name: 'chimera-courses-edit',
    path: '/chimera-courses/edit/:id',
    meta: {
      layout: 'main-layout',
      requiresAuth: true,
    }
  },
]
