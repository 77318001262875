<template>
  <v-container fluid class="down-top-padding">
    <BaseBreadcrumb :title="page.title" :icon="page.icon" :breadcrumbs="breadcrums"/>

    <v-row>  <v-col    >
      <v-bottom-navigation>
        <v-btn :href="`/chimera-courses/`"   >
          <span>Список курсов</span>

          <v-icon>mdi-book</v-icon>
        </v-btn>

        <v-btn :href="`/chimera-courses/edit/${$route.params.id}`">
          <span>Редактирование курса</span>

          <v-icon>mdi-book-open-page-variant-outline</v-icon>
        </v-btn>

        <v-btn color="grey lighten-2" >
          <span>Редактирование уроков</span>

          <v-icon>mdi-format-page-break</v-icon>
        </v-btn>

        <v-btn

          @click="sortModal = true"
        >
          <span> Сортировка уроков</span>

          <v-icon>mdi-sort-ascending</v-icon>

        </v-btn>

      </v-bottom-navigation>
    </v-col>
    </v-row>
    <v-row>  <v-col    >


    <v-row >
      <v-col
        cols="4"
        sm="6"
        md="4"
      >



        <template>

          <v-card>
            <v-card-title>

              <h3 class="title blue-grey--text text--darken-2 font-weight-regular">  Прототипы</h3>

            </v-card-title>
            <v-card-subtitle>
              <h6 class="subtitle-2 font-weight-light">   Первообраз, оригинал, первоначальный образец</h6>
            </v-card-subtitle>

            <v-list>
              <v-list-item>
                <v-list-item-action>
                  <v-icon>mdi-puzzle</v-icon>
                </v-list-item-action>

                <v-list-item-content>
                  <v-list-item-title>Списочная уроков</v-list-item-title>
                </v-list-item-content>
                <v-list-item-action>
                  <v-row>
                    <v-col>
                      <a target="_blank" :href="patternStatus.private_course_href">
                        <v-icon small color="primary">
                          mdi-eye
                        </v-icon>
                      </a>
                    </v-col>
                    <v-col>
                      <a target="_blank" :href="`${patternStatus.private_course_href}?edit_mode=true`">
                        <v-icon small color="primary">
                          mdi-pen
                        </v-icon>
                      </a>
                    </v-col>
                  </v-row>

                </v-list-item-action>
              </v-list-item>
              <v-divider></v-divider>

              <v-list-item>
                <v-list-item-action>
                  <v-icon>mdi-puzzle</v-icon>
                </v-list-item-action>

                <v-list-item-content>
                  <v-list-item-title>Детальная урока</v-list-item-title>
                </v-list-item-content>
                <v-list-item-action>
                  <v-row>
                    <v-col>
                      <a target="_blank" :href="patternStatus.lessons_href">
                        <v-icon small color="primary">
                          mdi-eye
                        </v-icon>
                      </a>
                    </v-col>
                    <v-col>
                      <a target="_blank" :href="`${patternStatus.lessons_href}?edit_mode=true`">
                        <v-icon small color="primary">
                          mdi-pen
                        </v-icon>
                      </a>
                    </v-col>
                    <v-col>
                      <v-btn icon small @click="modal = true">
                        <v-icon color="green">
                          mdi-plus
                        </v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>

                </v-list-item-action>
              </v-list-item>



            </v-list>
          </v-card>

          <!--
                              <v-row>
                                <v-col>



                                  <h3>
                                    Шаблон детальной страницы купленного курса
                                    <a target="_blank" :href="patternStatus.private_course_href">
                                      <v-icon color="primary">
                                        mdi-eye
                                      </v-icon>
                                    </a>
                                    <a target="_blank" :href="`${patternStatus.private_course_href}?edit_mode=true`">
                                      <v-icon color="primary">
                                        mdi-pen
                                      </v-icon>
                                    </a>
                                  </h3>
                                </v-col>
                              </v-row>

                              -->
        </template>



      </v-col>
      <v-col
        cols="8"
        sm="6"
        md="8"
      >


    <v-toolbar flat class="mb-8">
      <v-row>
        <v-col cols="12" lg="6">
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Поиск..."
            filled
            background-color="transparent"
            hide-details
            name="title"
          >
            <v-btn v-if="search"
                   class="reset-button"
                   slot="append"
                   icon
                   @click="search = null"
                   color="red"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-text-field>
        </v-col>
        <v-col>
          <v-switch v-model="only_trashed"
                    label="Показать удаленные"
                    name="only_trashed"
                    value="1"
                    style="margin-top: 16px;"/>
        </v-col>
      </v-row>
      <v-spacer/>

      <v-btn
        color="primary"
        :to="createURL"
      >
        <v-icon class="mr-2">mdi-clipboard-list</v-icon>
        Добавить урок
      </v-btn>
    </v-toolbar>
    <v-row>
      <v-col cols="4" class="mt-2" v-for="(lesson, index) in fetchedData" :key="lesson.id">
        <v-card elevation="2">
          <div>
            <v-row>
              <v-col>
                <v-spacer/>
                Урок {{index + 1}}
              </v-col>
            </v-row>
          </div>
          <v-img :src="lesson.image" height="250"/>
          <v-card-title>{{ lesson.title }}</v-card-title>
          <v-card-actions>
            <v-btn icon color="primary" :to="`/chimera-courses/${$route.params.id}/lessons/edit/${lesson.id}`"
                   v-if="!lesson.deleted_at">
              <v-icon>mdi-pen</v-icon>
            </v-btn>
            <v-btn icon color="primary" target="_blank" :href="lesson.client_url">
              <v-icon>mdi-eye</v-icon>
            </v-btn>
            <v-btn icon v-if="!lesson.deleted_at" @click="deleteItem(lesson.id, false)">
              <v-icon color="red">
                mdi-delete
              </v-icon>
            </v-btn>
            <v-btn v-else icon @click="restoreItem(lesson.id)">
              <v-icon color="green">
                mdi-backup-restore
              </v-icon>
            </v-btn>
            <v-btn icon v-if="lesson.deleted_at" @click="deleteItem(lesson.id, true)">
              <v-icon color="red">
                mdi-delete
              </v-icon>
            </v-btn>
            <v-spacer/>
            <v-btn icon color="primary" :to="`/chimera-courses/${$route.params.id}/lessons/edit/${lesson.id}?tab=1`">
              <v-icon>mdi-book-open-page-variant</v-icon>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <PatternPageCreator end-point="/module/chimera-courses/lessons/create-from-template" v-model="modal"
                        @onSubmit="fetchListing(payload)"/>

      </v-col>
    </v-row>

    </v-col>
    </v-row>
    <v-dialog v-model="sortModal" max-width="800" style="overflow-x: hidden; overflow-y: hidden">
      <v-card style="overflow-x: hidden; overflow-y: hidden">
        <SortingLessonForm/>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import BaseBreadcrumb from "../../../../components/commonComponents/BaseBreadcrumb";
import {mapGetters, mapActions} from 'vuex';
import PatternPageCreator from "../../../../components/chimeraCourses/PatternPageCreator";
import SortingLessonForm from "../../../../components/chimeraCourses/SortingLessonForm";

export default {
  name: "Index",
  components: {SortingLessonForm, PatternPageCreator, BaseBreadcrumb},
  data: () => ({
    page: {
      title: 'Уроки',
      icon: 'mdi-book-education-outline'
    },
    options: {
      sortBy: ['id'],
      sortDesc: [true],
      page: 1,
    },
    search: '',
    only_trashed: false,
    headers: [
      {text: 'ID', value: 'id'},
      {text: 'Название', value: 'title'},
      {text: 'Slug', value: 'slug'},
      {text: 'Статус', value: 'status'},
      {text: '', value: 'actions'},
    ],
    modal: false,
    sortModal: false,
  }),
  created() {
    this.fetchListing(this.payload);
    this.fetchCourseDetail(this.$route.params.id)
    this.fetchPatternStatus();
  },
  computed: {
    ...mapGetters({
      fetchedData: "ChimeraLessons/getFetchedListing",
      totalItems: "ChimeraLessons/getListingTotalItems",
      isLoading: "ChimeraLessons/getIsLoading",
      patternStatus: "ChimeraCourses/getPatternStatuses",
      fetchedCourse: "ChimeraCourses/getFetchedDetail",
    }),
    createURL() {
      return `/chimera-courses/${this.$route.params.id}/lessons/create`
    },
    payload() {
      return {
        options: this.options,
        search: this.search,
        only_trashed: this.only_trashed,
        course_id: this.$route.params.id,
      }
    },
    breadcrums() {
      return [
        {
          text: "Главная",
          disabled: false,
          href: '/',
        },
        {
          text: "Образовательные курсы",
          disabled: false,
          href: '/chimera-courses/',
        },
        {
          text: this.fetchedCourse.title || 'Курс',
          disabled: false,
          href: `/chimera-courses/edit/${this.$route.params.id}`,
        },
        {
          text: 'Список уроков',
          disabled: true,
        }
      ]
    }
  },
  methods: {
    ...mapActions({
      fetchListing: "ChimeraLessons/fetchListing",
      delete: "ChimeraLessons/deleteItem",
      restore: "ChimeraLessons/restoreItem",
      fetchPatternStatus: "ChimeraCourses/fetchPatternStatuses",
      patternsCreate: "ChimeraCourses/patternCreate",
      fetchCourseDetail: "ChimeraCourses/fetchDetail",
    }),
    deleteItem(id, isForce) {
      this.delete({
        id: id,
        isForce: isForce,
      }).then(() => {
        this.fetchListing(this.payload);
      });
    },
    restoreItem(id) {
      this.restore(id).then(() => {
        this.fetchListing(this.payload);
      })
    }
  },
  watch: {
    options: {
      handler() {
        this.fetchListing(this.payload);
      },
      deep: true,
    },
    search: {
      handler() {
        this.fetchListing(this.payload);
      }
    },
    only_trashed: {
      handler() {
        this.fetchListing(this.payload);
      }
    }
  }
}
</script>

<style scoped>

</style>
