import { Model } from "@tailflow/laravel-orion/lib/model";

export class Teaser extends Model<{
    title: string,
    description: string,
    url: string,
    created_at: string,
    updated_at: string
}> {

    $resource(): string {
        return "module/teasers";
    }
}
