import axiosInstance from "../../../axiosInstance";
import {ShortUrls} from "@/models/shortUrls";

export default {
  namespaced: true,
  state: {
    fetchedListing: [],
    listingTotalItems: 0,
    isListingLoad: false,
    isSaveButtonDisabled: false,
    shortUrl: '',
    stats: [],
  },
  getters: {
    getFetchedListing: state => state.fetchedListing,
    getListingTotalItems: state => state.listingTotalItems,
    getIsListingLoad: state => state.isListingLoad,
    isSaveButtonDisabled: state => state.isSaveButtonDisabled,
    getShortUrl: state => state.shortUrl,
    getStats: state => state.stats,
  },
  mutations: {
    setFetchedListing(state, listing) {
      state.fetchedListing = listing;
    },
    setListingTotalItems(state, totalItems) {
      state.listingTotalItems = totalItems;
    },
    setIsListingLoad(state, value) {
      state.isListingLoad = value;
    },
    setIsSaveButtonDisabled(state, value) {
      state.isSaveButtonDisabled = value;
    },
    setShortUrl(state, value) {
      state.shortUrl = value;
    },
    setStats(state, value) {
      state.stats = value;
    },
  },
  actions: {
    fetchListing({commit}, {options, search, only_trashed}) {

    commit('setIsListingLoad', true);

    const {sortBy, sortDesc, page, itemsPerPage} = options;
    const onlyTrashed = only_trashed ? `&only_trashed=1` : '';
    let data = {};

     search ? data.search = {value: search} : null;
     sortBy && sortBy[0] ? data.sort = [{field: sortBy[0], direction: sortDesc[0] ? 'desc' : 'asc'}] : null;

     axiosInstance.post(`shortUrls/search?limit=${itemsPerPage}&page=${page}${onlyTrashed}`, data)
       .then((response) => {
         commit('setFetchedListing', response.data.data);
         commit('setListingTotalItems', response.data.meta.total);
         commit('setIsListingLoad', false);
       });
    },
    destroyItem({ dispatch }, id) {

      ShortUrls.$query().destroy(id).then(() => {
        dispatch('snackbar/showSuccessfullyDelete', null, { root: true });
      }).catch(() => {
        dispatch('snackbar/showDeleteError', null, { root: true });
      });
    },
    storeItem({ commit, dispatch }, {form, isNeedRedirect, router}) {
      commit('setIsSaveButtonDisabled', true);

      ShortUrls.$query().store(form).then((response) => {
        commit('setShortUrl', response.$attributes.shortUrl);
        dispatch('snackbar/showSuccessfullySaved', null, { root: true });
        isNeedRedirect ? router.push({ name: 'shortUrls-index' }) : '';
      }).catch((e) => {
        if (e.response.data.message) {
          dispatch("snackbar/showSnackbar", { color: "red", text: `Ошибка: ${e.response.data.message}` }, { root: true });
        } else {
          dispatch('snackbar/showSaveError', null, { root: true });
        }
      }).finally(() => {
        commit('setIsSaveButtonDisabled', false);
      });
    },
    getByUrl({ commit }, url) {
      let data = {};

      data.filters = [
        {
          "field" : "url",
          "operator" : "=",
          "value" : url
        },
      ];

      axiosInstance.post(`shortUrls/search`, data)
        .then((response) => {
          commit('setShortUrl', response.data.data[0].shortUrl);
        });
    },
    async getStatistics({ commit, dispatch }, id) {
      await axiosInstance.get(`shortUrls/stats/${id}`).then((response) => {
        if (response.data)
          commit('setStats', response.data);
      }).catch(() => {
        dispatch("snackbar/showUnknownError", null , { root: true })
      })
    }
  }
}
