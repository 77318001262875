<template>
  <component :is="theme.components.BaseElementLayout"
             :el$="el$"
  >
    <template slot="field">
      <slot name="prefix"></slot>

      <MediaWidget :label="label" :medias="model" :is-multiple="false" @onChange="setImage"/>

      <media-uploader/>
      <slot name="suffix"></slot>
    </template>

    <slot slot="label" name="label" :el$="el$"></slot>
    <slot slot="info" name="info" :el$="el$"></slot>
    <slot slot="before" name="before" :el$="el$"></slot>
    <slot slot="between" name="between" :el$="el$"></slot>
    <slot slot="error" name="error" :el$="el$"></slot>
    <slot slot="after" name="after" :el$="el$"></slot>
  </component>
</template>

<script>
import BaseElement from '@laraform/laraform/src/mixins/BaseElement'
import BaseValidation from '@laraform/laraform/src/mixins/BaseValidation'
import MediaWidget from "../MediaWidget";
import MediaUploader from "@/components/MediaUploader";

export default {
  name: "MediaElement",
  components: {MediaUploader, MediaWidget},
  mixins: [BaseElement, BaseValidation],
  methods: {
    // eslint-disable-next-line no-unused-vars
    setImage(image) {
      this.model = image;
    }
  },
}
</script>

<style scoped>

</style>
