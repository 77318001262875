<template>
  <v-form>


    <v-card class="m-7">
      <v-card-text class="pa-5 border-bottom">
        <h3 class="title blue-grey--text text--darken-2 font-weight-regular">Шаг №1.</h3>
        <h6
          class="subtitle-2 font-weight-light"
        >Оформляем панели для открытого лендинга курса, на котором будет осуществляться продажа курса</h6>
      </v-card-text>
      <v-card-text class="pa-5 border-bottom">
        <h3 class="title blue-grey--text text--darken-2 font-weight-regular">Информация, используемая в Шапке
          лендинга</h3>
        <div class="p-4">
          <v-row>

            <v-col cols="12" sm="12">
              <v-text-field
                v-model="form.title"
                label="Название курса"
                name="title"
                hint="Главный заголовок, по которому будет происходить индекссация и ранжирование в поиске. Желательно, чтобы заголовок отражал основную/ общую тему курса. Если автор - известная личность, то его имя в названии будет помогать поиску в интернете."
                persistent-hint
                outlined
                counter="150"
                required
              ></v-text-field>
            </v-col>
          </v-row>


          <v-row>
            <v-col cols="12" sm="12">
              <v-textarea
                v-model="form.description"
                :rules="rules.description"
                hint="Обязательно используем в описании слово 'видеокурс'. Чтобы отразить суть курса, достаточно перечислить темы (не названия) уроков и/или интересные вопросы, оформленные в красивое предложение."
                persistent-hint
                outlined
                counter="180"
                label="Подзаголовок"
                name="description"
                required
              ></v-textarea>
            </v-col>
          </v-row>


          <v-row>
            <v-col cols="12" sm="12">
              <v-autocomplete
                v-if="persons"
                v-model="form.person_id"
                :items="persons"
                item-text="full_name"
                item-value="id"
                persistent-hint
                outlined
                hint="Выбираем нужную персону (преподователя) из списка. Если его нет — нужно занести его БИО в справочник Персоны и потом уже выбрать в курсе."
                label="Выберите персону"
                name="person_id"
                required
              ></v-autocomplete>
            </v-col>
          </v-row>


          <v-row>
            <v-col cols="12" sm="12">
              <v-text-field
                type="time"
                persistent-hint
                outlined
                hint="Данная информация позволяет пользователю оценить требуемое от него время и усилия на прохождение курса.  Было бы отлично, если бы это могло просчитываться автоматически исходя из суммы длительности уроков. А еще в случае нестандартных ситуаций было бы здорово, если бы это поле если что можно было бы подправить вручную."
                v-model="formTime"
                label="Общая длительность всех видео"
                required
              />
            </v-col>
          </v-row>


        </div>
      </v-card-text>
      <v-card-text class="pa-5 border-bottom">
        <h3 class="title blue-grey--text text--darken-2 font-weight-regular">Содержательная панель курса</h3>
        <div class="p-4">

          <v-row>
            <v-col cols="12" sm="12">
              <v-text-field
                v-model="form.video_url"
                persistent-hint
                outlined
                hint="В это поле вставляется ссылка на видео-тизер, который  предварительно размещается в закрытом плейлисте на канале Youtube  вашего проекта. Видео должно быть открыто для просмотра всем. В качестве тизера используется короткое видео до 1 минуты, где автор курса произносит 'продающий текст' для своего курса. "
                label="Ссылка на видео-тизер к курсу"
                name="video_url"
                type="text"
                required
              ></v-text-field>
            </v-col>
          </v-row>


          <v-row>
            <v-col cols="12" sm="12">
              <label>Описание к курсу</label>
              <tip-tap
                v-model="form.lead_about_course"

              />

              <div class="v-text-field__details">
                <div class="v-messages theme--light">
                  <div class="v-messages__wrapper">
                    <div class="v-messages__message">
                      Элемент дизайна и восприятия. Пользователь больше прочитывает, если в рамках панели\блока используютшрифты разного кегля. Здесь пишем более точную информацию о курсе.
                    </div>
                  </div>
                </div>
              </div>

            </v-col>
          </v-row>


          <v-row>
            <v-col cols="12" sm="12">

              <label>Подстрочник к описанию</label>
              <tip-tap
                v-model="form.about"

              />

              <div class="v-text-field__details">
                <div class="v-messages theme--light">
                  <div class="v-messages__wrapper">
                    <div class="v-messages__message">
                      Элемент дизайна и восприятия. Пользователь больше прочитывает, если в рамках панели\блока используютшрифты разного кегля. Здесь пишем более точную информацию о курсе.
                    </div>
                  </div>
                </div>
              </div>

            </v-col>
          </v-row>


        </div>
      </v-card-text>
      <v-card-text class="pa-5 border-bottom">
        <h3 class="title blue-grey--text text--darken-2 font-weight-regular">Акценты курса</h3>
        <h6
          class="subtitle-2 font-weight-light"
        >
          Продающий блок. Здесь выводятся 4 наиболее яркие цитаты или фразы или факты из курса, которые могут зацепить покупателя. К ним подбираютя не менее красочные иллюстрации. Всего в панели может участвовать до 4 элементов.
        </h6>
        <div class="p-4">

          <v-row>
            <v-col cols="12" sm="12">

              <v-text-field
                label="Заголовок панели Акценты курса"
                v-model="form.schedule_text"
                persistent-hint
                outlined
                counter="60"
                hint="Краткий заголовок, который должен привлечь внимание пользователя. Редактируется."
                required
              />

            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" sm="12">

              <course-themes
                v-model="form.themes"
                class="mt-2"
              />

            </v-col>
          </v-row>


        </div>
      </v-card-text>
      <v-card-text class="pa-5 border-bottom" >
        <h3 class="title blue-grey--text text--darken-2 font-weight-regular">Панель Курс стоит пройти</h3>
        <h6
          class="subtitle-2 font-weight-light"
        >
          Панель, где тексты и картинки должны аргументировать выбор пользователя. Зачем ему нужно пройти этот курс? Что он получит в итоге? Иллюстрации: используем универсальные, в медиатеке есть постоянные картинки для этой панели</h6>
        <div class="p-4">


          <v-row>
            <v-col cols="12" sm="12">

              <course-reasons
                v-model="form.reasons"
                class="mt-2"
              />

            </v-col>
          </v-row>


        </div>
      </v-card-text>
      <v-card-text class="pa-5 border-bottom" >
        <h3 class="title blue-grey--text text--darken-2 font-weight-regular">Шаг №2.</h3>
        <h6
          class="subtitle-2 font-weight-light"
        >Оформляем панели для закрытых страниц курса, они будут доступны пользователю после покупки</h6>
      </v-card-text>
      <v-card-text class="pa-5 border-bottom" >
        <h3 class="title blue-grey--text text--darken-2 font-weight-regular">Панели для оформления закрытых страниц</h3>

        <div class="p-4">

          <v-row>
            <v-col cols="12" sm="12">
              <v-textarea
                v-model="form.how_going_training_lead"
                label="Приветственный текст после покупки курса"
                persistent-hint
                outlined
                counter="180"
                hint="Это персональное обращение к тем, кто приобрел и начал проходить курс. Желательно брать напутствие от самого автора ( и тогда оформлять как цитату) или же писать от имени школы - на вы."
                required
              />
            </v-col>
          </v-row>


          <v-row>
            <v-col cols="12" sm="12">
              <label>Подстрочник с описанием как проходит курс</label>
              <tip-tap v-model="form.how_going_training_text"/>
              <div class="v-text-field__details">
                <div class="v-messages theme--light">
                  <div class="v-messages__wrapper">
                    <div class="v-messages__message">
                      Пишем в какой последовательности стоит смотреть уроки, что ждет пользователя на каждом этапе и как ему жить дальше с полученными материалами от нас.
                    </div>
                  </div>
                </div>
              </div>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" sm="12">
              <v-textarea
                v-model="form.ending_lead"
                label="Вводный текст на странице завершения курса"
                hint="Вводный текст на странице завершения курса"
                persistent-hint
                required
                outlined
                counter="100"
              >

              </v-textarea>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" sm="12">
              <label>Подстрочник к тексту завершения курса</label>
              <tip-tap
                v-model="form.ending_text"
                class="mb-5"
              />
              <div class="v-text-field__details">
                <div class="v-messages theme--light">
                  <div class="v-messages__wrapper">
                    <div class="v-messages__message">
                      Текст-поздравление с окончанием курса и подведением итогов. Также нужно дописать, что его ждут и другие уроки (универсальная фраза).
                    </div>
                  </div>
                </div>
              </div>
            </v-col>
          </v-row>


        </div>
      </v-card-text>


      <v-card-text class="pa-5 border-bottom">
        <h3 class="title blue-grey--text text--darken-2 font-weight-regular">Шаг №3.</h3>
        <h6
          class="subtitle-2 font-weight-light"
        >Технические настройки курса</h6>
      </v-card-text>
      <v-card-text class="pa-5 border-bottom">

        <div class="p-4">
          <v-text-field
            v-model="form.slug"
            label="Slug"
            name="slug"
            required
            :disabled="!(Object.keys(course).length === 0)"
          ></v-text-field>
          <a
            v-if="course.preview_url"
            :href="preview_url"
            target="_blank"
          >
            {{ preview_url }}
          </a>
          <preview v-if="course.preview_url" :preview-url="preview_url"/>
          <v-card
            flat
            color="transparent"
          >
            <v-subheader>Возраст</v-subheader>

            <v-card-text>
              <v-row>
                <v-col class="px-4">
                  <v-range-slider
                    v-model="range"
                    max="80"
                    min="3"
                    hide-details
                    class="align-center"
                  >
                    <template v-slot:prepend>
                      <v-text-field
                        :value="range[0]"
                        class="mt-0 pt-0"
                        hide-details
                        single-line
                        type="number"
                        style="width: 60px"
                        @change="$set(range, 0, $event)"
                      ></v-text-field>
                    </template>
                    <template v-slot:append>
                      <v-text-field
                        :value="range[1]"
                        class="mt-0 pt-0"
                        hide-details
                        single-line
                        type="number"
                        style="width: 60px"
                        @change="$set(range, 1, $event)"
                      ></v-text-field>
                    </template>
                  </v-range-slider>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>

          <v-text-field
            v-model="form.amount"
            label="Стоимость курса"
            hint="В этом поле задается основная стоимость курса в рублях"
            name="amount"
            type="number"
            prefix="₽"
            :disabled="form.is_free"
            required
          ></v-text-field>
          <v-checkbox
            v-model="form.is_free"
            label="Является бесплатным курсом"
            hint="Определяется является ли курс бесплатным"
            name="is_free"
            type="number"
            required
          />
          <v-checkbox
            v-model="form.is_popular"
            label="Вывести курс на главную страницу сайта в блок популярное"
            name="is_popular"
            hint="Данная отметка ставится для курсов, которые должны появляться в списке на главной странице сайта."
            required
          ></v-checkbox>
          <v-datetime-picker
            v-model="form.pickup_at"
            scrollable
            hint="Устанавливается желаемая дата и время публикации при помощи календаря. В зависимости от даты курс ранжируется в списке."
            label="Дата публикации анонса на главной странице сайта. По умолчанию текущая дата и время"
            clear-text="Очистить"
          ></v-datetime-picker>


          <v-select
            :items="statuses"
            v-model="form.status"
            label="Статус"
          />
          <media-widget
            class="mb-1"
            label="Изображение для анонса на главной и разделах"
            :medias="form.image"
            :is-multiple="false"
            @onChange="setImage"
          />
          <div class="v-text-field__details mb-2">
            <div class="v-messages theme--light">
              <div class="v-messages__wrapper">
                <div class="v-messages__message">
                  Рекомендованный размер 400х600
                </div>
              </div>
            </div>
          </div>
          <media-widget
            class="mb-1"
            label="Превью для видео"
            :medias="form.video_image"
            :is-multiple="false"
            @onChange="setVideoImage"
          />
          <div class="v-text-field__details mb-2">
            <div class="v-messages theme--light">
              <div class="v-messages__wrapper">
                <div class="v-messages__message">
                  Рекомендованный размер 600х400 . Данное изображение появляется: в превью к видео-тизеру,  на первой и завершающей страницах курса.
                </div>
              </div>
            </div>
          </div>
          <media-widget
            class="mb-1"
            label="Изображение в шапке сайта"
            :medias="form.head_image"
            :is-multiple="false"
            @onChange="setHeadImage"
          />
          <div class="v-text-field__details mb-2">
            <div class="v-messages theme--light">
              <div class="v-messages__wrapper">
                <div class="v-messages__message">
                  Рекомендованный размер 3000х800. Важно размещать объекты по центру.
                </div>
              </div>
            </div>
          </div>
        </div>
      </v-card-text>
      <media-uploader @completeUploads="onCompleteUploads"/>


      <div class="pa-5 text-left">
        <v-btn
          color="primary mr-2"
          :disabled="isSaveDisabled"
          :loading="isSaveDisabled"
          @click="submit(true)"
        >
          Сохранить
        </v-btn>
        <v-btn
          color="primary"
          :disabled="isSaveDisabled"
          :loading="isSaveDisabled"
          @click="submit(false)"
        >
          Сохранить и продолжить
        </v-btn>
      </div>
    </v-card>


  </v-form>
</template>

<script>
import slugify from 'slugify';
import MediaUploader from "../MediaUploader";
import MediaWidget from "../MediaWidget";
import TipTap from "../editor/TipTap";
import CourseThemes from "./CourseThemes.vue";
import CourseReasons from "./CourseReasons.vue";
import optionsMixin from "../../mixins/optionsMixin";
import moment from "moment";
import {mapActions, mapGetters} from "vuex";

export default {
  name: "CourseForm",
  mixins: [optionsMixin],
  components: {
    MediaWidget,
    MediaUploader,
    TipTap,
    CourseThemes,
    CourseReasons,
  },
  props: {
    course: {
      type: Object,
      default: () => ({})
    },
  },
  data: () => ({
    form: {
      id: null,
      title: null,
      description: '',
      slug: null,
      language: null,
      person_id: null,
      image: null,
      video_hours_duration: null,
      video_minutes_duration: null,
      conspectus_count: null,
      amount: null,
      about: null,
      lead_about_course: '',
      video_image: null,
      video_url: null,
      education_plan: [],
      themes: [],
      reasons: [],
      ending_text: null,
      is_popular: false,
      pickup_at: null,
      how_going_training_lead: null,
      how_going_training_text: null,
      head_image: null,
      schedule_text: null,
      ending_lead: null,
      status: 0,
      is_free: false,
    },
    range: [3, 80],
    time: {
      hours: 0,
      minutes: 0,
    },
    rules: {
      description: [v => v.length <= 180 || 'Максимум 180 символов!']
    },
    languages: [
      {text: 'Русский', value: 'ru'},
      {text: 'English', value: 'en'},
    ],
    statuses: [
      {text: 'Черновик', value: 0},
      {text: 'Опубликовано', value: 1},
    ],
  }),
  computed: {
    formTime: {
      get() {
        if (this.form.video_hours_duration !== null && this.form.video_minutes_duration !== null) {
          return moment().hour(this.form.video_hours_duration).minute(this.form.video_minutes_duration).seconds(0).format('HH:mm')
        }
        return '00:00';
      },
      set(val) {
        const time = moment(val, 'HH:mm');
        this.form.video_hours_duration = time.hours();
        this.form.video_minutes_duration = time.minutes();
      }
    },
    /*
    isPrymaya() {
      return process.env.VUE_APP_IS_PRYAMAYA === 'true';
    },
     */

    preview_url() {
      if (this.form.preview_url) {
        const preview = this.form.status === 1 ? '' : '?preview_mode=true'
        return this.form.preview_url + preview;
      }

      return null;
    },

    ...mapGetters({
      persons: 'Persons/getListingWithoutFilters',
      isSaveDisabled: 'Courses/isSaveButtonDisabled',
    })
  },
  created() {
    if (this.$route.params.data) {
      this.form = JSON.parse(this.$route.params.data);
      this.form.language = this.language;
    }
    this.fetchPersons();
    console.log(process.env);
  },
  mounted() {
    this.form.language = this.language;
  },
  methods: {
    ...mapActions({
      fetchPersons: 'Persons/fetchListingWithoutFilters',
    }),
    submit(isNeedRedirect) {
      this.form.age_from = this.range[0];
      this.form.age_to = this.range[1];

      this.form.pickup_at = this.form.pickup_at
        ? moment(this.form.pickup_at).format('YYYY-MM-DD HH:mm')
        : null;

      this.$emit('onSubmit', {
        form: this.form,
        mediaIds: this.mediaIds,
        isNeedRedirect: isNeedRedirect,
      });
    },
    async onCompleteUploads(ids) {
      this.mediaIds = ids;
      await this.submit();
      this.mediaIds = [];
    },
    setImage(image) {
      this.form.image = image;
    },
    setVideoImage(image) {
      this.form.video_image = image;
    },
    setHeadImage(image) {
      this.form.head_image = image;
    },
  },
  watch: {
    course: {
      handler(course) {
        if (course && Object.keys(course).length > 0) {
          this.form = course;
          this.range[0] = this.form.age_from;
          this.range[1] = this.form.age_to;
          this.form.language = this.language;
          this.$forceUpdate();
        }
      },
      immediate: true,
      deep: true,
    },
    'form.title': {
      handler(value) {
        if (value && Object.keys(this.course).length === 0) {
          this.form.slug = slugify(value.toLowerCase()).match(/[a-z]|[0-9]|[-]/gm).join('');
        }
      }
    }
  },
}
</script>
