import Index from '../../views/main/orders/Index'

export default [
  {
    component: Index,
    name: 'orders-index',
    path: '/orders',
    meta: {
      layout: 'main-layout',
      requiresAuth: true,
      module: 'orders',
    }
  },
]
