<template>
  <v-app id="materialpro" :class="`${!$vuetify.breakpoint.smAndDown ? 'full-sidebar' : 'mini-sidebar'}`">
    <component :is="layout"></component>
  </v-app>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'application',
  watch: {
    '$route.meta.layout'(value) {
      this.setLayout(value);
    }
  },
  data() {
    return {
      layout: 'empty-layout'
    };
  },
  async created() {
    this.setLayout(this.$route.meta.layout || 'empty-layout');
    this.checkToken();
    await this.fetchSites().then(() => {
      document.title = this.getOption('siteName', 'Admin');
      if (this.site.icon) {
        const element = document.getElementById('head_icon');
        element.setAttribute('href', this.site.icon);
      }
    });
    this.onUser();
  },
  computed: {
    ...mapGetters({
      getOption: 'options/getOption',
      site: 'SiteSwitcher/getSelectedSite',
    })
  },
  methods: {
    ...mapActions({
      checkToken: 'auth/checkToken',
      getUser: 'auth/getUser',
      logout: 'auth/logout',
      fetchSites: 'SiteSwitcher/fetchSites',
    }),
    async onUser() {
      try {
        await this.getUser();
      } catch (e) {
        await this.logout();
      }
    },
    setLayout(value) {
      this.layout = value
    },
  },
}
</script>

<style lang="scss">
html {
  overflow: auto;
}
</style>

