import {Person} from "../../../models/person";
import axiosInstance from "../../../axiosInstance";

export default {
  namespaced: true,
  state: {
    listingWithoutFilters: [],
    isSaveButtonDisabled: false,
    fetchedList: [],
  },
  getters: {
    getListingWithoutFilters: (state) => state.listingWithoutFilters,
    isSaveButtonDisabled: (state) => state.isSaveButtonDisabled,
    getFetchedList: (state) => state.fetchedList,
  },
  mutations: {
    setListingWithoutFilters(state, val) {
      state.listingWithoutFilters = val;
    },
    setIsSaveButtonDisabled(state, val) {
      state.isSaveButtonDisabled = val;
    },
    setFetchedList(state, val) {
      state.fetchedList = val;
    }
  },
  actions: {
    fetchListingWithoutFilters({ commit }) {
      Person.$query().get(100000).then((response) => {
        commit('setListingWithoutFilters', response.map((item) => item.$attributes));
      })
    },
    async submitSort({commit, state, dispatch}) {
      commit('setIsSaveButtonDisabled', true);

      let formattedData = {};
      const items = state.listingWithoutFilters;

      items.forEach(function (element, index) {
        formattedData[element.id] = {position: index}
      });

      await axiosInstance.patch('persons/batch', { resources: formattedData }).then(() => {
        dispatch('snackbar/showSuccessfullySaved', null, { root: true });
        dispatch('fetchListingWithoutFilters');
      }).catch(() => {
        dispatch('snackbar/showSaveError', null, { root: true });
      }).finally(() => {
        commit('setIsSaveButtonDisabled', false);
      })
    },
    async fetchList({ commit }) {
      await axiosInstance.get('persons/select-list').then((response) => {
        commit('setFetchedList', response.data.data);
      })
    }
  }
}

