<template>
  <v-container fluid class="down-top-padding">
    <BaseBreadcrumb :title="page.title" :icon="page.icon" :breadcrumbs="breadcrumbs"></BaseBreadcrumb>



    <v-row>  <v-col    >
      <v-bottom-navigation>
        <v-btn :href="`/chimera-courses/`"   >
          <span>Список курсов</span>

          <v-icon>mdi-book</v-icon>
        </v-btn>

        <v-btn :href="`/chimera-courses/edit/${$route.params.id}`"   >
          <span>Редактирование курса</span>

          <v-icon>mdi-book-open-page-variant-outline</v-icon>
        </v-btn>

        <v-btn  color="grey lighten-2"  :href="`/chimera-courses/${$route.params.id}/lessons/`" >
          <span>Редактирование уроков</span>

          <v-icon>mdi-format-page-break</v-icon>
        </v-btn>

      </v-bottom-navigation>
    </v-col>
    </v-row>




    <v-row>  <v-col    >
      <v-tabs    background-color="transparent"
                 v-model="tab"
                 color="basil"
                 vertical>
      <v-tab>Основная информация</v-tab>
      <v-tab v-if="!this.$route.query.parent_id">Подстраницы</v-tab>
      <v-tab>Таксономия</v-tab>
      <v-tab-item>
        <LessonForm @onSubmit="submit" :lesson="fetchedDetail"/>
      </v-tab-item>
      <v-tab-item v-if="!this.$route.query.parent_id">
        <SubLessonComponent/>
      </v-tab-item>
      <v-tab-item>
        <collection-relation-form
          module-name="Courses"
          :selected-collection-items="fetchedDetail.selected_collection_items"
          :main-items="fetchedDetail.main_taxonomy_ids"
          @onSubmit="submitCollection"
          @onSubmitMain="submitCollectionsMainItem"
        />
      </v-tab-item>
    </v-tabs>

    </v-col>
    </v-row>
  </v-container>
</template>

<script>
import BaseBreadcrumb from "../../../../components/commonComponents/BaseBreadcrumb";
import LessonForm from "../../../../components/chimeraCourses/lessons/LessonForm";
import { mapActions, mapGetters } from "vuex";
import SubLessonComponent from "../../../../components/chimeraCourses/lessons/SubLessonComponent";
import CollectionRelationForm from "../../../../components/collections/CollectionRelationForm";

export default {
  name: "Edit",
  components: {
    SubLessonComponent,
    BaseBreadcrumb,
    LessonForm,
    CollectionRelationForm
  },
  data: () => ({
    page: {
      title: 'Редактирование урока',
      icon: 'mdi-book-plus-outline',
    },
    tab: 0,
  }),
  created() {
    this.fetchDetail(this.$route.params.lessonID);
    this.fetchCourseDetail(this.$route.params.id);
    if (this.$route.query.tab) {
      this.tab = parseInt(this.$route.query.tab);
    }
  },
  computed: {
    ...mapGetters({
      fetchedDetail: "ChimeraLessons/getFetchedDetail",
      fetchedCourse: "ChimeraCourses/getFetchedDetail",
    }),
    breadcrumbs() {
      let arr = [
        {
          text: "Главная",
          disabled: false,
          href: '/',
        },
        {
          text: "Образовательные курсы",
          disabled: false,
          href: '/chimera-courses/',
        },
        {
          text: this.fetchedCourse.title || 'Курс',
          disabled: false,
          href: `/chimera-courses/edit/${this.$route.params.id}`,
        },
        {
          text: 'Список уроков',
          disabled: false,
          href: `/chimera-courses/${this.$route.params.id}/lessons`
        },
        {
          text: this.fetchedDetail.parent_title || this.fetchedDetail.title || 'Редактирование урока',
          disabled: !this.fetchedDetail.parent_id,
          href: `/chimera-courses/${this.$route.params.id}/lessons/edit/${this.fetchedDetail.parent_id}`,
        }
      ]

      if (this.fetchedDetail.parent_id) {
        arr.push(
          {
            text: 'Подстраницы',
            disabled: false,
            href: `/chimera-courses/${this.$route.params.id}/lessons/edit/${this.fetchedDetail.parent_id}?tab=1`
          },
          {
            text: this.fetchedDetail.title || 'Редактирование урока',
            disabled: true,
          }
        )
      }

      return arr;
    }
  },
  methods: {
    ...mapActions({
      fetchDetail: "ChimeraLessons/fetchDetail",
      fetchCourseDetail: "ChimeraCourses/fetchDetail",
      updateLesson: "ChimeraLessons/updateLesson",
      submitCollections: "ChimeraLessons/submitCollections",
      submitMainCollectionItems: "ChimeraLessons/makeMainCollectionItem"
    }),
    submit({ form, needRedirect }) {
      this.updateLesson({
        form: form,
        needRedirect: needRedirect,
        router: this.$router,
      })
    },
    async submitCollection({ items, isNeedRedirect }) {
      await this.submitCollections({ items: items}).then(() => {
        if (isNeedRedirect) {
          this.$router.push({ name: 'chimera-lessons-index', params: { id: this.fetchedDetail.course_id } });
        }
      });
    },
    async submitCollectionsMainItem(items) {
      await this.submitMainCollectionItems({ items: items });
    }
  }
}
</script>

<style scoped>

</style>
