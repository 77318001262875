<template>
  <v-form ref="form" lazy-validation v-model="valid">
    <v-text-field
      v-model="form.last_name"
      label="Фамилия"
    />
    <v-text-field
      v-model="form.first_name"
      label="Имя"
    />
    <v-text-field
      v-model="form.name"
      label="Отчество"
    />
    <v-text-field
      v-model="form.email"
      :rules="rules.emailRules"
      label="Email"
      type="email"
    />
    <v-text-field
      v-model="form.phone"
      label="Номер телефона"
      type="phone"
      />
    <v-text-field
      v-model="form.password"
      prepend-icon="mdi-key-arrow-right"
      @click:prepend="generatePassword"
      label="Пароль"
    >
    </v-text-field>
    <v-autocomplete label="Роли" multiple :items="rolesList" item-text="name" item-value="id" chips v-model="form.roles">
      <template v-slot:selection="data">
        <v-chip
          v-bind="data.attrs"
          :input-value="data.selected"
          close
          @click="data.select"
          @click:close="removeRole(data.item)"
        >
          {{ data.item.name }}
        </v-chip>
      </template>
      <template v-slot:item="data">
        <v-list-item-content>
          <v-list-item-title>
            {{ data.item.name }}
          </v-list-item-title>
          <v-list-item-subtitle>
            {{ data.item.description }}
          </v-list-item-subtitle>
        </v-list-item-content>
      </template>
    </v-autocomplete>
    <v-select label="Поиск..." chips item-text="name" item-value="id" v-model="form.model_permissions" :items="copyPermissionList" multiple>
      <template v-slot:selection="data">
        <v-chip
          v-bind="data.attrs"
          :input-value="data.selected"
          close
          @click="data.select"
          @click:close="removePermission(data.item)"
        >
          {{ data.item.name }}
        </v-chip>
      </template>
      <template v-slot:prepend-item>
        <v-text-field class="ml-2 mr-2" v-model="searchPermission" @input="search"></v-text-field>
        <v-list-item ripple @click="toggle">
          <v-list-item-action>
            <v-icon :color="form.model_permissions.length > 0 ? 'indigo darken-4': ''">
              {{ icon }}
            </v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>
              Выбрать все
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-divider class="mt-2"/>
      </template>
      <template v-slot:item="data">
        <v-list-item-content>
          <v-list-item-title>
            {{ data.item.name }}
          </v-list-item-title>
          <v-list-item-subtitle>
            {{ data.item.description }}
          </v-list-item-subtitle>
        </v-list-item-content>
      </template>
    </v-select>
    <v-checkbox
      v-model="form.is_verified"
      label="Подтвержденный email"
      />
    <v-btn
      color="primary"
      :disabled="isSaveDisabled"
      :loading="isSaveDisabled"
      @click="submit"
    >
      Сохранить
    </v-btn>
  </v-form>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
  name: "UserForm",
  props: {
    user: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      valid: true,
      rules: {
        emailRules: [
          v => !!v || 'Email is required',
          v => /.+@.+/.test(v) || 'Email is invalid'
        ],
        nameRules: [
          v => !!v || 'Name is required',
          v => (v && v.length > 3) || 'The minimum number of characters is 4'
        ],
      },
      form: {
        name: '',
        email: '',
        password: '',
        roles: [],
        model_permissions: [],
        phone: '',
        is_verified: false,
      },
      searchPermission: "",
      copyPermissionList: [],
    }
  },
  watch: {
    user: {
      handler() {
        if (Object.keys(this.user).length > 0) {
          this.form = JSON.parse(JSON.stringify(this.user));
        }
      },
      deep: true,
      immediate: true,
    },
    permissionsList: {
      handler(val) {
        this.copyPermissionList = val;
      }
    }
  },
  created() {
    this.fetchRoles();
    this.fetchPermissions();
  },
  computed: {
    ...mapGetters({
      fetchedDetail: 'Users/getFetchedDetail',
      isSaveDisabled: 'Users/isSaveButtonDisabled',
      rolesList: 'Roles/getFetchedList',
      permissionsList: 'Permissions/getFetchedList',
    }),
    icon () {
      if (this.form.model_permissions.length === this.permissionsList.length) return 'mdi-close-box'
      if (this.form.model_permissions.length > 0 && (this.form.model_permissions.length !== this.permissionsList.length)) return 'mdi-minus-box';
      return 'mdi-checkbox-blank-outline';
    }
  },
  methods: {
    ...mapActions({
      fetchRoles: 'Roles/fetchList',
      fetchPermissions: 'Permissions/fetchList',
    }),
    removeRole(item) {
      const index = this.form.roles.indexOf(item.id);
      if (index >= 0) this.form.roles.splice(index, 1);
    },
    removePermission(item) {
      console.log(this.form.model_permissions);
      console.log(item.id);
      const index = this.form.model_permissions.indexOf(item.id);
      if (index >= 0) this.form.model_permissions.splice(index, 1);
    },
    generatePassword() {
      this.form.password = Math.random().toString(36).slice(-11)
    },
    submit() {
      if (this.$refs.form.validate()) {
        this.$emit('onSubmit', this.form)
      }
    },
    toggle() {
      this.$nextTick(() => {
        if (this.permissionsList.length === this.form.model_permissions.length) {
          this.form.model_permissions = [];
        } else {
          this.form.model_permissions = this.permissionsList.slice();
        }
      })
    },
    search() {
      if (!this.searchPermission) {
        this.copyPermissionList = this.permissionsList;
      } else {
        this.copyPermissionList = this.permissionsList.filter((permission) => {
          let description = false;
          if (permission.description) {
            description = permission.description.toLowerCase().indexOf(this.searchPermission.toLowerCase()) > -1;
          }
          return permission.name.toLowerCase().indexOf(this.searchPermission.toLowerCase()) > -1 || description;

        })
      }
    },
  },
}
</script>

<style scoped>

</style>
