var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app-bar',{style:({
      background: 'rgb(124,58,237)',
      background: 'linear-gradient(90deg, rgba(124,58,237,1) 0%, rgba(248,113,113,1) 73%)',
      boxShadow: '0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)',
    }),attrs:{"app":"","clipped-left":"","clipped-right":"","color":_vm.navbarColor,"dark":_vm.navbarColor !== 'white'}},[(_vm.navbarColor !== 'white')?_c('v-toolbar-title',{staticClass:"align-center d-flex logo-width",class:("" + (_vm.showLogo ? 'logo-width' : ''))},[_c('span',{staticClass:"logo-text ml-2",class:("" + (_vm.showLogo ? '' : 'hidelogo'))},[(_vm.currentSite.site_logo)?_c('img',{staticClass:"mt-2",attrs:{"src":_vm.currentSite.site_logo,"alt":_vm.getOption('siteName', '')}}):_vm._e()])]):_c('v-toolbar-title',{staticClass:"align-center d-flex logo-width",class:("" + (_vm.showLogo ? 'logo-width' : ''))},[_c('span',{staticClass:"logo-text ml-2",class:("" + (_vm.showLogo ? '' : 'hidelogo'))},[(_vm.currentSite.site_logo)?_c('img',{staticClass:"mt-2",attrs:{"src":_vm.currentSite.site_logo,"alt":_vm.getOption('siteName', '')}}):_vm._e()])]),_c('div',{on:{"click":_vm.showhideLogo}},[_c('v-app-bar-nav-icon',{on:{"click":function($event){_vm.$vuetify.breakpoint.smAndDown
            ? _vm.setSidebarDrawer(!_vm.Sidebar_drawer)
            : _vm.$emit('input', !_vm.value)}}})],1),(_vm.showSearch)?_c('v-card',{staticClass:"searchinput"},[[_c('v-text-field',{staticClass:"mt-3 mb-0",attrs:{"placeholder":"Search & hit enter","append-icon":"mdi-close"},on:{"click:append":_vm.searchbox}})]],2):_vm._e(),_c('v-btn',{staticClass:"mr-2",attrs:{"to":"/medias","color":"primary"}},[_vm._v(" Медиа библиотека ")]),_c('v-btn',{staticClass:"mr-2",attrs:{"icon":"","title":"Публичная версия сайта"},on:{"click":_vm.redirectToPublicSite}},[_c('v-icon',[_vm._v("mdi-earth")])],1),(_vm.isAdministrator)?[_c('SiteSwitcher')]:_vm._e(),(_vm.isVisibleCustomers)?_c('v-btn',{staticClass:"mr-2",staticStyle:{"text-decoration":"none"},attrs:{"icon":"","href":"/customers"}},[_c('v-icon',[_vm._v(" mdi-face-agent ")])],1):_vm._e(),(_vm.hasBetaRole)?_c('v-btn',{staticStyle:{"text-decoration":"none"},attrs:{"icon":"","color":"primary","to":"/logs"}},[_c('v-icon',[_vm._v(" mdi-math-log ")])],1):_vm._e(),_c('v-spacer'),(_vm.isVisibleExcel)?_c('v-btn',{staticClass:"mr-2",attrs:{"color":"primary","to":"/partners/excelV2?tab=0&entity=partners"}},[_vm._v(" Excel-таблицы ")]):_vm._e(),(_vm.isLegasyMinfin)?_c('v-btn',{attrs:{"color":"primary","href":"/temp/region-statistic"}},[_vm._v(" Регионы Excel ")]):_vm._e(),_c('v-spacer'),_c('v-menu',{attrs:{"bottom":"","left":"","offset-y":"","origin":"top right","transition":"scale-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mr-2",attrs:{"dark":"","icon":""}},on),[_c('v-icon',[_vm._v("mdi-cog")])],1)]}}])},[_c('v-list',[_c('h4',{staticClass:"px-5 pt-2 py-3 font-weight-medium title"},[_vm._v("Настройки")]),_c('v-divider'),_vm._l((_vm.filtrableSettingMenuItems),function(item,i){return _c('v-list-item',{key:i,attrs:{"to":item.to},on:{"click":_vm.href}},[_c('v-list-item-icon',{staticClass:"mr-2"},[_c('v-icon',{domProps:{"textContent":_vm._s(item.icon)}})],1),_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(item.title)}})],1)],1)})],2)],1),_c('v-menu',{attrs:{"bottom":"","left":"","offset-y":"","origin":"top right","transition":"scale-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mr-2",attrs:{"dark":"","icon":""}},on),[_c('v-icon',[_vm._v("mdi-book-open-page-variant")])],1)]}}])},[_c('v-list',[_c('h4',{staticClass:"px-5 pt-2 py-3 font-weight-medium title"},[_vm._v("Справочник")]),_c('v-divider'),_vm._l((_vm.filtrableReferenceMenuItems),function(item,i){return _c('v-list-item',{key:i,attrs:{"to":item.to},on:{"click":_vm.href}},[_c('v-list-item-icon',{staticClass:"mr-2"},[_c('v-icon',{domProps:{"textContent":_vm._s(item.icon)}})],1),_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(item.title)}})],1)],1)})],2)],1),_c('UserMenu')],2)}
var staticRenderFns = []

export { render, staticRenderFns }