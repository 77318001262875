<template>
  <div>
    <v-form ref="form">
      <v-row>
        <v-col>
          <v-text-field
            label="Вопрос"
            required
            v-model="form.question"
            :rules="rules"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <tip-tap
            v-model="form.answer"
            label="Ответ"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-datetime-picker
            label="Дата публикации"
            v-model="form.published_at"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-checkbox
            label="Важный вопрос"
            v-model="form.is_important"
            clear-text="Очистить"
            :value="1"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-checkbox
            label="Опубликовано"
            v-model="form.published"
            clear-text="Очистить"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col v-if="FAQ.dynamic_fields">
          <FieldComponent v-model="FAQ.dynamic_fields" title="Дополнительные поля"/>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-btn
            class="mr-4"
            color="primary"
            :disabled="isSaveButtonDisabled"
            :loading="isSaveButtonDisabled"
            @click="submit(true)"
          >Сохранить
          </v-btn>
          <v-btn
            class="mr-4"
            color="primary"
            :disabled="isSaveButtonDisabled"
            :loading="isSaveButtonDisabled"
            @click="submit(false)"
          >Сохранить и продолжить
          </v-btn>
        </v-col>
        <v-col>
          <v-btn
            color="primary"
            @click="$router.push({ name: 'faq-index' })"
          >
            Назад
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import moment from "moment/moment";
import TipTap from "../editor/TipTap.vue";
import FieldComponent from "../../fields/FieldComponent";

export default {
  name: "FAQForm",
  components:{
    FieldComponent,
    TipTap,
  },
  props: {
    FAQ: {
      type: Object,
      default: () => ({}),
      required: true,
    }
  },
  data() {
    return {
      isSaveDisabled: false,
      form: {
        question: null,
        answer: null,
        published_at: moment().format('YYYY-MM-DD HH:MM'),
        published: 1,
        user_id: null,
        is_important: 0,
      },
      rules: [
        v => !!v || 'Поле обязательное для заполнения',
        v => (v && v.length >= 3) || 'Поле должно содержать не менее 3 символов',
      ]
    }
  },
  computed: {
    ...mapGetters({
      userInfo: "auth/getUser",
      isSaveButtonDisabled: "FAQ/isSaveButtonDisabled",
    }),
  },
  methods: {
    submit(isNeedRedirect) {
      if (this.$refs.form.validate() && (this.form.answer && this.form.answer.length > 0)) {
        this.$emit('onSubmit', {
          form: this.form,
          isNeedRedirect: isNeedRedirect
        })
      }
    },
  },
  mounted() {
    this.form.user_id = this.userInfo.id;
  },
  watch: {
    FAQ: {
      handler() {
        if (this.FAQ && Object.keys(this.FAQ).length > 0) {
          this.form = {...this.form, ...JSON.parse(JSON.stringify(this.FAQ))};
          this.isSaveDisabled = false;
        }
      },
      deep: true,
    },
  }
}
</script>

<style scoped>

</style>
