<template>
  <div>
    <v-card class="mt-7">
      <v-card-text class="pa-5 border-bottom">
        <div class="p-4">
          <v-row>
            <v-col cols="12" md="12">
              <v-text-field
                v-model="form.title"
                label="Название урока"
                name="title"
                hint="Отображается на внешней странице курса и в личном кабинете."
                persistent-hint
                outlined
                required
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="12">
              <v-text-field
                v-model="form.slug"
                label="Slug"
                name="slug"
                required
                :disabled="!(Object.keys(lesson).length === 0)"
              ></v-text-field>
              <a
                v-if="lesson.preview_url"
                :href="getPreviewUrl"
                target="_blank"
              >
                {{ getPreviewUrl }}
              </a>
              <preview v-if="lesson.preview_url" :preview-url="getPreviewUrl"/>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="12">
              <v-textarea
                v-model="form.description"
                :rules="rules.description"
                hint="Отображается на внешней странице курса и в личном кабинете."
                persistent-hint
                outlined
                counter="100"
                label="Тема урока"
                name="description"
                required
              />
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" sm="12">
              <v-text-field
                v-model="form.teaser_video_url"
                persistent-hint
                outlined
                hint="В это поле вставляется ссылка на видео-тизер, который вы предварительно разместили в своем канале Youtube или другом публичном файлообменнике. Видео должно быть открыто для просмотра всем. В качестве тизаер используется короткое видео до 1 минуты, где автор курса произности 'продающий текст' для своего курса. Пользователь ориентируется в выборе опираясь либо на текст в отписании Курса, либо на видео-тизер, либо использует оба формата для собсвенного убждения в правильности выбора."
                label="Ссылка на видео-тизер к курсу"
                name="teaser_video_url"
                type="text"
                required
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="12">
              <label>Описание урока</label>
              <tip-tap
                ref="tipTap"
                v-model="form.advanced_description"
              />
              <div class="v-text-field__details">
                <div class="v-messages theme--light">
                  <div class="v-messages__wrapper">
                    <div class="v-messages__message" :class="{ 'error--text': tipTapCounter > 500 }">
                      Используется для оформления страниц конкретного урока
                    </div>
                  </div>
                </div>
                <div class="v-counter theme--light" :class="{ 'error--text': tipTapCounter > 500 }"> {{ tipTapCounter }} / 500</div>
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="12">
              <v-text-field
                type="time"
                v-model="formTime"
                label="Длительность видео"
                persistent-hint
                outlined
                required
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col col="12" md="12">
              <v-text-field
                v-model="form.facecast_code"
                label="Код видео Facecast"
                outlined
                persistent-hint
                required
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="12">
              <media-widget
                class="mb-2"
                label="Файл конспекта"
                :medias="form.conspectus_file"
                :is-multiple="false"
                @onChange="setConspectusFile"
              />
              <div class="v-text-field__details">
                <div class="v-messages theme--light">
                  <div class="v-messages__wrapper">
                    <div class="v-messages__message">
                      PDF-файл, который пердоставляется пользователю для скачивания и самостоятельной работы. Файл загружается в медиабиблиотеку.
                    </div>
                  </div>
                </div>
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="12">
              <v-select
                :items="statuses"
                v-model="form.status"
                hint="Для работы есть два статуса. 1) Черновик - все до полного утверждения и сборки курса должно быть в черновиках. Опубликовано - урок доступен для представления в рамках личного кабинета и курса."
                outlined
                persistent-hint
                label="Статус"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="12">
              <media-widget
                class="mb-1"
                label="Заставка на видео-тизер"
                :medias="form.teaser_video_image"
                :is-multiple="false"
                @onChange="setVideoImage"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="12">
              <media-widget
                class="mb-1"
                label="Изображение для шапки урока"
                :medias="form.image"
                :is-multiple="false"
                @onChange="setImage"
              />
              <div class="v-text-field__details">
                <div class="v-messages theme--light">
                  <div class="v-messages__wrapper">
                    <div class="v-messages__message">
                      Рекомендованный размер: 3000х800. Не рекомендуется использовать людей крупно, крупные объекты — все будет резаться на ресайзах.
                    </div>
                  </div>
                </div>
              </div>
            </v-col>
          </v-row>
        </div>
      </v-card-text>
      <v-card-actions class="pa-5 text-left">
        <v-btn
          color="primary mr-2"
          :disabled="isSaveDisabled"
          :loading="isSaveDisabled"
          @click="submit(true)"
        >
          Сохранить
        </v-btn>
        <v-btn
          color="primary"
          :disabled="isSaveDisabled"
          :loading="isSaveDisabled"
          @click="submit(false)"
        >
          Сохранить и продолжить
        </v-btn>
      </v-card-actions>
      <media-uploader @completeUploads="onCompleteUploads"/>
    </v-card>
  </div>
</template>

<script>
import slugify from 'slugify';
import MediaUploader from "../MediaUploader";
import MediaWidget from "../MediaWidget";
import TipTap from "../editor/TipTap";
import optionsMixin from "../../mixins/optionsMixin";
import {mapGetters} from "vuex";
import moment from "moment";

export default {
  name: "LessonForm",
  mixins: [optionsMixin],
  components: {

    TipTap,
    MediaWidget,
    MediaUploader,
  },
  props: {
    lesson: {
      type: Object,
      default: () => ({})
    },
  },
  data: () => ({
    form: {
      id: null,
      title: '',
      description: '',
      slug: null,
      language: null,
      course_id: null,
      image: null,
      video_hours_duration: null,
      video_minutes_duration: null,
      conspectus_file: null,
      position: 0,
      facecast_code: null,
      advanced_description: null,
      teaser_video_url: null,
      teaser_video_image: null,
      control_question: {
        text: null,
        answers: [],
      },
      status: 0,
    },
    rules: {
      description: [v => v.length <= 250 || 'Максимум 250 символов!']
    },
    languages: [
      {text: 'Русский', value: 'ru'},
      {text: 'English', value: 'en'},
    ],
    time: {
      hours: 0,
      minutes: 0,
    },
    statuses: [
      {text: 'Черновик', value: 0},
      {text: 'Опубликовано', value: 1},
    ],
    currentMedia: {
      id: null,
      entity: 'Modules\\Courses\\Entities\\Lesson',
      entity_id: null,
      structure: null,
    },
    reRenderMedia: 1,
    tab: 0,
    editor: null,
  }),
  created() {
    if (this.$route.params) {
      this.form = this.$route.params;
      this.form.language = this.language;
    }
  },
  mounted() {
    this.editor = this.$refs.tipTap.$data.editor;
    this.form.language = this.language;
  },
  computed: {
    ...mapGetters({
      isSaveDisabled: 'Lessons/getIsButtonDisabled'
    }),
    formTime: {
      get() {
        if (this.form.video_hours_duration) {
          return moment().hour(this.form.video_hours_duration).minute(this.form.video_minutes_duration).seconds(0).format('HH:mm')
        }
        return '00:00';
      },
      set(val) {
        const time = moment(val, 'HH:mm');
        this.form.video_hours_duration = time.hours();
        this.form.video_minutes_duration = time.minutes();
      },
    },
    getFormData() {
      const formData = new FormData();

      Object.keys(this.form).forEach((key) => {
        if (typeof this.form[key] === 'object') {
          formData.set(key, JSON.stringify(this.form[key]));
        } else {
          formData.set(key, this.form[key]);
        }
      })

      formData.set('conspectus_file', this.form.conspectus_file);

      return formData;
    },
    getPreviewUrl() {
      if (this.lesson && this.lesson.course) {
        const preview = this.form.status === 1 ? '' : '?preview_mode=true'
        return `${process.env.VUE_APP_CLIENT_URL}/user/courses/${this.lesson.course.slug}/${this.lesson.slug}${preview}`;
      }

      return '';
    },
    tipTapCounter() {
      if (this.editor) {
        return this.editor.getCharacterCount()
      }

      return 0;
    },
  },
  methods: {
    async submit(isNeedRedirect) {
      // const data = await this.$refs['media-form'].submit();
      //
      // if (data) {
      //   this.currentMedia.structure = data;
      // }

      this.$emit('onSubmit', {
        form: this.form,
        mediaIds: this.mediaIds,
        media: this.currentMedia,
        isNeedRedirect: isNeedRedirect,
      });
    },
    async onCompleteUploads(ids) {
      this.mediaIds = ids;
      await this.submit();
      this.mediaIds = [];
    },
    setImage(image) {
      this.form.image = image;
    },
    setConspectusFile(file) {
      this.form.conspectus_file = file;
    },
    setVideoImage(image) {
      this.form.teaser_video_image = image;
    },
  },
  watch: {
    lesson: {
      handler(lesson) {
        if (lesson && Object.keys(lesson).length > 0) {
          this.form = lesson;
          if (this.form.media) {
            this.currentMedia = this.form.media;
            this.reRenderMedia += 1;
          }
          this.form.language = this.language;
        }
      },
      immediate: true,
      deep: true,
    },
    'form.title': {
      handler(value) {
        if (value && Object.keys(this.lesson).length === 0) {
          this.form.slug = slugify(value.toLowerCase()).match(/[a-z]|[0-9]|[-]/gm).join('');
        }
      }
    }
  },
}
</script>
