import MainRoutes from './routes/main';
import ClearRoutes from './routes/clear';
import AuthRoutes from './routes/auth';

// Errors pages
import NotFound from './routes/not-found';

import { sync } from 'vuex-router-sync';
import store from './stores';

import Router from 'vue-router';
import Vue from 'vue';

Vue.use(Router);

const setRoutes = (items) => {
  const routes = [];
  items.forEach((item) => {
    if (Array.isArray(item)) {
      routes.push(...item)
    } else {
      routes.push(item);
    }
  })
  return routes;
}

const router = new Router({
  mode: 'history',
  routes: setRoutes([
    MainRoutes,
    AuthRoutes,
    ClearRoutes,
    NotFound
  ])
});

sync(store, router);
router.beforeEach(async (to, from, next) => {
  const isCheckAuth = to.matched.some(record => record.meta.requiresAuth);
  const hasAccess = to.matched.some(record => record.meta.hasAccess);
  const isModule = to.matched.some(record => record.meta.module);

  if (from.name === null) {
    await store.dispatch('auth/checkToken');
  }

  const isUserAuth = store.getters['auth/isAuthenticated'];
  const userData = store.getters['auth/getUser'];

  if (isCheckAuth && !isUserAuth) {
    store.dispatch('auth/logout')
    next('/auth/login');
  }

  if(userData && isUserAuth) {
    await store.dispatch('auth/getUser');
    next();
  }

  if (hasAccess) {
    if (!(store.getters['auth/getPermissions'].indexOf(to.meta.hasAccess) > -1)) {
      next('/');
    }
    next();
  }

  if (isModule) {
    const moduleName = 'VUE_APP_M_' + to.meta.module.toUpperCase();
    if (process.env[moduleName] !== "true") {
      next('/');
    }
    next();
  }

  next();
});

export default router;
