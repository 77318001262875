import Index from '../../views/main/courses/Index'
import Create from '../../views/main/courses/Create'
import Edit from '../../views/main/courses/Edit';

export default [
  {
    component: Index,
    name: 'courses-index',
    path: '/courses',
    meta: {
      layout: 'main-layout',
      requiresAuth: true,
      module: 'courses',
    }
  },
  {
    component: Create,
    name: 'courses-create',
    path: '/courses/create',
    meta: {
      layout: 'main-layout',
      requiresAuth: true,
      module: 'courses',
    }
  },
  {
    component: Edit,
    name: 'courses-edit',
    path: '/courses/edit/:id',
    meta: {
      layout: 'main-layout',
      requiresAuth: true,
      module: 'courses',
    }
  },
]
