<template>
  <div>
    <v-row>
      <v-col>
        <v-tabs
          v-model="tab"
          vertical
        >
          <v-text-field label="поиск..." @input="(event) => searchOption(event)"/>
          <v-tab
            v-for="(item, index) in options_searchable"
            :key="index"
          >
            <div class="align-self-center">
              <v-icon v-if="isMain(item.id)">
                mdi-star
              </v-icon>
              <v-icon v-else-if="isChecked(item.id)">
                mdi-check
              </v-icon>
              {{ item[textField] }}
            </div>
          </v-tab>
        </v-tabs>
      </v-col>
      <v-col>
        <v-tabs v-model="tab">
          <v-tab-item
            v-for="(item, index) in options_searchable"
            :key="index"
          >
            <v-text-field @input="(event) => searchChildrens(event, index)"></v-text-field>
            <v-card flat>
              <div
                v-if="item.children && item.children.length > 0"
                class="ml-5 text-center"
                :key="index"
              >
                <v-checkbox
                  v-for="(child, index) in item.children"
                  v-model="selected[child.id]"
                  :key="index"
                  :value="1"
                  :unchecked-value="0"
                  :label="child[textField]"
                  :disabled="isMain(child.id)"
                  class="mt-auto mb-auto"
                  @change="onChangeCheckbox(child)"
                />
              </div>
              <div
                v-else
                class="ml-5 text-center"
                :key="index"
              >
                <v-checkbox
                  v-model="selected[item.id]"
                  :key="index"
                  :value="1"
                  :unchecked-value="0"
                  label="Выбрать раздел"
                  :disabled="isMain(item.id)"
                  class="mt-auto mb-auto"
                  @change="onChangeCheckbox(item)"
                />
              </div>
            </v-card>
          </v-tab-item>
        </v-tabs>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Vue from "vue";

export default {
  name: "TabsWithCheckboxes",
  props: {
    value: {
      type: Array,
      default: () => ([]),
    },
    options: {
      type: Array,
      required: true,
    },
    textField: {
      type: String,
      default: 'text'
    },
    valueField: {
      type: String,
      default: 'value'
    },
    itemAction: {
      type: String,
      default: null,
    },
    mainItems: {
      type: Array,
      // eslint-disable-next-line vue/require-valid-default-prop
      default: [],
    },
  },
  data: () => ({
    options_searchable: '',
    tab: 0,
    selected: {},
  }),
  methods: {
    onChangeCheckbox(item) {
      if (item.parent_id) {
        this.selected[item.parent_id] = 1
      }

      Object.keys(this.selected).map((key) => this.selected[key] !== 1 && delete this.selected[key]);
      this.$emit('input', Object.keys(this.selected));
    },
    isMain(id) {
      return this.mainItems.indexOf(id) !== -1
    },
    isChecked(id) {
      return this.selected[id];
    },
    searchOption(event) {
      if (event !== '') {
        let items = JSON.parse(JSON.stringify(this.options));
        this.options_searchable = items.filter((item) => {
          return item.title.toLowerCase().indexOf(event.toLowerCase()) + 1;
        })
      } else {
        this.options_searchable = JSON.parse(JSON.stringify(this.options));
      }
    },
    searchChildrens(event, index){
      if (event !== '') {
        let items = JSON.parse(JSON.stringify(this.options[index].children));
        Vue.set(this.options_searchable[index], 'children', items.filter((item) => {
          return item.title.toLowerCase().indexOf(event.toLowerCase()) + 1;
        }))
      } else {
        let items = JSON.parse(JSON.stringify(this.options[index].children));
        Vue.set(this.options_searchable[index], 'children', items);
      }
    }
  },
  watch: {
    value: {
      handler() {
        if (this.value && this.value.length > 0) {
          this.selected = {};

          this.value.map((item) => {
            this.selected[item] = 1;
          })
        }
      },
      immediate: true,
    },
    mainItems: {
      handler() {
        this.$forceUpdate();
      },
      deep: true,
    },
    options: {
      handler() {
        this.options_searchable = JSON.parse(JSON.stringify(this.options));
      },
      deep: true,
      immediate: true,
    }
  },
}
</script>

<style lang="scss">
.v-tab {
  justify-content: left;
}
</style>
