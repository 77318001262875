<template>
  <v-dialog v-model="dialog" max-width="600">
    <v-card>
      <v-container>
        <v-text-field v-model="form.title" label="Название"/>
        <v-text-field v-model="form.slug" label="Slug"/>
        <v-btn block @click="createEntity">Сохранить</v-btn>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import {mapActions} from "vuex";
import slugify from "slugify";

export default {
  name: "PatternPageCreator",
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    endPoint: {
      type: String,
      default: () => ('/module/chimera-courses/create-from-template'),
    }
  },
  data: () => ({
    form: {
      title: null,
      slug: null,
    }
  }),
  computed: {
    dialog: {
      set(val) {
        this.$emit('input', val)
      },
      get() {
        return this.value;
      }
    }
  },
  methods: {
    ...mapActions({
      createCourse: "ChimeraCourses/createFromTemplate"
    }),
    createEntity() {
      this.form.course_id = this.$route.params.id;

      this.createCourse({
        form: this.form,
        endPoint: this.endPoint,
      }).then(() => {
        this.$emit('onSubmit');
        this.dialog = false;
      })
    }
  },
  watch: {
    'form.title': {
      handler(value) {
        if (value) {
          this.form.slug = slugify(value.toLowerCase()).match(/[a-z]|[0-9]|[-]/gm).join('');
        }
      }
    },
  }
}
</script>

<style scoped>

</style>
