import { Model } from "@tailflow/laravel-orion/lib/model";

export class Page extends Model<{
    parent_id: number,
    slug: string,
    description: string,
    language: string,
    blocks: object[],
    layout: string,
    layouts: string[],
    created_at: string,
    updated_at: string
}> {

    $resource(): string {
        return "pages";
    }

}
