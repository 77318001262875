var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.fetchDetail)?_c('v-container',{staticClass:"down-top-padding",attrs:{"fluid":""}},[_c('BaseBreadcrumb',{attrs:{"title":_vm.page.title,"icon":_vm.page.icon,"breadcrumbs":_vm.breadcrumbs}}),_c('v-card',[_c('v-card-text',{staticClass:"pa-5"},[_c('v-tabs',{staticClass:"mb-5",attrs:{"background-color":"transparent","grow":""},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab',[_vm._v(" Основная информация ")]),_c('v-tab',[_vm._v(" Курсы ")])],1),_c('v-tabs-items',{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab-item',[_c('UserForm',{attrs:{"user":_vm.fetchedUser},on:{"onSubmit":_vm.submit}})],1),_c('v-tab-item',[_c('v-btn',{staticClass:"ml-2",attrs:{"color":"primary"},on:{"click":function($event){_vm.coursesDialog = true}}},[_vm._v(" Добавить ")]),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.userCourses},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(item.preview_url)?_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","href":item.preview_url,"target":"_blank"}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v(" mdi-eye ")])],1):_vm._e()]}}],null,true)},[_c('span',[_vm._v("Предпросмотр курса")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(item.admin_attach_url)?_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.attachItem(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v(" mdi-eye-plus ")])],1):_vm._e()]}}],null,true)},[_c('span',[_vm._v("Предпросмотр купленного курса")])]),_c('v-btn',{attrs:{"href":_vm.editItem(item),"icon":""}},[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v(" mdi-pencil ")])],1),_c('v-icon',{attrs:{"color":"red"},on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v(" mdi-delete ")])]}}],null,false,2874245664)})],1)],1),_c('v-dialog',{attrs:{"max-width":"600px"},model:{value:(_vm.coursesDialog),callback:function ($$v) {_vm.coursesDialog=$$v},expression:"coursesDialog"}},[_c('v-card',[_c('v-container',[_c('v-row',[_c('v-col',[_c('h3',[_vm._v("Добавление курса")])])],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[(_vm.courses)?_c('v-autocomplete',{attrs:{"items":_vm.courses,"item-text":"title","item-value":"id","label":"Выберите курс","name":"course","required":""},model:{value:(_vm.selectCourseId),callback:function ($$v) {_vm.selectCourseId=$$v},expression:"selectCourseId"}}):_vm._e()],1),_c('v-col',[_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.submitAddCourse}},[_vm._v(" Ок ")])],1)],1)],1)],1)],1)],1)],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }