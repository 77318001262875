<template>
  <v-container>
    <v-card class="mb-5">
      <v-card-title>Дочерние элементы</v-card-title>
      <v-card-text>
        <v-data-table
          :headers="headers"
          :items="child"
        >
          <template v-slot:top>
            <v-row>
              <v-col>
                <v-btn
                  color="primary"
                  class="mb-5"
                  :disabled="child.length === 0"
                  @click="showSortableModal = true"
                >
                  Сортировка позиций
                </v-btn>
              </v-col>
              <v-col>
                <v-spacer/>
                <v-btn
                  color="primary"
                  class="mb-5"
                  :href="createChild()"
                >
                  Создать дочерний элемент
                </v-btn>
              </v-col>
            </v-row>
          </template>
          <template v-slot:item.actions="{item}">
            <v-btn
              :href="editItem(item)"
              icon
            >
              <v-icon color="primary">
                mdi-pencil
              </v-icon>
            </v-btn>
            <v-icon
              color="red"
              @click="deleteItem(item)"
            >
              mdi-delete
            </v-icon>
          </template>
          <template v-slot:item.position="{item}">
            <tr>
              {{item.position + 1}}
            </tr>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>

    <v-card v-if="false">
      <v-card-title>Форма для добавление существующего элемента</v-card-title>
      <v-card-subtitle>Выводится только те страницы у которых нет родительской страницы</v-card-subtitle>
      <v-card-text>
        <v-select
          multiple
          item-text="title"
          :items="pagesWithoutParent"
          item-value="id"
          v-model="selectedPages"
        />
      </v-card-text>
      <v-card-actions>
        <v-btn
          color="primary"
          block
          :disabled="isSaveButtonDisable"
          :loading="isSaveButtonDisable"
          @click="attach"
        >
          Сохранить
        </v-btn>
      </v-card-actions>
    </v-card>
    <SortablePages v-model="showSortableModal"/>
  </v-container>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import SortablePages from "./SortablePages";

export default {
  name: "PageChildren",
  components: {SortablePages},
  data: () => ({
    only_trashed: null,
    headers: [
      {text: 'Id', value: 'id'},
      {text: 'Название', value: 'title'},
      {text: 'Slug', value: 'slug'},
      {text: 'Дата создания', value: 'created_at'},
      {text: 'Дата обновления', value: 'updated_at'},
      {text: '', value: 'actions'},
    ],
    tab: 1,
    selectedPages: [],
    showSortableModal: false,
  }),
  computed: {
    ...mapGetters({
      child: 'Pages/getChild',
      pageDetail: 'Pages/getFetchedDetail',
      pagesWithoutParent: 'Pages/getPagesWithOutParent',
      isSaveButtonDisable: 'Pages/isSaveButtonDisabled',
    })
  },
  created() {
    this.fetchPagesWithoutParent();
  },
  methods: {
    ...mapActions({
      store: 'Pages/store',
      fetchDetail: 'Pages/fetchDetail',
      fetchPagesWithoutParent: 'Pages/fetchPagesWithoutParent',
      attachPages: 'Pages/attachPages',
      removeChild: 'Pages/removeChildren',
    }),
    editItem(item) {
      return this.$router.resolve({ name: 'pages-edit', params: { id: item.id } }).href
    },
    deleteItem(item) {
      this.removeChild(item.id).then(() => {
        this.fetchDetail(this.$route.params.id);
        this.fetchPagesWithoutParent();
      });
    },
    attach() {
      this.attachPages(this.selectedPages).then(() => {
        this.fetchPagesWithoutParent();
        this.fetchDetail(this.$route.params.id);
        this.selectedPages = [];
      });
    },
    createChild() {
      return this.$router.resolve({ name: 'pages-create', query: { parent_id: this.pageDetail.id } }).href
    }
  },
}
</script>
