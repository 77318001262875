<template>
  <v-container fluid class="down-top-padding">
    <BaseBreadcrumb :title="page.title" :icon="page.icon" :breadcrumbs="breadcrums"/>
    <v-row >
      <v-col
        cols="4"
        sm="6"
        md="4"
      >
                  <template v-if="patternStatus.course_listing && patternStatus.public_course && patternStatus.private_course && patternStatus.lessons && !isLoading">
                    <v-card>
                      <v-card-title>
                        <h3 class="title blue-grey--text text--darken-2 font-weight-regular">  Прототипы</h3>
                      </v-card-title>
                      <v-card-subtitle>
                        <h6 class="subtitle-2 font-weight-light">   Первообраз, оригинал, первоначальный образец</h6>
                      </v-card-subtitle>
                      <v-list>
                        <v-list-item>
                          <v-list-item-action>
                            <v-icon>mdi-puzzle</v-icon>
                          </v-list-item-action>
                          <v-list-item-content>
                            <v-list-item-title>Списочная курсов</v-list-item-title>
                          </v-list-item-content>
                          <v-list-item-action>
                            <v-row>
                              <v-col>
                            <a target="_blank" :href="patternStatus.course_listing_href">
                              <v-icon small color="primary">
                                mdi-eye
                              </v-icon>
                            </a>
                              </v-col>
                              <v-col>
                            <a target="_blank" :href="`${patternStatus.course_listing_href}?edit_mode=true`">
                              <v-icon small color="primary">
                                mdi-pen
                              </v-icon>
                            </a>
                              </v-col>
                            </v-row>
                          </v-list-item-action>
                        </v-list-item>
                        <v-divider></v-divider>
                        <v-list-item>
                          <v-list-item-action>
                            <v-icon>mdi-puzzle</v-icon>
                          </v-list-item-action>
                          <v-list-item-content>
                            <v-list-item-title>Лендинг курса</v-list-item-title>
                          </v-list-item-content>
                          <v-list-item-action>
                            <v-row>
                              <v-col>
                                <a target="_blank" :href="patternStatus.public_course_href">
                                  <v-icon small color="primary">
                                    mdi-eye
                                  </v-icon>
                                </a>
                              </v-col>
                              <v-col>
                                <a target="_blank" :href="`${patternStatus.public_course_href}?edit_mode=true`">
                                  <v-icon small color="primary">
                                    mdi-pen
                                  </v-icon>
                                </a>
                              </v-col>
                              <v-col>
                                <v-btn icon small @click="patternDialog = true">
                                  <v-icon color="green">
                                    mdi-plus
                                  </v-icon>
                                </v-btn>
                              </v-col>
                            </v-row>
                          </v-list-item-action>
                        </v-list-item>
                      </v-list>
                    </v-card>
                  </template>
                  <template v-else>
                    <v-btn block color="primary" @click="patternsCreate">
                      Создать шаблоны
                    </v-btn>
                  </template>
      </v-col>
      <v-col
        cols="8"
        sm="6"
        md="8"
      >
        <div class="mt-3">
          <v-row>
            <v-toolbar flat class="mb-8">
              <v-row>
                <v-col cols="12" lg="6">
                  <v-text-field
                    v-model="search"
                    append-icon="mdi-magnify"
                    label="Поиск..."
                    filled
                    background-color="transparent"
                    hide-details
                    name="title"
                  >
                    <v-btn v-if="search"
                           class="reset-button"
                           slot="append"
                           icon
                           @click="search = null"
                           color="red"
                    >
                      <v-icon>mdi-close</v-icon>
                    </v-btn>
                  </v-text-field>
                </v-col>
                <v-col>
                  <v-switch v-model="only_trashed"
                            label="Показывать удаленные"
                            name="only_trashed"
                            value="1"
                            style="margin-top: 16px;"/>
                </v-col>
              </v-row>
              <v-spacer></v-spacer>
              <v-btn
                color="primary"
                to="/chimera-courses/create"
                dark
                class="mb-2 text-capitalize"
              >
                <v-icon class="mr-2">mdi-book-plus-outline</v-icon>
                Создать курс без прототипа
              </v-btn>
            </v-toolbar>
          </v-row>
          <v-row>
            <v-col cols="4" class="mt-2" v-for="course in fetchedData" :key="course.id">
              <v-card elevation="2">
                <v-img :src="course.image" height="250"/>
                <v-card-title>{{ course.title }}</v-card-title>
                <v-card-actions>
                  <v-btn icon color="primary" :to="`/chimera-courses/edit/${course.id}`"
                         v-if="!course.deleted_at">
                    <v-icon>mdi-pen</v-icon>
                  </v-btn>
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        icon
                        :href="course.client_url"
                        target="_blank"
                        color="primary"
                        v-bind="attrs"
                        v-on="on">
                        <v-icon>mdi-eye</v-icon>
                      </v-btn>
                    </template>
                    <span>Предпросмотр курса</span>
                  </v-tooltip>
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        icon
                        :href="course.payment_client_url"
                        target="_blank"
                        color="primary"
                        v-bind="attrs"
                        v-on="on">
                        <v-icon>mdi-eye-plus</v-icon>
                      </v-btn>
                    </template>
                    <span>Предпросмотр курса</span>
                  </v-tooltip>
                  <v-btn icon v-if="!course.deleted_at" @click="deleteItem(course.id, false)">
                    <v-icon color="red">
                      mdi-delete
                    </v-icon>
                  </v-btn>
                  <v-btn v-else icon @click="restoreItem(course.id)">
                    <v-icon color="green">
                      mdi-backup-restore
                    </v-icon>
                  </v-btn>
                  <v-btn icon v-if="course.deleted_at" @click="deleteItem(course.id, true)">
                    <v-icon color="red">
                      mdi-delete
                    </v-icon>
                  </v-btn>
                  <v-spacer/>
                  <v-btn icon color="primary" :to="`/chimera-courses/${course.id}/lessons`">
                    <v-icon>mdi-book</v-icon>
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-col>
          </v-row>
        </div>
      </v-col>
    </v-row>
    <PatternPageCreator v-model="patternDialog" @onSubmit="fetchListing(payload)"/>
  </v-container>
</template>

<script>
import BaseBreadcrumb from "../../../components/commonComponents/BaseBreadcrumb";
import { mapGetters, mapActions } from "vuex";
import PatternPageCreator from "../../../components/chimeraCourses/PatternPageCreator";

export default {
  name: "Index",
  components: {PatternPageCreator, BaseBreadcrumb},
  data: () => ({
    page: {
      title: 'Образовательные курсы',
      icon: 'mdi-book-education-outline'
    },
    breadcrums: [
      {
        text: "Главная",
        disabled: false,
        href: '/',
      },
      {
        text: "Образовательные курсы",
        disabled: true,
        href: '/chimera-courses/',
      }
    ],
    options: {
      sortBy: ['id'],
      sortDesc: [true],
      page: 1,
    },
    search: '',
    only_trashed: false,
    patternDialog: false,
    headers: [
      { text: 'ID', value: 'id' },
      { text: 'Название', value: 'title' },
      { text: 'Slug', value: 'slug' },
      { text: 'Статус', value: 'status' },
      { text: 'Дата создания', value: 'created_at' },
      { text: 'Создатель', value: 'creator' },

      { text: '', value: 'actions' },
    ]
  }),
  created() {
    this.fetchListing(this.payload);
    this.fetchPatternStatus()
  },
  computed: {
    ...mapGetters({
      fetchedData: "ChimeraCourses/getFetchedListing",
      totalItems: "ChimeraCourses/getListingTotalItems",
      isLoading: "ChimeraCourses/getIsLoading",
      patternStatus: "ChimeraCourses/getPatternStatuses",
    }),
    payload() {
      return {
        options: this.options,
        search: this.search,
        only_trashed: this.only_trashed,
      }
    }
  },
  methods: {
    ...mapActions({
      fetchListing: "ChimeraCourses/fetchListing",
      delete: "ChimeraCourses/deleteItem",
      restore: "ChimeraCourses/restoreItem",
      fetchPatternStatus: "ChimeraCourses/fetchPatternStatuses",
      patternsCreate: "ChimeraCourses/patternCreate",
      regenerateTemplate: "ChimeraCourses/regenerateTemplate",
    }),
    deleteItem(id, isForce) {
      this.delete({
        id: id,
        isForce: isForce,
      }).then(() => {
        this.fetchListing(this.payload);
      });
    },
    restoreItem(id) {
      this.restore(id).then(() => {
        this.fetchListing(this.payload);
      })
    },
    regeneratePaymentCourse(id) {
      this.regenerateTemplate({
        lesson: id,
        template: 'course-private-pattern',
      })
    }
  },
  watch: {
    options: {
      handler() {
        this.fetchListing(this.payload);
      },
      deep: true,
    },
    search: {
      handler() {
        this.fetchListing(this.payload);
      }
    },
    only_trashed: {
      handler() {
        this.fetchListing(this.payload);
      }
    }
  }
}
</script>

<style scoped>

</style>
