<template>
  <v-container>
    <v-row>
      <v-col>
        <h1>Элементы фасетной таксономии</h1>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-btn color="primary" to="/collection-items/create">
          Создать элемент
        </v-btn>
      </v-col>
      <v-col>
        <v-checkbox
          v-model="only_trashed"
          label="Показать удаленные"
          name="only_trashed"
          value="1"
        ></v-checkbox>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-data-table
          :headers="headers"
          :items="items"
          :search="search"
        >
          <template v-slot:item.actions="{ item }">
            <v-btn
              :href="editItem(item)"

              icon
            >
              <v-icon color="primary">
                mdi-pencil
              </v-icon>
            </v-btn>
            <v-icon
              v-if="item.deleted_at"
              color="green"
              @click="restoreItem(item)"
            >
              mdi-backup-restore
            </v-icon>
            <v-icon
              v-else
              color="red"
              @click="deleteItem(item)"
            >
              mdi-delete
            </v-icon>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {CollectionItem} from '../../../../models/collectionItem';

export default {
  name: 'Index',
  data: () => ({
    search: '',
    only_trashed: null,
    headers: [
      {text: 'Id', value: 'id'},
      {text: 'Заголовок', value: 'title'},
      {text: 'Дата создания', value: 'created_at'},
      {text: 'Дата обновления', value: 'updated_at'},
      {text: '', value: 'actions'},
    ],
    items: [],
  }),
  created() {
    this.get();
  },
  methods: {
    editItem(item) {
      return this.$router.resolve({name: 'collection-items-edit', params: {id: item.id}}).href
    },
    async deleteItem(item) {
      this.$confirm('Вы уверены?', 'Удаление').then(() => {
        CollectionItem.$query().delete(item.id, 0);
        this.get();
      });
    },
    async restoreItem(item) {
      await CollectionItem.$query().restore(item.id);
      await this.get();
    },
    async get() {
      let items = [];

      if (this.only_trashed) {
        items = await CollectionItem.$query().onlyTrashed().get(1000);
      } else {
        items = await CollectionItem.$query().get(1000);
      }

      this.items = items.map(collectionItem => collectionItem.$attributes);
    },
  },
  watch: {
    only_trashed: {
      handler() {
        this.get();
      }
    },
  },
};
</script>
