<template>
  <div class="ya_preview">
    <div class="wrap">
      <div class="favicon">
        <img :src="siteIcon" alt="">
      </div>
      <div class="desktop-card">
        <div>
          <h2 class="title">
            <a class="main-link" href="yandex.ru" @click.prevent>
              {{ title }}
            </a>
          </h2>
          <div class="subtitle">
            <a class="subtitle-link" href="yandex.ru" @click.prevent>
              <b>{{ getHostname }}</b>
              <span class="Path-Separator">›</span>{{getPreviewUrl}}
            </a>
          </div>
          <div class="description">
            {{ description }}
            <span v-if="displayShowMoreButton" class="show-more">Читать ещё</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import seoMixin from "@/mixins/seoMixin";

export default {
  mixins: [
    seoMixin
  ],
  props: {
    form: {
      type: Object,
    },
    entity: {
      type: Object,
    },
  },
  data() {
    return {
      title: '',
      description: '',
      descriptionLength: 215,
    }
  },
  computed: {
    ...mapGetters({
      site: 'SiteSwitcher/getSelectedSite',
    }),
    displayShowMoreButton() {
      return this.form.meta_description?.length > this.descriptionLength;
    },
    getPreviewUrl() {
      if (!this.entity.preview_url) return '';

      let url = new URL(this.entity.preview_url);

      return this.cutString(url.pathname, 50 - this.getHostname.length );
    },
    getHostname() {
      if (!this.entity.preview_url) return '';

      let url = this.entity.preview_url.replace(/http(.*?)\/\//, '');
      let hostname = url.match(/(.*?)\//)[1];

      return hostname;
    },
  },
  methods: {
    cutString(string, count) {
      if (string.length > count) {
        return string.substring(0, count) + '...';
      }

      return string;
    }
  },
  watch: {
    'form.meta_description': {
      handler(val) {
        let value = val || this.entity.lead || this.entity.description;

        if (typeof value != 'string') return;

        this.description = this.cutString(value, this.descriptionLength) || this.entity.lead;
      },
      immediate: true,
    },
  }
}
</script>

<style lang="scss" scoped>
  .ya_preview {
    margin-bottom: 15px;
  }

  .wrap {
    background: #fbfbfb;
    padding: 20px;
    font-family: 'YS Text', sans-serif;

    .favicon {
      float: left;
      margin-top: 19px;
      width: 16px;
      height: 16px;

      img {
        max-width: 100%;
      }
    }

    .desktop-card {
      margin-left: 30px;
      width: 620px;
      padding: 16px 14px;
      border-radius: 12px;
      background: white;
      box-shadow: 0px 4px 12px 0px #0d234308;

      .title {
        line-height: 22px;
        letter-spacing: -0.1px;
      }

      .main-link,
      .subtitle-link {
        text-decoration: none;
        font-weight: 400;
      }

      .main-link {
        color: navy;
        font-size: 18px;
        line-height: 22px;
        font-weight: 400;

        &:hover {
          color: #d00;
        }
      }

      .subtitle-link {
        color: #006000;
        font-size: 14px;

        &:hover {
          color: #d00;
        }
      }

      .Path-Separator {
        margin: 0 4.2px;
        font-family: Verdana;
      }

      .description {
        line-height: 17px;
        font-size: 13px;

        .show-more {
          color: navy;
          position: relative;
          padding-right: 7.8px;
          cursor: pointer;

          &:hover {
            color: #d00;
          }

          &::before {
            transform: rotate(-45deg) scale(.6) translate(-12px,12px);
          }

          &::after{
            transform: rotate(45deg) scale(.6) translate(8px,8px);
          }

          &::before, &::after{
              position: absolute;
              right: -4px;
              box-sizing: border-box;
              width: 10px;
              height: 2px;
              content: '';
              border-radius: 0 2px 2px 0;
              background: currentColor;
          }
        }
      }
    }
  }

</style>
