<template>
  <div>
    <p>
      <b>Пользователь:</b>
      {{ form.user ? form.user.full_name : '' }}
    </p>
    <p>
      <b>Имя:</b>
      {{ form.name }}
    </p>
    <p>
      <b>Телефон:</b>
      {{ form.phone }}
    </p>
    <p>
      <b>Email:</b>
      {{ form.email }}
    </p>
    <p>
      <b>Страница, с которой пришли данные:</b>
      <a :href="form.sign_url">{{ form.sign_url }}</a>
    </p>
    <p>
      <b>Создан:</b>
      {{ form.created_at }}
    </p>
    <p>
      <b>Обновлен:</b>
      {{ form.updated_at }}
    </p>
    <p v-if="form.comment">
      <template v-for="(text, key) in form.comment">
        <b :key="key">{{ key[0].toUpperCase() + key.slice(1) }}:</b>
        {{ text }}
      </template>
    </p>
    <p v-if="form.changer">
      <b>Изменил статус:</b>
      <a :href="editUser(form.changer)">{{ form.changer.full_name }}</a>
    </p>
    <v-form>
      <v-select
        v-model="form.status"
        :items="statuses"
        label="Статус"
      ></v-select>
      <v-textarea
        v-model="form.response"
        :error-messages="errors.response"
        label="Комментарий"
        name="response"
        required
      ></v-textarea>
      <v-flex>
        <v-btn
          color="primary"
          :disabled="isSaveDisabled"
          :loading="isSaveDisabled"
          @click="submit"
        >
          Сохранить
        </v-btn>
      </v-flex>
    </v-form>
  </div>
</template>

<script>
export default {
  name: "LeadForm",
  props: {
    lead: {
      type: Object,
      default: () => ({})
    },
    errors: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    form: {
      id: null,
      name: null,
      phone: null,
      email: null,
      comment: null,
      sign_url: null,
      status: null,
      response: null,
    },
    statuses: [
      {text: 'Новый', value: 0},
      {text: 'Прочитано', value: 1},
      {text: 'Отвечено', value: 2},
    ],
    isSaveDisabled: false,
  }),
  created() {
    if (this.$route.params) {
      this.form = this.$route.params;
    }
  },
  methods: {
    submit() {
      this.isSaveDisabled = true;

      this.$emit('onSubmit', {
        form: this.form,
      });
    },
    editUser(item) {
      return this.$router.resolve({name: 'users-edit', params: {id: item.id}}).href
    }
  },
  watch: {
    lead: {
      handler(lead) {
        if (lead && Object.keys(lead).length > 0) {
          this.form = lead;
          this.isSaveDisabled = false;
        }
      },
      immediate: true,
      deep: true,
    },
  },
}
</script>
