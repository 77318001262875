export default {
  methods: {
    setTitle(val) {
      let value = val || this.entity.title;

      if (typeof value != 'string') return;

      let title = value;

      if (this.form.titlePostFix != undefined)
        title += ` — ${this.form.titlePostFix}`;

      this.title = this.cutString(title, 52) || this.entity.title;
    },
  },
  watch: {
    'form.meta_title': {
      handler(val) {
        this.setTitle(val);
      },
      immediate: true,
    },
    'form.titlePostFix': {
      handler() {
        this.setTitle(this.form.meta_title);
      },
      immediate: true,
    }
  }
}
