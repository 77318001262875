import {FAQ} from "../../../models/faq";
import axiosInstance from "../../../axiosInstance";

export default {
  namespaced: true,
  state: {
    fetchedListing: [],
    fetchedAllItems: [],
    listingTotalItems: 0,
    fetchedDetail: {},
    isSaveButtonDisabled: false,
    isListingLoad: false,
    isModalVisible: false,
  },
  getters: {
    getFetchedListing: state => state.fetchedListing,
    getFetchedDetail: state => state.fetchedDetail,
    getListingTotalItems: state => state.listingTotalItems,
    getFetchedAllItems: state => state.fetchedAllItems,
    isSaveButtonDisabled: state => state.isSaveButtonDisabled,
    isModalVisible: state => state.isModalVisible,
    isListingLoad: state => state.isListingLoad,
  },
  mutations: {
    setFetchedListing(state, listing) {
      state.fetchedListing = listing;
    },
    setFetchedDetail(state, detail) {
      state.fetchedDetail = detail;
    },
    setListingTotalItems(state, totalItems) {
      state.listingTotalItems = totalItems;
    },
    setIsSaveButtonDisabled(state, value) {
      state.isSaveButtonDisabled = value;
    },
    setIsListingLoad(state, value) {
      state.isListingLoad = value;
    },
    setFetchedAllItems(state, value) {
      state.fetchedAllItems = value;
    },
    setIsModalVisible(state, value) {
      state.isModalVisible = value;
    }
  },
  actions: {
    fetchListing({ commit }, { options, onlyTrashed, search }) {
      commit('setIsListingLoad', true)

      const {page, itemsPerPage, sortBy, sortDesc} = options;
      const onlyTrashedQuery = onlyTrashed ? `&only_trashed=1` : '';
      let data = {};

      sortBy && sortBy[0] ? data.sort = [{field: sortBy[0], direction: sortDesc[0] ? 'desc' : 'asc'}] : null;
      search ? data.search = {value: search} : null;

      axiosInstance
        .post(`/module/faq/admin/faq/search?limit=${ itemsPerPage }&page=${ page }${ onlyTrashedQuery }`, data)
        .then((response) => {
          commit('setFetchedListing', response.data.data);
          commit('setListingTotalItems', response.data.meta.total);
        })

      commit('setIsListingLoad', false)
    },
    destroyItem({ dispatch }, id) {
       FAQ.$query().destroy(id).then(() => {
        dispatch('snackbar/showSuccessfullyDelete', null, { root: true });
      }).catch(() => {
        dispatch('snackbar/showDeleteError', null, { root: true });
      });
    },
    fetchDetail({ commit }, id) {
      FAQ.$query().find(id).then((response) => {
        commit('setFetchedDetail', response.$attributes);
      })
    },
    updateItem({ commit, dispatch }, payload) {
      const {id, form, isNeedRedirect, router} = payload;
      commit('setIsSaveButtonDisabled', true);

      FAQ.$query().update(id, form).then(() => {
        dispatch('snackbar/showSuccessfullySaved', null, { root: true });
        commit('setIsSaveButtonDisabled', false);
        if (isNeedRedirect) {
          router.push({ name: 'faq-index' });
        }

      }).catch(() => {
        dispatch('snackbar/showSaveError', null, { root: true });
      }).finally(() => {
        commit('setIsSaveButtonDisabled', false);
      });
    },
    storeItem({ commit, dispatch }, {form, isNeedRedirect, router}) {
      commit('setIsSaveButtonDisabled', true);

      return FAQ.$query().store(form).then((response) => {
        dispatch('snackbar/showSuccessfullySaved', null, { root: true });
        commit('setIsSaveButtonDisabled', false);
        if (isNeedRedirect) {
          router.push({ name: 'faq-index' });
        } else {
          router.push({ name: 'faq-edit', params: { id: response.$attributes.id } });
        }

      }).catch(() => {
        dispatch('snackbar/showSaveError', null, { root: true });
      }).finally(() => {
        commit('setIsSaveButtonDisabled', false);
      });
    },
    fetchAllItems({ commit }) {
      FAQ.$query().get(10000).then((response) => {
        const items = response.map(el => el.$attributes);
        items.sort(function (a, b) {
          return (a.position === null) - (b.position === null) || +(a.position > b.position) || -(a.position < b.position);
        });
        commit('setFetchedAllItems', items);
      })
    },
    async storeSort({state, dispatch, commit}) {
      commit('setIsSaveButtonDisabled', true);

      let formattedData = {};
      const items = state.fetchedAllItems;

      items.forEach(function (elem, index) {
        formattedData[elem.id] = {position: index};
      });

      await axiosInstance.patch('module/faq/admin/faq/batch', { resources: formattedData }).then(() => {
        dispatch('snackbar/showSuccessfullySaved', null, { root: true });
      }).catch(() => {
        dispatch('snackbar/showSaveError', null, { root: true });
      }).finally(() => {
        commit('setIsSaveButtonDisabled', false);
        commit('setIsModalVisible', false);
      });
    }
  }
}
