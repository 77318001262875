<template>
  <v-form>
    <v-text-field
      v-model="form.title"
      :error-messages="errors.title"
      label="Название"
      name="title"
    ></v-text-field>
    <v-textarea
      v-model="form.description"
      :error-messages="errors.description"
      label="Описание"
      name="description"
    ></v-textarea>
    <v-text-field
      v-model="form.url"
      :error-messages="errors.url"
      label="Ссылка"
      name="url"
      required
    ></v-text-field>
    <v-select
      v-if="statuses"
      v-model="form.status"
      :error-messages="errors.status"
      :items="statuses"
      label="Статус"
      name="status"
      required
    ></v-select>
    <media-widget
      class="mb-1"
      label="Изображение"
      :medias="form.image"
      :is-multiple="false"
      @onChange="setImage"
    />
    <v-flex>
      <v-btn
        class="mr-4"
        color="primary"
        :disabled="isSaveDisabled"
        :loading="isSaveDisabled"
        @click="submit(true)"
      >
        Сохранить
      </v-btn>
      <v-btn
        class="mr-4"
        color="primary"
        :disabled="isSaveDisabled"
        :loading="isSaveDisabled"
        @click="submit(false)"
      >
        Сохранить и продолжить
      </v-btn>
    </v-flex>
    <media-uploader />
  </v-form>
</template>

<script>
import MediaUploader from "../MediaUploader";
import MediaWidget from "../MediaWidget";

export default {
  name: "TeaserForm",
  components: {
    MediaWidget,
    MediaUploader,
  },
  props: {
    teaser: {
      type: Object,
      default: () => ({})
    },
    errors: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    form: {
      id: null,
      title: null,
      description: null,
      url: null,
      image: null,
      status: null,
    },
    isSaveDisabled: false,
    statuses: [
      {text: 'Черновик', value: 0},
      {text: 'Заполанировано', value: 1},
      {text: 'Опубликовано', value: 2},
    ],
  }),
  created() {
    if (this.$route.params) {
      this.form = this.$route.params;
    }
  },
  methods: {
    submit(isNeedRedirect) {
      this.isSaveDisabled = true;

      this.$emit('onSubmit', {
        form: this.form,
        isNeedRedirect: isNeedRedirect,
      });
    },
    setImage(image) {
      this.form.image = image;
    },
  },
  watch: {
    teaser: {
      handler(teaser) {
        if (teaser && Object.keys(teaser).length > 0) {
          this.form = teaser;
          this.isSaveDisabled = false;
        }
      },
      immediate: true,
      deep: true,
    },
  },
}
</script>
