<template>
  <v-container fluid class="down-top-padding">
    <BaseBreadcrumb :title="page.title" :icon="page.icon" :breadcrumbs="breadcrumbs"></BaseBreadcrumb>
    <v-card>
    <v-card-text class="pa-5">
    <v-row>
      <v-col>
        <v-data-table
          :headers="headers"
          :items="items"
          :options.sync="options"
          :server-items-length="total"
          :footer-props="{'items-per-page-options':[5, 10, 15, 20, -1]}"
          class="contact-listing-app"
        >
          <template v-slot:top>
            <v-toolbar flat class="mb-8">
              <v-row>
                <v-col cols="12" lg="6">
                  <v-text-field
                    v-model="search"
                    append-icon="mdi-magnify"
                    label="Поиск..."
                    filled
                    background-color="transparent"
                    hide-details
                    name="title"
                  >
                    <v-btn v-if="search" class="reset-button"
                      slot="append"
                      icon
                      @click="search = null"
                      color="red"
                    >
                      <v-icon>mdi-close</v-icon>
                    </v-btn>
                  </v-text-field>
                </v-col>
                <v-col>
                  <v-switch v-model="only_trashed" label="Показать удаленные" name="only_trashed" value="1" style="margin-top: 16px;"></v-switch>
                </v-col>
              </v-row>

              <v-spacer></v-spacer>
              <v-dialog max-width="700">
                <template v-slot:activator="{ on }">
                  <v-btn
                    color="primary"
                    to="/pages/create"
                    dark
                    class="mb-2 text-capitalize"
                  >
                    <v-icon class="mr-2">mdi-book-open-page-variant-outline</v-icon>
                    Создать страницу
                  </v-btn>
                </template>
              </v-dialog>
            </v-toolbar>
          </template>

          <template v-slot:item.id="{ item }">
            <v-chip pill>{{ item.id }}</v-chip>
          </template>
          <template v-slot:item.actions="{ item }">
            <a :href="item.preview_url" target="_blank">
              <v-icon small class="mr-2 info--text">mdi-eye</v-icon>
            </a>
            <a
              v-if="(item.is_permission_view && can('page.permission.view')) || !item.is_permission_view"
              :href="editItem(item)"
            >
              <v-icon small class="mr-2 info--text">
                mdi-pencil
              </v-icon>
            </a>
            <v-icon
              small class="mr-2 info--text"
              @click="copyItem(item)"
            >
              mdi-content-copy
            </v-icon>
            <v-icon
              v-if="item.deleted_at"
              small
              color="green"
              @click="restoreItem(item)"
            >
              mdi-backup-restore
            </v-icon>
            <v-icon
              v-else-if="(item.is_permission_view && can('page.permission.view')) || !item.is_permission_view"
              small
              color="red"
              @click="deleteItem(item)"
            >
              mdi-delete
            </v-icon>
          </template>
          <template v-slot:item.translations="{ item }">
            <v-btn icon color="success" @click="openTranslationDialog(item)">
              <v-icon>
                mdi-plus
              </v-icon>
            </v-btn>
            <v-speed-dial v-for="value in item.existing_translations" :key="value.id">
                <template v-slot:activator v-if="item.language_id !== value.language_id">
                  <country-flag
                    :country="value.flag_icon"
                    :key="value.id"
                    class="mt-1"
                  />
                </template>
                <v-btn fab small color="primary" :to="`pages/edit/${value.id}`">
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
                <v-btn fab small color="primary" :href="value.preview_url">
                  <v-icon>mdi-eye</v-icon>
                </v-btn>
            </v-speed-dial>
          </template>
        </v-data-table>
        </v-col>
      </v-row>
      </v-card-text>
    </v-card>
    <TranslationCreatorComponent
      v-model="translationModal"
      :original_page="selectedPageTranslate"
      :existing-languages="selectedPageLanguages"
      @onSubmit="makeTranslationPage"
    />
  </v-container>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import TranslationCreatorComponent from "../../../components/TranslationCreatorComponent";

export default {
  name: 'Index',
  components: {TranslationCreatorComponent},
  data: () => ({
    search: '',
    only_trashed: null,
    options: {
      sortBy: ['id'],
      sortDesc: [true],
      page: 1,
      itemsPerPage: 20,
    },
    headers: [
      {text: 'Id', value: 'id'},
      {text: 'Название', value: 'title'},
      {text: 'Slug', value: 'slug'},
      {text: 'Дата создания', value: 'created_at'},
      {text: 'Дата обновления', value: 'updated_at'},
      {text: 'Переводы', value: 'translations' },
      {text: '', value: 'actions'},
    ],
    page: {
      title: "Страницы"
    },
    breadcrumbs: [
      {
        text: "Админ панель",
        disabled: false,
        to: '/'
      },
      {
        text: "Страницы",
        disabled: true,
        to: '/pages'
      },
    ],
    translationModal: false,
    selectedPageLanguages: {},
    selectedPageTranslate: null,
  }),
  created() {
    this.get();
  },
  computed: {
    ...mapGetters({
      items: 'Pages/getFetchedListing',
      total: 'Pages/getListingTotalItems',
    }),
  },
  methods: {
    ...mapActions({
      fetchedListing: 'Pages/fetchListing',
      destroyItem: 'Pages/destroy',
      restore: 'Pages/restoreItem',
      copy: 'Pages/makeCopy',
      storePage: 'Pages/store',
    }),
    editItem(item) {
      return this.$router.resolve({name: 'pages-edit', params: {id: item.id}}).href
    },
    async deleteItem(item) {
      this.$confirm('Вы уверены?', 'Удаление').then(() => {
        this.destroyItem(item.id).then(() => {
          this.get();
        })
      });
    },
    restoreItem(item) {
      this.restore(item.id).then(() => {
        this.get();
      })
    },
    async copyItem(item) {
      this.copy(item.id).then(async () => {
        await this.get();
      })
    },
    async get() {
      const payload = {
        options: this.options,
        search: this.search,
        only_trashed: this.only_trashed,
      };

      this.fetchedListing(payload);
    },
    openTranslationDialog(page) {
      this.selectedPageLanguages = page.existing_translations.map(({language_id}) => (language_id));
      this.selectedPageTranslate = page.id;
      this.translationModal = true;
    },
    makeTranslationPage(form) {
      this.storePage({form: form}).then(() => {
        this.translationModal = false;
        this.get();
      });
    }
  },
  watch: {
    only_trashed: {
      handler() {
        this.get();
      }
    },
    search: {
      handler(newSearch, oldSearch) {
        newSearch != oldSearch ? this.options.page = 1 : '';
        this.get();
      }
    },
    options: {
      handler() {
        this.get()
      },
      deep: true,
    },
  },
};
</script>

<style scoped>
  .reset-button {
    width: unset!important;
    height: unset!important;
    margin-top: 5px;
  }
</style>
