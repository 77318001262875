<template>
  <div>
    <v-form v-if="!isLoading">
    <v-btn
      v-if="page.parent"
      class="mb-2"
      color="primary"
      :disabled="isSaveDisabled"
      :loading="isSaveDisabled"
      :href="editParent()"
      block
    >
      Редактирование родительской страницы
    </v-btn>
    <v-text-field
      v-model="form.title"
      label="Название"
      name="title"
      required
    ></v-text-field>
    <v-text-field
      v-model="form.slug"
      label="Slug"
      name="slug"
      required
      :disabled="!(Object.keys(page).length === 0)"
    ></v-text-field>
    <v-select
      label="Выберите вариацию страницы (изменения применяться только после сохранения)"
      v-model="form.variant"
      :items="variants"
      item-value="value"
      item-text="title"
      />
    <a
      v-if="page.preview_url"
      :href="page.preview_url"
      target="_blank"
    >
      {{ page.preview_url }}
    </a><br>
    <template v-if="!isPryamaya">
      <label>Описание</label>
      <tip-tap v-model="form.description"/>
      <v-text-field
        v-model="form.subtitle"
        label="Подзаголовок"
        name="subtitle"
        required
      ></v-text-field>
      <v-text-field
        v-model="form.menu_title"
        label="Заголовок в меню"
        name="menu_title"
      />
      <v-text-field
        v-model="form.menu_icon"
        label="Иконка в меню"
        />
    </template>
    <v-textarea
      v-model="form.lead"
      label="Расширение"
      name="lead"
      required
    ></v-textarea>
    <div v-show="page.variant === 1">
      <label>Контент</label>
      <media-form
        :key="reRenderMedia"
        ref="media-form"
        :structure="currentMedia.structure"
        :is-show-save="false"
        style="margin: 0 auto"
      />
    </div>

    <template v-if="!isPryamaya">
      <media-widget
        class="mb-1"
        label="Фоновое изображение"
        :medias="form.background_image"
        :is-multiple="false"
        @onChange="setBackgroundImage"
      />
      <media-widget
        class="mb-1"
        label="Знак раздела"
        :medias="form.section_mark_image"
        :is-multiple="false"
        @onChange="setSectionMarkImage"
      />
      <v-checkbox
        v-model="form.is_permission_view"
        label="Страница доступная для редактирования по роли"
      />
      <v-checkbox
        v-model="form.header_footer_disabled"
        label="Отключить шапку и подвал"
        />
    </template>
    <v-btn
      v-if="isEdit && isModuleBlockEnabled && page.variant !== 1"
      :href="`${page.preview_url}?edit_mode=true&admin_url=${this.$route.fullPath}`"
      color="primary"
      class="mr-2 mt-2"
      @click="toBlocks"
    >
      Редактирование блоков
    </v-btn>
    <v-btn
      v-if="page.parent"
      class="mr-2 mt-2"
      color="primary"
      :disabled="isSaveDisabled"
      :loading="isSaveDisabled"
      :href="editParent()"
    >
      Редактирование родительской страницы
    </v-btn>
    <v-btn
      color="primary"
      class="mt-2 mr-2"
      :disabled="isSaveDisabled"
      :loading="isSaveDisabled"
      @click="submit(true)"
    >
      Сохранить
    </v-btn>
    <v-btn
      color="primary"
      class="mt-2 mr-2"
      :disabled="isSaveDisabled"
      @click="submit(false)"
      >
      Сохранить и продолжить
    </v-btn>
    <media-uploader
      :loaded-medias="page.loaded_medias"
      @completeUploads="onCompleteUploads"
    />
  </v-form>
    <div class="text-center" v-else>
      <v-progress-circular indeterminate color="primary"/>
    </div>
  </div>
</template>

<script>
import {BlockEntity} from '../../models/blockEntity';
import slugify from 'slugify';
import MediaUploader from "../MediaUploader";
import MediaWidget from '../MediaWidget';
import TipTap from "../editor/TipTap";
import {Site} from "../../models/site";
import {mapGetters} from "vuex";
import optionsMixin from "../../mixins/optionsMixin";
import MediaForm from "../medias/MediaForm";

export default {
  name: 'PageForm',
  mixins: [optionsMixin],
  components: {
    MediaForm,
    MediaUploader,
    MediaWidget,
    TipTap,
  },
  props: {
    page: {
      type: Object,
      default: () => ({})
    },
    isEdit: {
      type: Boolean,
      default: false,
    },
    media: {
      type: Object,
      default: () => ({}),
    },
    isLoading: {
      type: Boolean,
      default: false,
    }
  },
  created() {
    this.getForm();
    this.getSites();
  },
  data: () => ({
    form: {
      title: null,
      slug: null,
      description: null,
      background_image: null,
      section_mark_image: null,
      subtitle: null,
      lead: null,
      blocks: [],
      blockValues: [],
      site_id: null,
      menu_title: null,
      menu_icon: null,
      is_dynamic_loading: false,
      is_permission_view: false,
      header_footer_disabled: false,
      variant: 0,
    },
    variants: [
      { title: 'Без типа', value: 0 },
      { title: 'Визивиг', value: 1 },
      { title: 'Блоки Bootstrap', value: 2 },
      { title: 'Блоки Tailwind', value: 3 },
    ],
    sites: [],
    layouts: [
      'type1',
      'type2'
    ],
    mediaIds: [],
    currentMedia: {
      id: null,
      entity: 'App\\Models\\Page',
      entity_id: null,
      structure: null,
    },
    reRenderMedia: 1,
    clientUrl: process.env.VUE_APP_CLIENT_URL,
  }),
  computed: {
    ...mapGetters({
      isSaveDisabled: 'Regions/isSaveButtonDisabled'
    }),
    isModuleBlockEnabled() {
      return process.env.VUE_APP_M_BLOCKS === 'true';
    },
    isPryamaya() {
      return process.env.VUE_APP_IS_PRYAMAYA === 'true';
    }
  },
  methods: {
    created() {
      this.getSites();
    },
    async onCompleteUploads(ids) {
      this.mediaIds = ids;
      await this.submit();
      this.mediaIds = [];
    },
    async submit(isNeedRedirect) {
      const data = await this.$refs['media-form'].submit();

      if (data) {
        this.currentMedia.structure = data;
      }

      this.$emit('onSubmit', {form: this.form, mediaIds: this.mediaIds, media: this.currentMedia, isNeedRedirect: isNeedRedirect});
    },
    setBackgroundImage(image) {
      this.form.background_image = image;
    },
    setSectionMarkImage(image) {
      this.form.section_mark_image = image;
    },
    toBlocks() {
      this.$store.dispatch('block/updatePageForm', this.form);
    },
    async getForm() {
      if (this.$route.params.from_block) {
        this.form = JSON.parse(JSON.stringify(this.$store.getters['block/getForm']));
      }
      if (!this.$route.params.from_block && Object.keys(this.page).length > 0) {
        this.form = JSON.parse(JSON.stringify({...this.page, blocks: []}));
        const blockEntities = await BlockEntity.$query()
          .filter('entity_type', '=', 'App\\Models\\Page')
          .filter('entity_id', '=', `${this.page.id}`)
          .search();
        let blocks = [];
        blockEntities.forEach((block) => {
          blocks.push({...block.$attributes.structure, id: block.$attributes.id})
        })
        this.form.blocks = blocks;
      }
    },
    async getSites() {
      await Site.$query().get(10000).then((response) => {
        this.sites = response.map(item => item.$attributes);
        if (!this.form.site) {
          this.form.site_id = this.sites.find(item => item.is_default == 1).id;
        }
      });
    },
    editParent() {
      return this.$router.resolve({ name: 'pages-edit', params: { id: this.page.parent.id } }).href;
    }
  },
  watch: {
    page: {
      handler() {
        this.getForm();
      },
      immediate: true,
    },
    media: {
      handler(media) {
        if (media && Object.keys(media).length > 0) {
          this.currentMedia = JSON.parse(JSON.stringify(media));
          this.reRenderMedia += 1;
        }
      }
    },
    'form.title': {
      handler(value) {
        if (value && Object.keys(this.page).length === 0) {
          this.form.slug = slugify(value.toLowerCase()).match(/[a-z]|[0-9]|[-]/gm).join('');
        }
      }
    },
  },
};
</script>
