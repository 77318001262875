<template>
  <div>
    <v-row>
      <v-col cols="8" sm="12" md="8">
        <v-card>
          <v-card-title>
            <h3 class="title blue-grey--text text--darken-2 font-weight-regular">
              {{ Object.keys(lesson).length > 0 ? 'Редактирование' : 'Создание' }} {{ checkParentId() ? 'подстраницы' : 'урока' }}</h3>
          </v-card-title>
          <v-card-subtitle>
            <h6 class="subtitle-2 font-weight-light">Пожалуйста, заполните основные поля</h6>
          </v-card-subtitle>
          <v-divider></v-divider>
          <v-card-text class="pa-5">
            <v-row>
              <v-col>
                <v-text-field
                  v-model="form.title"
                  :label="checkParentId() ? 'Название страницы' : 'Название урока'"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-textarea
                  v-model="form.description"
                  label="Описание"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <label>Контент</label>
                <media-form
                  ref="media-form"
                  :key="reRenderMedia"
                  :structure="form.structure"
                  style="margin: 0 auto"
                  :is-show-save="false"/>
              </v-col>
            </v-row>
            <v-row v-if="lesson.id">
              <v-col>
                <media-widget
                  label="Изображение"
                  :medias="form.image"
                  :is-multiple="false"
                  @onChange="setImage"/>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="4" sm="12" md="4">
        <v-card>
          <v-card-title>
            <h3 class="title blue-grey--text text--darken-2 font-weight-regular">Настройки</h3>
          </v-card-title>
          <v-card-subtitle>
            <h6 class="subtitle-2 font-weight-light">Пожалуйста, проверьте дополнительные поля</h6>
          </v-card-subtitle>
          <v-divider></v-divider>
          <v-card-text class="pa-5">
            <v-row>
              <v-col>
                <v-text-field
                  v-model="form.slug"
                  label="Slug"/>
              </v-col>
            </v-row>
            <v-row v-if="form.client_url">
              <v-col>
                <a
                  v-if="form.client_url"
                  :href="form.client_url"
                  target="_blank"
                  class="mr-3"
                >
                  {{ form.client_url }}
                </a>
                <preview :preview-url="form.client_url"/>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-select
                  v-model="form.status"
                  :items="statuses"
                  item-value="value"
                  item-text="label"
                  label="Статус"/>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-select
                  v-model="form.display_type"
                  :items="displayTypes"
                  item-value="value"
                  item-text="label"
                  label="Режим отображения"
                />
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row v-if="isCompact">
      <v-col>
        <v-btn color="primary" class="mb-2 mr-2" @click="submit(false)">Сохранить</v-btn>
        <v-btn color="primary" class="mb-2" v-if="lesson.id" :href="`${lesson.client_url}?edit_mode=true`"
               target="_blank">Редактирование блоков
        </v-btn>
      </v-col>
    </v-row>
    <v-row v-else>
      <v-col>
        <v-btn color="primary" class="mr-3" v-if="lesson.id" :href="`${lesson.client_url}?edit_mode=true`"
               target="_blank">Редактирование блоков
        </v-btn>
        <v-btn color="primary" class="mr-3" @click="submit(true)">Сохранить</v-btn>
        <v-btn color="primary" class="mr-3" @click="submit(false)">Сохранить и продолжить</v-btn>
      </v-col>
    </v-row>
    <media-uploader/>
  </div>
</template>

<script>
import MediaWidget from "../../MediaWidget";
import MediaUploader from "../../MediaUploader";
import MediaForm from "../../medias/MediaForm";
import slugify from "slugify";

export default {
  name: "LessonForm",
  components: {
    MediaWidget,
    MediaUploader,
    MediaForm,
  },
  props: {
    lesson: {
      type: Object,
      default: () => ({}),
    },
    "isCompact": {
      type: Boolean,
      default: () => (false),
    }
  },
  data: () => ({
    form: {
      title: null,
      slug: null,
      description: null,
      image: null,
      course_id: null,
      parent_id: null,
      status: 0,
      structure: null,
      display_type: 0,
    },
    defaultForm: {
      title: null,
      slug: null,
      description: null,
      image: null,
      course_id: null,
      parent_id: null,
      structure: null,
      status: 0,
      display_type: 0,
    },
    reRenderMedia: 1,
    statuses: [
      {
        label: 'Черновик',
        value: 0,
      },
      {
        label: 'Опубликовано',
        value: 1,
      }
    ],
    displayTypes: [
      {
        label: 'Медиа режим',
        value: 0,
      },
      {
        label: 'Блочный режим',
        value: 1,
      },
      {
        label: 'Смешанный',
        value: 2,
      }
    ]
  }),
  created() {
    if (Object.keys(this.lesson).length > 0) {
      this.form = JSON.parse(JSON.stringify(this.lesson));
    }
  },
  methods: {
    setImage(val) {
      this.form.image = val;
    },
    async submit(needRedirect) {
      this.form.structure = await this.$refs["media-form"].submit();
      this.form.course_id = this.$route.params.id;
      this.$emit('onSubmit', {
        form: this.form,
        needRedirect: needRedirect,
      });
    },
    resetForm() {
      this.form = this.defaultForm;
      this.reRenderMedia = this.reRenderMedia + 1;
    },
    setParentId(id) {
      this.form.parent_id = id;
    },
    checkParentId() {
      if (this.form.parent_id || this.$route.query.parent_id) {
        return true;
      }

      return false;
    }
  },
  watch: {
    lesson: {
      handler(value) {
        if (Object.keys(value).length > 0) {
          this.form = JSON.parse(JSON.stringify(value));
          this.reRenderMedia = this.reRenderMedia + 1;
        }
      }
    },
    'form.title': {
      handler(value) {
        if (value && Object.keys(this.lesson).length === 0) {
          this.form.slug = slugify(value.toLowerCase()).match(/[a-z]|[0-9]|[-]/gm).join('');
        }
      }
    },
  }
}
</script>

<style scoped>

</style>
