<template>
  <v-container>
    <v-row>
      <v-col>
        <h1>Редактирование промокода "{{ promocode.title }}"</h1>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <promocode-form
          :promocode="promocode"
          :errors="errors"
          @onSubmit="submit"
        />
      </v-col>
    </v-row>
    <v-snackbar
      v-model="snackbarSuccess"
      :timeout="timeout"
      color="success"
    >
      Сохранено

      <template v-slot:action="{ attrs }">
        <v-btn
          icon
          @click="snackbarSuccess = false"
          color="red"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
    <v-snackbar
      v-model="snackbarError"
      :timeout="timeout"
      color="error"
    >
      {{ this.errors }}

      <template v-slot:action="{ attrs }">
        <v-btn
          icon
          @click="snackbarError = false"
          color="red"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import PromocodeForm from "../../../components/promocodes/PromocodeForm";
import {Promocode} from "../../../models/promocode";

export default {
  name: 'Create',
  components: { PromocodeForm },
  data: () => ({
    promocode: {},
    errors: {},
    snackbarSuccess: false,
    snackbarError: false,
    timeout: 2000,
  }),
  mounted() {
    this.find();
  },
  methods: {
    async submit({ form }) {
      try {
        this.errors = {};
        await Promocode.$query().update(form.id, form);
        await this.$router.push({ name: 'promocodes-index'});
        this.snackbarSuccess = true;
      } catch (e) {
        if (e.response?.data) {
          this.errors = e.response.data.errors
          this.snackbarError = true;
        }
        throw e;
      }
    },
    async find() {
      const promocode = await Promocode.$query().find(this.$route.params.id);
      this.promocode = promocode.$attributes;
    },
  },
};
</script>
