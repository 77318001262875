import Index from '../../views/main/faq/Index'
import Create from '../../views/main/faq/Create'
import Edit from '../../views/main/faq/Edit';

export default [
  {
    component: Index,
    name: 'faq-index',
    path: '/faq',
    meta: {
      layout: 'main-layout',
      requiresAuth: true,
      module: 'FAQ',
    }
  },
  {
    component: Create,
    name: 'faq-create',
    path: '/faq/create',
    meta: {
      layout: 'main-layout',
      requiresAuth: true,
      module: 'FAQ',
    }
  },
  {
    component: Edit,
    name: 'faq-edit',
    path: '/faq/edit/:id',
    meta: {
      layout: 'main-layout',
      requiresAuth: true,
      module: 'FAQ',
    }
  },
]
