<template>
  <component :is="theme.components.BaseElementLayout"
             :el$="el$"
  >
    <template slot="field">
      <slot name="prefix"></slot>

      <TipTap v-model="model" :value="model"/>

      <slot name="suffix"></slot>
    </template>

    <slot slot="label" name="label" :el$="el$"></slot>
    <slot slot="info" name="info" :el$="el$"></slot>
    <slot slot="before" name="before" :el$="el$"></slot>
    <slot slot="between" name="between" :el$="el$"></slot>
    <slot slot="error" name="error" :el$="el$"></slot>
    <slot slot="after" name="after" :el$="el$"></slot>
  </component>
</template>

<script>
import BaseElement from '@laraform/laraform/src/mixins/BaseElement'
import BaseValidation from '@laraform/laraform/src/mixins/BaseValidation'
import TipTap from "../editor/TipTap.vue";

export default {
  name: "TitTapElement",
  mixins: [BaseElement, BaseValidation],
  components:{
    TipTap,
  },
}
</script>

<style scoped>

</style>
