import {Role} from "../../../models/role";

export default {
  namespaced: true,
  state: {
    fetchedList: [],
  },
  getters: {
    getFetchedList: (state) => state.fetchedList,
  },
  mutations: {
    setFetchedList(state, val) {
      state.fetchedList = val;
    }
  },
  actions: {
    fetchList({ commit }) {
      Role.$query().get(10000).then((response) => {
        commit('setFetchedList', response.map(item => item.$attributes));
      })
    }
  }
}
