import axiosInstance from "../../../axiosInstance";
import {Field} from "../../../models/field";

export default {
  namespaced: true,
  state: {
    fetchedListing: [],
    fetchedDetail: {},
    isLoading: false,
    isButtonDisabled: false,
    totalItems: 0,
    types: [],
    groups: [],
    hints: {},
  },
  getters: {
    getFetchedListing: (state) => state.fetchedListing,
    getFetchedDetail: (state) => state.fetchedDetail,
    getTotalItems: (state) => state.totalItems,
    getTypes: (state) => state.types,
    getGroups: (state) => state.groups,
    getHints: (state) => state.hints,
    isLoading: (state) => state.isLoading,
  },
  mutations: {
    setFetchedListing(state, val) {
      state.fetchedListing = val;
    },
    setIsLoading(state, val) {
      state.isLoading = val;
    },
    setTotalItems(state, val) {
      state.totalItems = val;
    },
    setGroups(state, val) {
      state.groups = val;
    },
    setTypes(state, val) {
      state.types = val;
    },
    setHints(state, val) {
      state.hints = val;
    },
    setFetchedDetail(state, val) {
      state.fetchedDetail = val;
    },
  },
  actions: {
    async fetchListing({commit, dispatch}, {query, options, search}) {
      commit("setIsLoading", true)

      let filters = {
        filters: [],
        search: {},
      };

      const {group, type} = query;
      const {itemsPerPage, page} = options;

      filters.search = {value: search};
      if (group || group === 0) filters.filters.push({field: "group_id", operator: "=", value: group})
      if (type || type === 0) filters.filters.push({field: "type", operator: "=", value: type});

      await axiosInstance.post(`module/fields/admin/fields/search?limit=${itemsPerPage}&page=${page}`, filters).then((response) => {
        commit('setFetchedListing', response.data.data);
        commit('setTotalItems', response.data.meta.total);
      }).catch(() => {
        dispatch("snackbar/showUnknownError", null, {root: true})
      }).finally(() => {
        commit("setIsLoading", false)
      })
    },
    async destroyItem({dispatch}, id) {
      await Field.$query().destroy(id).then(() => {
        dispatch("snackbar/showSuccessfullyDelete", null, {root: true})
      }).catch(() => {
        dispatch("snackbar/showDeleteError", null, {root: true});
      })
    },
    async fetchGroups({commit, dispatch}) {
      await axiosInstance.get(`module/fields/admin/fields/groups`).then((response) => {
        commit("setGroups", response.data);
      }).catch(() => {
        dispatch("snackbar/showUnknownError", null, {root: true})
      });
    },
    async fetchTypes({commit, dispatch}) {
      await axiosInstance.get(`module/fields/admin/fields/types`).then((response) => {
        commit("setTypes", response.data);
      }).catch(() => {
        dispatch("snackbar/showUnknownError", null, {root: true})
      });
    },
    async storeItem({dispatch}, {payload, router}) {
      Field.$query().store(payload).then((response) => {
        dispatch("snackbar/showSuccessfullySaved", null, {root: true})
        router.push({name: 'fields-edit', params: {id: response.$attributes.id}});
      }).catch(() => {
        dispatch("snackbar/showSaveError", null, {root: true});
      })
    },
    async fetchDetail({dispatch, commit}, id) {
      Field.$query().find(id).then((response) => {
        commit("setFetchedDetail", response.$attributes);
      }).catch(() => {
        dispatch("snackbar/showUnknownError", null, {root: true});
      })
    },
    async updateItem({dispatch, commit}, form) {
      await Field.$query().update(form.id, form).then((response) => {
        commit("setFetchedDetail", response.$attributes);
        dispatch("snackbar/showSuccessfullySaved", null, {root: true});
      }).catch(() => {
        dispatch("snackbar/showUnknownError", null, {root: true})
      });
    },
    async fetchHints({commit, dispatch}) {
      await axiosInstance.get(`module/fields/admin/fields/hints`).then((response) => {
        commit("setHints", response.data);
      }).catch(() => {
        dispatch("snackbar/showUnknownError", null, {root: true})
      });
    },
  }
}
