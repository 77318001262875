<template>
  <v-container fluid class="down-top-padding">
    <BaseBreadcrumb :title="page.title" :icon="page.icon" :breadcrumbs="breadcrumbs"></BaseBreadcrumb>
    <v-card>
      <v-card-text class="pa-5">
        <v-row>
          <v-col>
            <occupied-alert
              v-if="article && article.id"
              :item-id="article.id"
              prefix="article"
              @change="occupied"
            ></occupied-alert>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <h1>
              Редактирование статьи "{{ article.title || article.id }}"
              <v-btn
                icon
                :href="`${apiUrl}/api/module/articles/${article.id}/export`"
              >
                <v-icon color="primary">
                  mdi-file-download-outline
                </v-icon>
              </v-btn>
            </h1>
          </v-col>
        </v-row>
        <v-tabs
          v-model="tab"
          background-color="transparent"
          grow
          class="mb-5"
        >
          <v-tab>
            Основная информация
          </v-tab>
          <v-tab>
            Таксономия
          </v-tab>
          <v-tab v-if="Object.keys(article).length > 0">
            SEO - позиции
          </v-tab>
          <v-tab>
            SEO
          </v-tab>
          <v-tab>
            SMM
          </v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab">
          <v-tab-item>
            <article-form
              ref="form"
              :article="article"
              :media="media"
              :errors="errors"
              :is-disabled="isOccupied"
              @onSubmit="submit"
            />
          </v-tab-item>
          <v-tab-item>
            <h4>Сниппет</h4>
            <v-card
              class="mb-2"
              color="grey"
            >
              <div class="container text-center">
                <h1>
                  {{ article.title }}
                </h1>
                <div class="text-center">
                  <a
                    v-if="article.main_taxonomy"
                    :href="'/article/'"
                    :class="`${article.main_taxonomy.color_code || 'text-1'}`"
                  >
                    {{ article.main_taxonomy.title }},
                  </a>
                  <a
                    v-if="article.main_taxonomy_child"
                    :href="'/article/'"
                    :class="`${article.main_taxonomy_child.color_code || 'text-1'}`"
                  >
                    {{ article.main_taxonomy_child.title }},
                  </a>
                  <span class="text-secondary">
                    {{ article.published_at  | moment('DD MMMM YYYY') }}
                  </span>
                </div>
              </div>
            </v-card>
            <collection-relation-form
              module-name="Articles"
              :errors="errors"
              :selected-collection-items="article.selected_collection_items"
              :is-disabled="isOccupied"
              :main-items="article.main_taxonomy_ids"
              @onSubmit="submitCollections"
              @onSubmitMain="submitCollectionsMainItems"
            />
            <h4>Сниппет</h4>
            <v-card color="grey">
              <div class="container text-center">
                <div
                  v-if="article.taxonomy"
                  class="article-content-tags"
                >
                  Теги:
                  <a
                    v-for="(item, index) in article.taxonomy"
                    :href="'/article/'"
                    :class="`text-${item.parent && item.parent.color_code ? item.parent.color_code : item.color_code}`"
                    :key="index"
                  >
                    {{ item.title }};
                  </a>
                </div>
              </div>
            </v-card>
          </v-tab-item>
          <v-tab-item v-if="Object.keys(article).length > 0">
            <SeoPositionComponent
              :entity_id="article.id"
              :entity_type="article.entity_type"
            />
          </v-tab-item>
          <v-tab-item>
            <seo-form-separate
              :entity="article"
              :errors="errors"
              :is-disabled="isOccupied"
              @onSubmit="submit"
            />
          </v-tab-item>
          <v-tab-item>
            <smm-form-separate
              :entity="article"
              :errors="errors"
              :is-disabled="isOccupied"
              @onSubmit="submit"
            />
          </v-tab-item>
        </v-tabs-items>
        <v-snackbar
          v-model="snackbarSuccess"
          :timeout="timeout"
          color="success"
        >
          Сохранено

          <template v-slot:action="{ attrs }">
            <v-btn
              icon
              @click="snackbarSuccess = false"
              color="red"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </template>
        </v-snackbar>
        <v-snackbar
          v-model="snackbarError"
          :timeout="timeout"
          color="error"
        >
          {{ this.errors }}

          <template v-slot:action="{ attrs }">
            <v-btn
              icon
              @click="snackbarError = false"
              color="red"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </template>
        </v-snackbar>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import ArticleForm from '../../../components/articles/ArticleForm';
import SeoFormSeparate from "../../../components/SEOFormSeparate";
import SmmFormSeparate from "../../../components/SMMFormSeparate";
import CollectionRelationForm from '../../../components/collections/CollectionRelationForm';
import {Article} from '../../../models/article';
import {Media} from '../../../models/media';
import OccupiedAlert from '../../../components/occupiedPages/Alert.vue';
import axiosInstance from "../../../axiosInstance";
import SeoPositionComponent from "../../../components/seo/SeoPositionComponent";

export default {
  name: 'Edit',
  components: {
    SeoPositionComponent,
    ArticleForm,
    CollectionRelationForm,
    OccupiedAlert,
    SeoFormSeparate,
    SmmFormSeparate,
  },
  data: () => ({
    article: {},
    media: {},
    errors: {},
    tab: 0,
    snackbarSuccess: false,
    snackbarError: false,
    timeout: 2000,
    isOccupied: false,
    apiUrl: process.env.VUE_APP_API_URL,
    page: {
      title: "Редактирование статьи"
    },
    breadcrumbs: [
      {
        text: "Админ панель",
        disabled: false,
        to: '/'
      },
      {
        text: "Медиа",
        disabled: false,
        to: '/articles'
      },
      {
        text: "Редактирование",
        disabled: true,
        to: '/edit'
      }
    ],
  }),
  created() {
    this.find();
  },
  methods: {
    async submit({form, media, mediaIds, isNeedRedirect}) {
      try {
        this.errors = {};

        if (mediaIds) {
          form.mediaIds = mediaIds;
        }

        if (media && media.id) {
          await Media.$query().update(media.id, {...media, entity_id: form.id});
        } else if (media) {
          await Media.$query().store({...media, entity_id: form.id});
        }

        const article = await Article.$query().update(form.id, form);
        await article.persons().sync(form.selected_authors || []);

        if (!mediaIds || mediaIds.length === 0) {
          await this.find();
        }

        this.snackbarSuccess = true;

        if (isNeedRedirect) {
          this.$router.push({ name: 'articles-index' });
        }

      } catch (e) {
        if (e.response?.data) {
          this.errors = e.response.data.errors || e.response.data.message;
          this.snackbarError = true;
        } else {
          this.errors = e;
          this.snackbarError = true;
        }
        throw e;
      }
    },
    async submitCollections({ items, isNeedRedirect }) {
      try {
        this.errors = {};
        if (items.length > 0) {
          const article = await Article.$query().find(this.$route.params.id);
          await article.collectionItems().sync(items);
          await this.find();

          if (isNeedRedirect) {
            this.$router.push({ name: 'articles-index' });
          }
        }
        this.snackbarSuccess = true;
      } catch (e) {
        if (e.response?.data) {
          this.errors = e.response.data.errors || e.response.data.message;
          this.snackbarError = true;
        } else {
          this.errors = e;
          this.snackbarError = true;
        }
        throw e;
      }
    },
    async submitCollectionsMainItems(items) {
      try {
        this.errors = {};
        await axiosInstance.post(`/module/articles/${this.article.id}/collection-items/make-main`, {items});
        this.snackbarSuccess = true;
        await this.find();
      } catch (e) {
        if (e.response?.data) {
          this.errors = e.response.data.errors || e.response.data.message;
          this.snackbarError = true;
        } else {
          this.errors = e;
          this.snackbarError = true;
        }
        throw e;
      }
    },
    occupied(value) {
      this.isOccupied = value;
    },
    async find() {
      const article = await Article.$query()
        .with(['collectionItems', 'persons'])
        .find(this.$route.params.id);

      this.article = article.$attributes;
      const media = await Media.$query()
        .filter('entity', '=', 'Modules\\Articles\\Entities\\Article')
        .filter('entity_id', '=', `${this.article.id}`).search(1);
      this.media = media && media.length > 0 ? media[0].$attributes : null;
    },
  },
};
</script>
