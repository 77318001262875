<template>
  <div class="p-3">
    <v-row>
      <v-col>
        <h3>Сортировка Уроков</h3>
        <v-card style="max-height: 400px; overflow-y: scroll">
          <v-list>
            <v-list-item-group>
              <draggable v-model="selectedItems" style="overflow-x: hidden; overflow-y: hidden">
                <div v-for="(item, i) in selectedItems" :key="i">
                  <v-row>
                    <v-col cols="1">
                      <v-icon>mdi-arrow-all</v-icon>
                    </v-col>
                    <v-col cols="10">
                      <div>
                        Порядковый номер {{ i + 1 }}
                      </div>
                      <div>
                        Урок: <span class="font-weight-black">{{ item.title }}</span>
                      </div>
                    </v-col>
                  </v-row>
                </div>
              </draggable>
            </v-list-item-group>
          </v-list>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-flex>
          <v-btn
            class="mr-4"
            color="primary"
            @click="submit"
          >
            Сохранить
          </v-btn>
        </v-flex>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import draggable from "vuedraggable";

export default {
  name: "SortingLessonForm",
  components: {draggable},
  data: () => ({
    selectedItems: [],
  }),
  created() {
    this.fetchLessonList(this.$route.params.id);
  },
  computed: {
    ...mapGetters({
      'lessonList': "ChimeraCourses/getLessonList",
    })
  },
  methods: {
    ...mapActions({
      "fetchLessonList": "ChimeraCourses/fetchLessonList",
      "delete": "ChimeraLessons/deleteItem",
      "storePositions": "ChimeraLessons/storePositions",
    }),
    editItem(id) {
      return this.$router.resolve({name: 'chimera-lessons-edit', params: {id: this.$route.params.id, lessonID: id}}).href
    },
    deleteItem(id) {
      this.delete({
        id: id,
        isForce: false,
      }).then(() => {
        this.fetchLessonList(this.$route.params.id);
      });
    },
    submit() {
      const ids = {};

      this.selectedItems.map((item, index) => {
        ids[item.id.toString()] = {'position': `${index + 1}`}
      })

      this.storePositions(ids).then(() => {
        this.fetchLessonList(this.$route.params.id);
      });
    }
  },
  watch: {
    lessonList: {
      handler(val) {
        this.selectedItems = val;
      }
    }
  }
}
</script>

<style scoped>

</style>
