<template>
<div>
    <v-row>
      <v-col  cols="8" sm="12" md="8"  >
        <v-card>
          <v-card-title>
            <h3 class="title blue-grey--text text--darken-2 font-weight-regular">{{ Object.keys(course).length > 0 ? 'Редактирование' : 'Создание' }} курса</h3>
          </v-card-title>
          <v-card-subtitle>
            <h6 class="subtitle-2 font-weight-light">Пожалуйста, заполните основные поля</h6>
          </v-card-subtitle>
          <v-divider></v-divider>
          <v-card-text class="pa-5">
        <v-row>
          <v-col>
            <v-text-field
              v-model="form.title"
              label="Название"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-textarea
              v-model="form.description"
              label="Описание"
            />
          </v-col>
        </v-row>
            <v-row>
              <v-col>
              <label>Контент</label>
              <media-form
                ref="media-form"
                :key="reRenderMedia"
                :structure="form.structure"
                :is-show-save="false"
                style="margin: 0 auto"
              />
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <media-widget
                  label="Изображение курса"
                  :medias="form.image"
                  :is-multiple="false"
                  @onChange="setImage"/>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col  cols="4" sm="12" md="4" >
        <v-card>
          <v-card-title>
            <h3 class="title blue-grey--text text--darken-2 font-weight-regular">Настройки</h3>
          </v-card-title>
          <v-card-subtitle>
            <h6 class="subtitle-2 font-weight-light">Пожалуйста, проверьте дополнительные поля</h6>
          </v-card-subtitle>
          <v-divider></v-divider>
          <v-card-text class="pa-5">
        <v-row>
          <v-col>
            <v-text-field
              v-model="form.slug"
              :disabled="form.id !== undefined"
              label="Постоянная ссылка (slug)"/>
          </v-col>
        </v-row>
        <v-row v-if="form.client_url">
          <a
            v-if="form.client_url"
            :href="form.client_url"
            target="_blank"
            class="mr-3"
          >
            {{ form.client_url }}
          </a>
          <preview  :preview-url="form.client_url"/>
        </v-row>
        <v-row>
          <v-col>
            <v-select
              v-model="form.status"
              :items="statuses"
              item-value="value"
              item-text="label"
              label="Статус"/>
          </v-col>
        </v-row>
            <v-row>
              <v-col>
                <v-select
                  v-model="form.display_type"
                  :items="displayTypes"
                  item-value="value"
                  item-text="label"
                  label="Статус"
                  />
              </v-col>
            </v-row>
        <v-row>
          <v-col>
            <v-datetime-picker
              v-model="form.published_at"
              label="Дата публикации"/>
          </v-col>
        </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row>   <v-col>
      <v-btn color="primary" class="mr-4"  v-if="form.id" target="_blank" :href="`${course.client_url}?edit_mode=true`">Редактирования блоков</v-btn>
      <v-btn color="primary" class="mr-4" @click="submit(true)">Сохранить</v-btn>
      <v-btn color="primary" @click="submit(false)">Сохранить и продолжить</v-btn>
    </v-col> </v-row>
    <media-uploader/>
</div>
</template>

<script>
import MediaWidget from "../MediaWidget";
import MediaUploader from "../MediaUploader";
import slugify from "slugify";
import MediaForm from "../medias/MediaForm";

export default {
  name: "CourseForm",
  components: {MediaForm, MediaUploader, MediaWidget},
  props: {
    course: {
      type: Object,
      default: () => ({}),
    }
  },
  data: () => ({
    page: {
      title: 'Создание курса',
    },
    form: {
      title: null,
      slug: null,
      status: 0,
      description: null,
      image: null,
      publshed_at: null,
      structure: null,
      display_type: 0,
    },
    reRenderMedia: 1,
    statuses: [
      {
        label: 'Черновик',
        value: 0,
      },
      {
        label: 'Опубликовано',
        value: 1,
      }
    ],
    displayTypes: [
      {
        label: 'Медиа режим',
        value: 0,
      },
      {
        label: 'Блочный режим',
        value: 1,
      },
      {
        label: 'Смешанный',
        value: 2,
      }
    ]
  }),
  methods: {
    setImage(val) {
      this.form.image = val;
    },
    async submit(needRedirect) {
      this.form.structure = await this.$refs["media-form"].submit();

      this.$emit('onSubmit', {
        form: this.form,
        needRedirect: needRedirect,
      });
    }
  },
  watch: {
    course: {
      handler(value) {
        if (Object.keys(value).length > 0) {
          this.form = JSON.parse(JSON.stringify(value));
          this.reRenderMedia = this.reRenderMedia + 1;
        }
      }
    },
    'form.title': {
      handler(value) {
        if (value && Object.keys(this.course).length === 0) {
          this.form.slug = slugify(value.toLowerCase()).match(/[a-z]|[0-9]|[-]/gm).join('');
        }
      }
    },
  }
}
</script>

<style scoped>

</style>
