<template>
  <v-form>
    <v-text-field
      v-model="form.title"
      :error-messages="errors.title"
      label="Название"
      name="title"
      required
    ></v-text-field>
    <v-text-field
      v-model="form.code"
      :error-messages="errors.code"
      label="Промокод"
      :rules="[promocodeCheck()]"
      counter="12"
      name="code"
      required
    ></v-text-field>
    <v-select
      v-model="form.type"
      :error-messages="errors.type"
      :items="types"
      label="Сценарий"
      name="type"
      required
    ></v-select>
    <v-text-field
      v-model="form.amount"
      :error-messages="errors.amount"
      label="Величина"
      name="amount"
      type="number"
      :disabled="form.type === null"
      required
    ></v-text-field>
    <v-text-field
      v-model="form.valid_from"
      :error-messages="errors.valid_from"
      label="Активен с"
      name="valid_from"
      type="date"
      required
    ></v-text-field>
    <v-text-field
      v-model="form.valid_to"
      :error-messages="errors.valid_to"
      label="Активен по"
      name="valid_to"
      type="date"
      required
    ></v-text-field>
    <v-text-field
      v-model="form.activation_limit"
      :error-messages="errors.activation_limit"
      label="Лимит активаций"
      name="activation_limit"
      type="number"
      required
    ></v-text-field>
    <v-checkbox
      v-model="form.status"
      :error-messages="errors.status"
      name="status"
      label="Активен"
      :true-value="1"
      :false-value="0"
    ></v-checkbox>
    <v-flex>
      <v-btn
        color="primary"
        :disabled="isSaveDisabled"
        :loading="isSaveDisabled"
        @click="submit"
      >
        Сохранить
      </v-btn>
    </v-flex>
  </v-form>
</template>

<script>
export default {
  name: "PromocodeForm",
  props: {
    promocode: {
      type: Object,
      default: () => ({})
    },
    errors: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    form: {
      title: null,
      code: null,
      type: null,
      amount: null,
      valid_from: null,
      valid_to: null,
      activation_limit: null,
      status: 0,
    },
    types: [
      {text: 'Скидка на сумму', value: 0},
      {text: 'Скидка на %', value: 1},
      {text: 'Доступ', value: 2},
    ],
    codePattern: new RegExp('[^A-Za-z0-9]+'),
    isSaveDisabled: false,
  }),
  created() {
    if (this.$route.params.data) {
      this.form = JSON.parse(this.$route.params.data);
    }
  },
  methods: {
    submit() {
      if (this.promocodeCheck()) {
        this.isSaveDisabled = true;
        this.$emit('onSubmit', {
          form: this.form,
        });
      }
    },
    promocodeCheck() {
      if (this.codePattern.test(this.form.code) || (this.form.code && this.form.code.length > 12)) {
        this.errors['code'] = 'Латиница и цифры, не больше 12 символов';
        return false;
      }

      this.errors['code'] = null;
      return true;
    }
  },
  watch: {
    promocode: {
      handler(promocode) {
        if (promocode && Object.keys(promocode).length > 0) {
          this.form = promocode;
          this.oldCode = this.form.code;
          this.isSaveDisabled = false;
        }
      },
      immediate: true,
      deep: true,
    },
  },
}
</script>
