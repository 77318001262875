<template>
  <v-container>
    <v-card v-if="statistic.indicators">
      <v-card-title>
        <v-row align="center">
          <v-col>
            Популярное
          </v-col>
          <v-col>
            <v-dialog
              ref="dialog"
              v-model="dateDialog"
              :return-value.sync="date"
              persistent
              width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="date"
                  label="Выберите промежуток"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                />
              </template>
              <v-date-picker
                v-model="date"
                range
                scrollable
                locale="ru-RU"
              >
                <v-spacer></v-spacer>
                <v-btn
                  text
                  color="primary"
                  @click="dateDialog = false"
                >
                  Закрыть
                </v-btn>
                <v-btn
                  text
                  color="primary"
                  @click="$refs.dialog.save(date)"
                >
                  Oк
                </v-btn>
              </v-date-picker>
            </v-dialog>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text>
        <div>
          <v-row>
            <v-col sm="3">
              <div class="text h3">Пользователи</div>
              <div class="text h3 font-weight-normal">{{ statistic.indicators.users }}</div>
            </v-col>
            <v-col sm="3">
              <div class="text h3">Просмотр страниц</div>
              <div class="text h3 font-weight-normal">{{ statistic.indicators.pageviews }}</div>
            </v-col>
            <v-col>
              <div class="text h3">Ср.длительность просмотра стр.</div>
              <div class="text h3 font-weight-normal">{{ statistic.indicators.avrDuration }}</div>
            </v-col>
          </v-row>
        </div>
      </v-card-text>
    </v-card>
    <v-card class="mt-5" v-if="statistic.pagesTable">
      <v-card-title>
        Пользователи
      </v-card-title>
      <v-card-text>
        <v-data-table
          :headers="pagesTableHandler"
          :items-per-page="5"
          :items="statistic.pagesTable"/>
      </v-card-text>
    </v-card>
    <v-card class="mt-5" v-if="statistic.auditory">
      <v-card-title>
        Распределение по аудитории
      </v-card-title>
      <v-card-text>
        <apexchart type="bar" :options="statistic.auditory.options" :series="statistic.auditory.series"/>
      </v-card-text>
    </v-card>
    <v-card class="mt-5" v-if="statistic.pageViews">
      <v-card-title>
        Распределение просмотров страниц по разделам сайта
      </v-card-title>
      <v-card-text>
        <apexchart type="bar" :options="statistic.pageViews.options" :series="statistic.pageViews.series"/>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
  name: "Popular",
  data: () => ({
    dateDialog: false,
    date: [],
    isMounted: false,
    pagesTableHandler: [
      { text: 'Страница', value: 'URL' },
      { text: 'Название страницы', value: 'Title' },
      { text: 'Пользователи', value: 'users' },
      { text: 'Просмотры страниц', value: 'pageViews' },
    ]
  }),
  async created() {
    const currentDate = new Date();
    const currentMonth = currentDate.getMonth() < 10 ? `0${currentDate.getMonth() + 1}` : currentDate.getMonth() + 1;
    const lastDay = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0)

    this.date = [
      `${lastDay.getFullYear()}-${currentMonth}-01`,
      `${lastDay.getFullYear()}-${currentMonth}-${lastDay.getDate()}`
    ];

    await this.fetch({
      interval: {
        from: this.date[0],
        to: this.date[1],
      }
    })
  },
  mounted() {
    this.isMounted = true;
  },
  computed: {
    ...mapGetters({
      'statistic': 'Statistics/getPopularStatistic',
    }),
    payload() {
      return {
        interval: {
          from: this.date[0],
          to: this.date[1],
        }
      }
    }
  },
  methods: {
    ...mapActions({
      'fetch': 'Statistics/fetchPopularStatistic',
    }),
    formatDate(date) {
      const currentMonth = date.getMonth() < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1;
      const currentDay = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate();
      return `${date.getFullYear()}-${currentMonth}-${currentDay}`
    }
  },
  watch: {
    date: {
      async handler() {
        if (this.date.length === 2) {
          const firstDate = new Date(this.date[0]);
          const secondDate = new Date(this.date[1]);

          if (firstDate > secondDate) {
            this.date[0] = this.formatDate(secondDate);
            this.date[1] = this.formatDate(firstDate);
          }

          await this.fetch(this.payload)
        }
      }
    }
  }
}
</script>

<style scoped>

</style>
