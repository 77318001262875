import {Course} from "../../../models/course";
import axiosInstance from "../../../axiosInstance";

export default {
  namespaced: true,
  state: {
    fetchedListing: [],
    fetchedListingWithoutFilters: [],
    listingTotalItems: 0,
    isListingLoad: false,
    fetchedDetail: {},
    isSaveButtonDisabled: false,
  },
  getters: {
    getFetchedListing: (state) => state.fetchedListing,
    getListingTotalItems: (state) => state.listingTotalItems,
    getFetchedDetail: (state) => state.fetchedDetail,
    getFetchedListingWithoutFilters: (state) => state.fetchedListingWithoutFilters,
    isListingLoad: (state) => state.isListingLoad,
    isSaveButtonDisabled: (state) => state.isSaveButtonDisabled,
  },
  mutations: {
    setFetchedListing(state, val) {
      state.fetchedListing = val;
    },
    setListingTotalItems(state, val) {
      state.listingTotalItems = val;
    },
    setFetchedDetail(state, val) {
      state.fetchedDetail = val;
    },
    setIsListingLoad(state, val) {
      state.isListingLoad = val;
    },
    setIsSaveButtonDisabled(state, val) {
      state.saveButtonDisabled = val;
    },
    setFetchedListingWithoutFilters(state, val) {
      state.fetchedListingWithoutFilters = val;
    }
  },
  actions: {
    fetchListing({ commit }, { options, search, only_trashed, filters }) {
      commit('setIsListingLoad', true);

      const {sortBy, sortDesc, page, itemsPerPage} = options;
      const onlyTrashed = only_trashed ? `&only_trashed=1` : '';
      let data = {};
      search ? data.search = {value: search} : null;
      filters ? data.filters = filters : null;
      sortBy && sortBy[0] ? data.sort = [{field: sortBy[0], direction: sortDesc[0] ? 'desc' : 'asc'}] : null;

      axiosInstance.post(`/module/courses/search?limit=${itemsPerPage}&page=${page}${onlyTrashed}`, data).then((response) => {
        commit('setFetchedListing', response.data.data);
        commit('setListingTotalItems', response.data.meta.total);
      });

      commit('setIsListingLoad', false);
    },
    fetchDetail({ commit, dispatch }, id) {
      commit('setIsSaveButtonDisabled', true)

      Course.$query().find(id).then((response) => {
        commit('setFetchedDetail', response.$attributes);
      }).catch(() => {
        dispatch('snackbar/showUnknownError', null, { root: true })
      });

      commit('setIsSaveButtonDisabled', false);
    },
    restoreItem({dispatch}, {id}) {
      return Course.$query().restore(id).then(() => {
        dispatch('snackbar/showSuccessfullyRestore', null, { root: true });
      }).catch(() => {
        dispatch('snackbar/showErrorRestore', null, { root: true });
      });
    },
    pickupItem({dispatch}, {id}) {
      return axiosInstance.get(`/module/courses/${id}/pickup`).then(() => {
        dispatch('snackbar/showSuccessfullySaved', null, { root: true });
      }).catch(() => {
        dispatch('snackbar/showSaveError', null, { root: true });
      });
    },
    storeItem({ dispatch, commit }, { form, isNeedRedirect, router }) {
      commit('setIsSaveButtonDisabled', true);

      return Course.$query().store(form).then((response) => {
        dispatch('snackbar/showSuccessfullySaved', null, { root: true });
        commit('setIsSaveButtonDisabled', false);
        if (isNeedRedirect) {
          router.push({ name: 'courses-index' });
        } else {
          router.push({ name: 'courses-edit', params: { id: response.$attributes.id } });
        }

      }).catch(() => {
        dispatch('snackbar/showSaveError', null, { root: null });
      }).finally(() => {
        commit('setIsSaveButtonDisabled', false);
      })
    },
    updateItem({dispatch, commit}, {form, media, id}) {
      commit('setIsSaveButtonDisabled', true);

      if (media) {
        form.media_ids = media;
      }

      Course.$query().update(id, form).then((response) => {
        commit('setFetchedDetail', response.$attributes);
        dispatch('snackbar/showSuccessfullySaved', null, { root: true });
      }).catch(() => {
        dispatch('snackbar/showSaveError', null, { root: null });
      })

      commit('setIsSaveButtonDisabled', false);
    },
    storeCollections({ commit, dispatch }, { id, form }) {
      commit('setIsSaveButtonDisabled', true);

      Course.$query().find(id).then((response) => {
        response.collectionItems().sync(form).then(() => {
          dispatch('snackbar/showSuccessfullySaved', null, { root: true });
          dispatch('fetchDetail', id);
        }).catch(() => {
          dispatch('snackbar/showSaveError', null, { root: null });
        });
      }).catch(() => {
        dispatch('snackbar/showSaveError', null, { root: null });
      });

      commit('setIsSaveButtonDisabled', false);
    },
    storeCollectionsMainItems({ commit, dispatch }, { id, items }) {
      commit('setIsSaveButtonDisabled', true);

      axiosInstance.post(`/module/courses/${id}/collection-items/make-main`, {items}).then(() => {
        dispatch('snackbar/showSuccessfullySaved', null, { root: true });
        dispatch('fetchDetail', id);
      }).catch(() => {
        dispatch('snackbar/showSaveError', null, { root: null });
      })

      commit('setIsSaveButtonDisabled', false);
    },
    storeLessons({ dispatch, commit }, { id, lessons}) {
      commit('setIsSaveButtonDisabled', true);

      axiosInstance.patch(`/module/lessons/batch`, {resources: lessons}).then(() => {
        dispatch('snackbar/showSuccessfullySaved', null, { root: true });
        dispatch('fetchDetail', id);
      }).catch(() => {
        dispatch('snackbar/showSaveError', null, { root: null });
      })

      commit('setIsSaveButtonDisabled', false);
    },
    destroyItem({dispatch}, {id}) {
      return Course.$query().destroy(id, false).then(() => {
        dispatch('snackbar/showSuccessfullyDelete', null, { root: true });
      }).catch(() => {
        dispatch('snackbar/showDeleteError', null, { root: true });
      })
    },
    fetchListingWithoutFilters({ commit, dispatch }) {
      return Course.$query().get(10000).then((response) => {
        commit('setFetchedListingWithoutFilters', response.map(el => el.$attributes));
      }).catch(() => {
        dispatch('snackbar/showUnknownError', null, { root: true });
      })
    }
  },
}
