<template>
  <v-container fluid class="down-top-padding">
    <BaseBreadcrumb :title="page.title" :icon="page.icon" :breadcrumbs="breadcrumbs"></BaseBreadcrumb>
    <v-card>
      <v-card-text class="pa-5">
        <v-row>
          <v-col>
            <FAQForm
              :FAQ="FAQ"
              :errors="errors"
              @onSubmit="store"
            />
          </v-col>
        </v-row>
        <v-snackbar
          v-model="errors.hasErrors"
        >
          {{ this.errors.message }}
        </v-snackbar>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import FAQForm from "../../../components/faq/FAQForm";
import {mapActions} from "vuex";

export default {
  components: {
    FAQForm,
  },
  name: "Create",
  data(){
    return {
      FAQ: {},
      errors: {
        message: "",
        hasErrors: false,
      },
      snackbar: false,
      page: {
        title: "Создание FAQ"
      },
      breadcrumbs: [
        {
          text: "Админ панель",
          disabled: false,
          to: '/'
        },
        {
          text: "FAQ",
          disabled: true,
          to: '/FAQ'
        },
        {
          text: "Создание",
          disabled: true,
          to: '/FAQ/create'
        },
      ],
    }
  },
  methods: {
    ...mapActions({
      storeItem: 'FAQ/storeItem',
    }),
    store({form, isNeedRedirect}) {
      const payload = {
        form: form,
        isNeedRedirect: isNeedRedirect,
        router: this.$router,
      }

      this.storeItem(payload).then(this.$router.push({ name: 'faq-index' }));
    }
  }
}
</script>

<style scoped>

</style>
