<template>
  <v-container fluid class="down-top-padding">
    <BaseBreadcrumb :title="page.title" :icon="page.icon" :breadcrumbs="breadcrumbs"></BaseBreadcrumb>
      <v-card>
        <v-card-text class="pa-5">
          <v-row justify="space-between">
            <v-col cols="12" lg="4">
              <v-text-field
                v-model="search"
                prepend-inner-icon="mdi-magnify"
                label="Поиск..."
                filled
                background-color="transparent"
                hide-details
                name="title"
              >
                <v-btn v-if="search" class="reset-button"
                       slot="append"
                       icon
                       @click="search = null"
                       color="red"
                >
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </v-text-field>
            </v-col>
            <v-col cols="12" lg="4" class="justify-end align-center d-flex">
              <v-dialog v-model="dialog" persistent max-width="700px">
                <template v-slot:activator="{ on }">
                  <v-btn color="primary" to="/articles/create" v-if="canCreate">
                    Создать статью
                  </v-btn>
                  <v-btn color="primary" class="ml-2" :href="getExportUrl">
                    Выгрузить
                  </v-btn>
                </template>
              </v-dialog>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="3">
              <v-row align="center">
                <v-col class="pr-0">
                  <v-select
                    v-if="creators"
                    v-model="query.created_by"
                    :items="creators"
                    item-text="name"
                    item-value="id"
                    menu-props="auto"
                    label="Выберите автора"
                    name="created_by"
                    prepend-icon="mdi-account"
                    single-line
                    hide-details
                  ></v-select>
                </v-col>
                <v-col cols="auto" class="pl-0">
                  <v-btn
                    icon
                    @click="query.created_by = null"
                    color="red"
                  >
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="3">
              <v-row align="center">
                <v-col class="pr-0">
                  <v-select
                    v-if="regions"
                    v-model="query.region_id"
                    :items="regions"
                    item-text="title"
                    item-value="id"
                    label="Выберите регион"
                    name="region_id"
                    prepend-icon="mdi-earth"
                    single-line
                    hide-details
                  ></v-select>
                </v-col>
                <v-col cols="auto" class="pl-0">
                  <v-btn
                    icon
                    @click="query.region_id = null"
                    color="red"
                  >
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="3">
              <v-row align="center">
                <v-col class="pr-0">
                  <v-select
                    v-if="partnerResource"
                    v-model="query.partner_resource_id"
                    :items="partnerResource"
                    item-text="title"
                    item-value="id"
                    label="Выберите партнёрский ресурс"
                    name="partner_resource_id"
                    prepend-icon="mdi-handshake"
                    single-line
                    hide-details
                  ></v-select>
                </v-col>
                <v-col cols="auto" class="pl-0">
                  <v-btn
                    icon
                    @click="query.partner_resource_id = null"
                    color="red"
                  >
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="3">
              <v-row align="center">
                <v-col class="pr-0">
                  <v-select
                    v-if="types && fieldCheck('type', entity)"
                    v-model="query.type"
                    :items="filtrableTypeList"
                    item-text="name"
                    item-value="value"
                    label="Выберите тип"
                    name="type"
                    prepend-icon="mdi-newspaper"
                    single-line
                    hide-details
                  ></v-select>
                </v-col>
                <v-col cols="auto" class="pl-0">
                  <v-btn
                    icon
                    @click="query.type = null"
                    color="red"
                  >
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-row align="center">
                <v-col cols="auto" class="p-0">
                  <v-icon>mdi-calendar</v-icon>
                  От
                </v-col>
                <v-col class="pr-0">
                  <v-datetime-picker
                    ref="published_at_from"
                    v-model="dateQuery.published_at_from"
                    scrollable
                    label="Дата публикации"
                    clear-text="Очистить"
                  ></v-datetime-picker>
                </v-col>
                <v-col cols="auto" class="pl-0">
                  <v-btn
                    icon
                    @click="dateQuery.published_at_from = null"
                    color="red"
                  >
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </v-col>
                <v-col cols="auto">по</v-col>
                <v-col class="pr-0">
                  <v-datetime-picker
                    ref="published_at_to"
                    v-model="dateQuery.published_at_to"
                    scrollable
                    label="Дата публикации"
                    clear-text="Очистить"
                  ></v-datetime-picker>
                </v-col>
                <v-col cols="auto" class="pl-0">
                  <v-btn
                    icon
                    @click="dateQuery.published_at_to = null"
                    color="red"
                  >
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
            <v-col>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="auto">
              <v-switch
                v-model="query.is_archive"
                label="Показать архивные"
                name="is_archive"
                value="1"
              ></v-switch>
            </v-col>
            <v-col cols="auto">
              <v-switch
                v-model="only_trashed"
                label="Показать удаленные"
                name="only_trashed"
                value="1"
              ></v-switch>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-data-table
                :headers="headers"
                :items="items"
                :options.sync="options"
                :loading="loading"
                :server-items-length="total"
                :search="search"
                :footer-props="{'items-per-page-options':[5, 10, 15, 20, -1]}"
                class="contact-listing-app"
              >
                <template v-slot:item.id="{ item }">
                  <v-chip pill>{{ item.id }}</v-chip>
                </template>
                <template v-slot:item.title="{ item }">
                  <div>
                    {{ item.title }}
                    <div class="text text-danger" v-if="item.occupied_user">Заблокированно: {{ item.occupied_user }}</div>
                  </div>
                </template>
                <template v-slot:item.status="{ item }">
                  <v-chip :color="getStatusColor(item.status)" label>{{ item.human_status }}</v-chip>
                </template>
                <template v-slot:item.actions="{ item }">
                  <!--            <v-btn-->
                  <!--              icon-->
                  <!--              v-if="item.preview_url && canView"-->
                  <!--              :href="item.preview_url"-->
                  <!--              target="_blank"-->
                  <!--            >-->
                  <!--              <v-icon color="primary">-->
                  <!--                mdi-eye-->
                  <!--              </v-icon>-->
                  <!--            </v-btn>-->
                  <a v-if="!item.article_id" :href="addTranslate(item)">
                    <v-icon small color="primary">mdi-google-translate</v-icon>
                  </a>
                  <a v-if="canEdit" :href="editItem(item)">
                    <v-icon small color="primary">
                      mdi-pencil
                    </v-icon>
                  </a>
                  <a :href="`${apiUrl}/api/module/articles/${item.id}/export`">
                    <v-icon small color="primary">
                      mdi-file-download-outline
                    </v-icon>
                  </a>
                  <a :href="item.preview_url" target="_blank">
                    <v-icon small color="primary">mdi-eye</v-icon>
                  </a>
                </template>
                <template v-slot:item.delete="{ item }">
                  <v-icon
                    v-if="item.deleted_at && canDelete"
                    color="green"
                    @click="restoreItem(item)"
                    small
                  >
                    mdi-backup-restore
                  </v-icon>
                  <v-icon
                    v-else-if="canDelete"
                    color="red"
                    @click="deleteItem(item)"
                    small
                  >
                    mdi-delete
                  </v-icon>
                </template>
                <template v-slot:item.progress="{ item }">
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-progress-linear
                        :color="getProgressColor(item.text_complexity)"
                        height="12"
                        v-on="on"
                        v-bind="attrs"
                        :value="item.text_complexity"
                        v-if="item.text_complexity"
                      />
                    </template>
                    <span>
                        {{ item.human_text_complexity }}
                    </span>
                  </v-tooltip>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
  </v-container>
</template>

<script>
import {Article} from '../../../models/article';
import {Region} from "../../../models/region";
import {User} from "../../../models/user";
import {PartnerResource} from "../../../models/partnerResource";
import moment from "moment";
import axiosInstance from '../../../axiosInstance';
import {mapActions, mapGetters} from "vuex";

export default {
  name: 'Index',
  data: () => ({
    page: {
      title: "Медиа"
    },
    breadcrumbs: [
      {
        text: "Админ панель",
        disabled: false,
        to: '/'
      },
      {
        text: "Медиа",
        disabled: true
      }
    ],
    loading: true,
    total: 0,
    options: {
      sortBy: ['id'],
      sortDesc: [true],
      page: 1,
      itemsPerPage: 20,
    },
    search: '',
    only_trashed: 0,
    headers: [
      {text: 'Id', value: 'id'},
      {text: 'Автор', value: 'creator'},
      {text: 'Название', value: 'title'},
      {text: 'Slug', value: 'slug'},
      {text: 'Основная рубрика', value: 'main_taxonomy.title'},
      // {text: 'Сейчас редактирует', value: 'occupied_user'},
      {text: 'Статус', value: 'status'},
      {text: 'Дата публикации', value: 'published_at'},
      {text: 'Дата обновления', value: 'updated_at'},
      {text: 'Оценка читабельности', value: 'progress'},
      {text: '', value: 'actions'},
      {text: '', value: 'delete'},
    ],
    query: {
      created_by: null,
      region_id: null,
      is_archive: '0',
      partner_resource_id: null,
    },
    dateQuery: {
      published_at_from: null,
      published_at_to: null,
    },
    selected_index: [],
    selected_metrics: [],
    items: [],
    regions: [],
    creators: [],
    partnerResource: [],
    types: [
      {name: 'Исследование', value: 0, settingKey: 'TYPE_STUDY'},
      {name: 'Кейс', value: 1, settingKey: 'TYPE_CASE'},
      {name: 'Интервью', value: 2, settingKey: 'TYPE_INTERVIEW'},
      {name: 'Личная история', value: 3, settingKey: 'TYPE_PERSONAL_HISTORY'},
      {name: 'Новость', value: 4, settingKey: 'TYPE_NEWS'},
      {name: 'Инструкция', value: 5, settingKey: 'TYPE_INSTRUCTIONS'},
      {name: 'Экспертное мнение', value: 6, settingKey: 'TYPE_EXPERT_OPINION'},
      {name: 'Заметка', value: 7, settingKey: 'TYPE_NOTE'},
      {name: 'Отзыв', value: 8, settingKey: 'TYPE_FEEDBACK'},
      {name: 'Вопрос&ответ', value: 9, settingKey: 'TYPE_QUESTION_ANSWER'},
      {name: 'Дайджест', value: 10, settingKey: 'TYPE_DIGEST'},
      {name: 'Образовательный модуль', value: 11, settingKey: 'TYPE_EDUCATION'},
      {name: 'Игра', value: 12, settingKey: 'TYPE_GAME'},
      {name: 'Подкаст', value: 13, settingKey: 'TYPE_PODCAST' },
      {name: 'Мероприятие', value: 14, settingKey: 'TYPE_EVENT'},
    ],
    entity: 'Modules\\\\Articles\\\\Entities\\\\Article',
    apiUrl: process.env.VUE_APP_API_URL,

    canEdit: false,
    canDelete: false,
    canView: false,
    canCreate: false,
  }),
  created() {
    this.getPermissions();
    this.get();
    this.getRegions();
    this.getUsers();
    this.getPartnerResources();
    if (Object.keys(this.currentSite).length > 0) {
      this.fetchEntityOptions(this.entity);
    }
  },
  computed: {
    ...mapGetters({
      hasBetaRole: 'auth/hasBetaRole',
      'fieldCheck': 'EntityOptions/checkOption',
      currentSite: 'SiteSwitcher/getSelectedSite',
    }),
    computedCurrentSite() {
      return this.currentSite;
    },
    getExportUrl() {
      const payload = {
        created_by: this.query.created_by,
        is_archive: this.query.is_archive,
        region_id: this.query.region_id,
        partner_resource_id: this.query.partner_resource_id,
        published_at_from: this.dateQuery.published_at_from,
        published_at_to: this.dateQuery.published_at_to,
      };

      return process.env.VUE_APP_API_URL + '/api/module/articles/export?' + (new URLSearchParams(payload).toString());
    },
    filters() {
      const filters = [];

      Object.keys(this.query).map((key) => {
        if (this.query[key] !== undefined && this.query[key] !== null) {
          filters.push({field: key, operator: '=', value: this.query[key]});
        }
      });

      this.dateQuery.published_at_from ? filters.push({
        field: 'published_at',
        operator: '>=',
        value: this.dateQuery.published_at_from
      }) : null;
      this.dateQuery.published_at_to ? filters.push({
        field: 'published_at',
        operator: '<=',
        value: this.dateQuery.published_at_to
      }) : null;
      this.query.partner_resource_id ? null : filters.push({
          field: 'partner_resource_id',
          operator: '=',
          value: null
        });

      return filters;
    },
    filtrableTypeList() {
      return this.types.filter((item) => {
        return this.fieldCheck(item.settingKey, this.entity);
      })
    },
  },
  methods: {
    ...mapActions({
      fetchEntityOptions: "EntityOptions/fetchOptions",
    }),
    addTranslate(item) {
      return this.$router.resolve({name: 'articles-create', params: {article_id: item.id}}).href
    },
    editItem(item) {
      return this.$router.resolve({name: 'articles-edit', params: {id: item.id}}).href
    },
    async deleteItem(item) {
      this.$confirm('Вы уверены?', 'Удаление').then(() => {
        Article.$query().destroy(item.id, 0);
        this.get();
      });
    },
    async restoreItem(item) {
      await Article.$query().restore(item.id);
      await this.get();
    },
    async get() {
      this.loading = true;
      const {sortBy, sortDesc, page, itemsPerPage} = this.options;

      const data = {};
      this.filters ? data.filters = this.filters : null;
      this.search ? data.search = {value: this.search} : null;
      sortBy && sortBy[0] ? data.sort = [{field: sortBy[0], direction: sortDesc[0] ? 'desc' : 'asc'}] : null;

      const onlyTrashed = this.only_trashed ? `&only_trashed=1` : '';

      await axiosInstance.post(`/module/articles/search?limit=${itemsPerPage}&page=${page}${onlyTrashed}`, data)
        .then((response) => {
          this.items = response.data.data;
          this.total = response.data.meta.total;
        });

      this.loading = false;
    },
    async getRegions() {
      try {
        const regions = await Region.$query().get();
        this.regions = regions.map(region => region.$attributes);
      } catch (e) {
        throw e;
      }
    },
    async getUsers() {
      try {
        const creators = await User.$query().get();
        this.creators = creators.map(creator => creator.$attributes);
      } catch (e) {
        throw e;
      }
    },
    async getPartnerResources() {
      try {
        const partnerResources = await PartnerResource.$query().get();
        this.partnerResource = partnerResources.map(creator => creator.$attributes);
      } catch (e) {
        throw e;
      }
    },
    getStatusColor(status) {
      switch (status) {
        case 0:
          return 'red';
        case 1:
          return 'yellow';
        case 2:
          return 'green';
        default:
          return 'grey';
      }
    },
    async getPermissions() {
      this.canCreate = this.can('article.create');
      this.canEdit = this.can('article.edit');
      this.canDelete = this.can('article.delete');
      this.canView = this.can('article.view');
    },
    getProgressColor(val) {
      if (val >= 80) {
        return 'green';
      } else if (val >= 60) {
        return 'lime';
      } else if (val >= 40) {
        return 'yellow';
      } else if (val >= 20) {
        return 'orange';
      }

      return 'red'
    },
  },
  watch: {
    search: {
      handler(newSearch, oldSearch) {
        newSearch != oldSearch ? this.options.page = 1 : '';
        this.get();
      }
    },
    only_trashed: {
      handler() {
        this.get();
      }
    },
    query: {
      handler() {
        this.options.page = 1

        this.get();
      },
      deep: true,
      immediate: true,
    },
    dateQuery: {
      handler() {
        this.options.page = 1

        this.dateQuery.published_at_from = this.dateQuery.published_at_from
          ? moment(this.dateQuery.published_at_from).format('YYYY-MM-DD HH:mm')
          : this.$refs.published_at_from.date = null;
        this.dateQuery.published_at_to = this.dateQuery.published_at_to
          ? moment(this.dateQuery.published_at_to).format('YYYY-MM-DD HH:mm')
          : this.$refs.published_at_to.date = null;
        this.get();
      },
      deep: true,
    },
    options: {
      handler() {
        this.get()
      },
      deep: true,
    },
    'computedCurrentSite': {
      handler(value) {
        if (Object.keys(value).length > 0) {
          this.fetchEntityOptions(this.entity);
        }
      }
    }
  }
};
</script>
