<template>
  <v-container fluid class="down-top-padding" v-if="isLoaded">
    <BaseBreadcrumb :title="page.title" :icon="page.icon" :breadcrumbs="breadcrumbs"></BaseBreadcrumb>
    <v-card>
      <v-card-text class="pa-5">
        <div class="row">
          <v-col>
            <div class="v-card v-sheet theme--light">
              <div class="v-card__text pa-5">
                <div class="d-flex align-center">
                  <v-btn elevation="0" fab rounded readonly="true" color="primary">
                    <v-icon>
                      mdi-account
                    </v-icon>
                  </v-btn>
                  <div class="ml-2 mr-1">
                    <h2 class="headline font-weight-light card-title">
                      {{ statistic.users_info.total_students }}
                    </h2>
                    <h5 class="subtitle-2 font-weight-regular">Всего студентов</h5>
                  </div>
                </div>
              </div>
            </div>
          </v-col>
          <v-col>
            <div class="v-card v-sheet theme--light">
              <div class="v-card__text pa-5">
                <div class="d-flex align-center">
                  <v-btn elevation="0" fab rounded readonly="true" color="primary">
                    <v-icon>
                      mdi-currency-usd
                    </v-icon>
                  </v-btn>
                  <div class="ml-2 mr-1">
                    <h2 class="headline font-weight-light card-title">
                      {{ statistic.users_info.total_free_registrations }}
                    </h2>
                    <h5 class="subtitle-2 font-weight-regular">Зарегистрировано на бесплатные</h5>
                  </div>
                </div>
              </div>
            </div>
          </v-col>
          <v-col>
            <div class="v-card v-sheet theme--light">
              <div class="v-card__text pa-5">
                <div class="d-flex align-center">
                  <v-btn elevation="0" fab rounded readonly="true" color="primary">
                    <v-icon>
                      mdi-credit-card-outline
                    </v-icon>
                  </v-btn>
                  <div class="ml-2 mr-1">
                    <h2 class="headline font-weight-light card-title">{{
                        statistic.users_info.total_payment_registrations
                      }}</h2>
                    <h5 class="subtitle-2 font-weight-regular">Зарегистрировано на платные</h5>
                  </div>
                </div>
              </div>
            </div>
          </v-col>
          <v-col>
            <div class="v-card v-sheet theme--light">
              <div class="v-card__text pa-5">
                <div class="d-flex align-center">
                  <v-btn elevation="0" fab rounded readonly="true" color="primary">
                    <v-icon>
                      mdi-percent
                    </v-icon>
                  </v-btn>
                  <div class="ml-2 mr-1">
                    <h2 class="headline font-weight-light card-title">60%</h2>
                    <h5 class="subtitle-2 font-weight-regular">Доходимость</h5>
                  </div>
                </div>
              </div>
            </div>
          </v-col>
        </div>
        <div class="row">
          <v-col md="9">
            <apexchart height="325" type="line" :options="chartOptions" :series="series"/>
          </v-col>
          <v-col>
            <div class="row">
              <v-col>
                <div class="v-card v-sheet theme--light">
                  <div class="v-card__text pa-5">
                    <div class="d-flex align-center">
                      <v-btn elevation="0" fab rounded readonly="true" color="primary">
                        <v-icon>
                          mdi-credit-card-settings-outline
                        </v-icon>
                      </v-btn>
                      <div class="ml-2 mr-1">
                        <h2 class="headline font-weight-light card-title">
                          {{ statistic.users_info.income }}
                        </h2>
                        <h5 class="subtitle-2 font-weight-regular">Доход</h5>
                      </div>
                    </div>
                  </div>
                </div>
              </v-col>
            </div>
            <div class="row">
              <v-col>
                <div class="v-card v-sheet theme--light">
                  <div class="v-card__text pa-5">
                    <div class="d-flex align-center">
                      <v-btn elevation="0" fab rounded readonly="true" color="primary">
                        <v-icon>
                          mdi-checkbook
                        </v-icon>
                      </v-btn>
                      <div class="ml-2 mr-1">
                        <h2 class="headline font-weight-light card-title">
                          {{ statistic.users_info.avg_income }}
                        </h2>
                        <h5 class="subtitle-2 font-weight-regular">Средний чек</h5>
                      </div>
                    </div>
                  </div>
                </div>
              </v-col>
            </div>
          </v-col>
        </div>
        <div class="row">
          <div class="col">
            <div class="row">
              <v-col>
                <vue-funnel-graph
                  style="background: #393862"
                  :labels="funnelGraphPayment.labels"
                  :values="paymentFunnelData"
                  :direction="funnelGraphPayment.direction"
                  :width="funnelGraphPayment.width"
                  :height="funnelGraphPayment.height"
                  :animated="true"
                  :display-percentage="true"
                />
              </v-col>
              <v-col>
                <div class="row">
                  <v-col>
                    <div class="v-card v-sheet theme--light">
                      <div class="v-card__text pa-5">
                        <div class="d-flex align-center">
                          <v-btn elevation="0" fab rounded readonly="true" color="primary">
                            <v-icon>
                              mdi-credit-card-settings-outline
                            </v-icon>
                          </v-btn>
                          <div class="ml-2 mr-1">
                            <h2 class="headline font-weight-light card-title">
                              {{ statistic.users_info.income }}
                            </h2>
                            <h5 class="subtitle-2 font-weight-regular">Доход</h5>
                          </div>
                        </div>
                      </div>
                    </div>
                  </v-col>
                </div>
                <div class="row">
                  <v-col>
                    <div class="v-card v-sheet theme--light">
                      <div class="v-card__text pa-5">
                        <div class="d-flex align-center">
                          <v-btn elevation="0" fab rounded readonly="true" color="primary">
                            <v-icon>
                              mdi-checkbook
                            </v-icon>
                          </v-btn>
                          <div class="ml-2 mr-1">
                            <h2 class="headline font-weight-light card-title">
                              {{ statistic.users_info.avg_income }}
                            </h2>
                            <h5 class="subtitle-2 font-weight-regular">Средний чек</h5>
                          </div>
                        </div>
                      </div>
                    </div>
                  </v-col>
                </div>
              </v-col>
            </div>
          </div>
          <div class="col">
            <div class="row">
              <v-col>
                <vue-funnel-graph
                  style="background: #393862"
                  :values="freeFunnelData"
                  :labels="funnelGraphFree.labels"
                  :direction="funnelGraphFree.direction"
                  :width="funnelGraphFree.width"
                  :height="funnelGraphFree.height"
                  :animated="true"
                  :display-percentage="true"
                />
              </v-col>
              <v-col>
                <div class="row">
                  <v-col>
                    <div class="v-card v-sheet theme--light">
                      <div class="v-card__text pa-5">
                        <div class="d-flex align-center">
                          <v-btn elevation="0" fab rounded readonly="true" color="primary">
                            <v-icon>
                              mdi-credit-card-settings-outline
                            </v-icon>
                          </v-btn>
                          <div class="ml-2 mr-1">
                            <h2 class="headline font-weight-light card-title">
                              {{ statistic.users_info.income }}
                            </h2>
                            <h5 class="subtitle-2 font-weight-regular">Доход</h5>
                          </div>
                        </div>
                      </div>
                    </div>
                  </v-col>
                </div>
                <div class="row">
                  <v-col>
                    <div class="v-card v-sheet theme--light">
                      <div class="v-card__text pa-5">
                        <div class="d-flex align-center">
                          <v-btn elevation="0" fab rounded readonly="true" color="primary">
                            <v-icon>
                              mdi-checkbook
                            </v-icon>
                          </v-btn>
                          <div class="ml-2 mr-1">
                            <h2 class="headline font-weight-light card-title">
                              {{ statistic.users_info.avg_income }}
                            </h2>
                            <h5 class="subtitle-2 font-weight-regular">Средний чек</h5>
                          </div>
                        </div>
                      </div>
                    </div>
                  </v-col>
                </div>
              </v-col>
            </div>
          </div>
        </div>
        <div class="row" v-if="statistic.courses_statistics.length > 0">
          <v-col>
            <v-row>
              <h1>Курсы</h1>
            </v-row>
            <v-row no-gutters v-for="(course, index) in statistic.courses_statistics" :key="index" class="mb-4">
              <v-col md="4">
                <v-card class="mx-auto" outlined>
                  <v-list-item three-line>
                    <v-list-item-content>
                      <div class="text-overline mb-4">
                        {{ course.general_information.title }}
                      </div>
                    </v-list-item-content>
                  </v-list-item>
                </v-card>
              </v-col>
              <v-col md="3" class="ml-5 mr-5">
                <vue-funnel-graph
                  style="background: #393862"
                  :labels="funnelGraphCourse.labels"
                  :values="course.chartValues"
                  :direction="funnelGraphCourse.direction"
                  :width="funnelGraphCourse.with"
                  :height="funnelGraphCourse.height"
                  :animated="true"
                  :display-percentage="true"
                />
              </v-col>
              <v-col>
                <v-row>
                  Доход: {{ course.income }}
                </v-row>
                <v-row>
                  Среднее число дней прохождения: {{ course.avg_time }}
                </v-row>
                <v-row>
                  Видео на курсе: {{ course.course_total_videos }}
                </v-row>
              </v-col>
              <v-divider vertical/>
            </v-row>
          </v-col>
        </div>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import axiosInstance from "../../../axiosInstance";
import {VueFunnelGraph} from 'vue-funnel-graph-js'

export default {
  name: "Index",
  components: {VueFunnelGraph},
  data: () => ({
    statistic: {},
    type: 'Column2D',
    width: '500',
    height: '300',
    dataFormat: 'json',
    dataSource: {
      chart: {
        caption: 'Vue FusionCharts Sample',
        theme: 'fint'
      },
      data: [{value: 1.9}, {value: 2.3}, {value: 2.1}]
    },
    displayValue: 'nothing',
    funnelGraphPayment: {
      labels: ['Регистраций на платные', 'Поступило оплат', 'Повторная покупка'],
      values: [300, 500, 700],
      height: 500,
      width: 300,
      direction: 'vertical'
    },
    funnelGraphFree: {
      labels: ['Регистраций на бесплатные', 'Регистраций на платные', 'Поступило оплат', 'Активировали промокод'],
      values: [300, 500, 700],
      height: 500,
      width: 300,
      direction: 'vertical'
    },
    funnelGraphCourse: {
      labels: ['Посещение главной страницы курса', 'Окончило курс'],
      height: 250,
      with: 150,
      direction: 'vertical',
    },
    page: {
      title: "Статистика"
    },
    breadcrumbs: [
      {
        text: "Админ панель",
        disabled: false,
        to: '/'
      },
      {
        text: "Курсы",
        disabled: false,
        to: '/courses/'
      },
      {
        text: "Статистика",
        disabled: true,
        to: '/statistics'
      },
    ],
  }),
  computed: {
    chartOptions() {
      return {
        chart: {
          height: 350,
          type: 'line',
          zoom: {
            enabled: false
          }
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          curve: 'straight'
        },
        title: {
          text: 'Регистраций',
          align: 'center'
        },
        grid: {
          row: {
            colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
            opacity: 0.5
          },
        },
        xaxis: {
          categories: this.statistic.users_info.graphic_registrations.keys,
        }
      }
    },
    series() {
      return [
        {
          data: this.statistic.users_info.graphic_registrations.data,
        }
      ]
    },
    isLoaded() {
      return Object.keys(this.statistic).length > 0;
    },
    paymentFunnelData() {
      return [
        this.statistic.payment_funnel_charts.total_orders,
        this.statistic.payment_funnel_charts.total_payment_orders,
        this.statistic.payment_funnel_charts.repeat_purchased,
      ]
    },
    freeFunnelData() {
      return [
        this.statistic.free_funnel_charts.total_free_registrations,
        this.statistic.free_funnel_charts.total_payment_registrations,
        this.statistic.free_funnel_charts.total_payment_orders,
        this.statistic.free_funnel_charts.used_promocodes,
      ]
    }
  },
  async created() {
    await axiosInstance.get('statistic').then((response) => {
      this.statistic = response.data;
    })
  }
}
</script>

<style scoped>

</style>
