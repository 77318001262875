import { Model } from "@tailflow/laravel-orion/lib/model";

export class FAQ extends Model<{
    question: string,
    answer: string,
    published_at: string,
    published: number,
    user_id: number,
    created_at: string,
    updated_at: string,
}> {

    $resource(): string {
        return "module/faq/admin/faq";
    }
}
