import axiosInstance from "../../../axiosInstance";
import {User} from "../../../models/user";

export default {
  namespaced: true,
  state: {
    fetchedListing: [],
    listingTotalItems: 0,
    fetchedDetail: {},
    isSaveButtonDisabled: false,
    isListingLoad: false,
  },
  getters: {
    getFetchedListing: state => state.fetchedListing,
    getFetchedDetail: state => state.fetchedDetail,
    getListingTotalItems: state => state.listingTotalItems,
    isListingLoad: state => state.isListingLoad,
    isSaveButtonDisabled: state => state.isSaveButtonDisabled,
  },
  mutations: {
    setFetchedListing(state, listing) {
      state.fetchedListing = listing;
    },
    setFetchedDetail(state, detail) {
      state.fetchedDetail = detail;
    },
    setListingTotalItems(state, totalItems) {
      state.listingTotalItems = totalItems;
    },
    setIsSaveButtonDisabled(state, value) {
      state.isSaveButtonDisabled = value;
    },
    setIsListingLoad(state, value) {
      state.isListingLoad = value;
    },
  },
  actions: {
    async fetchListing({commit}, {options, only_trashed, search}) {
      commit('setIsListingLoad', true);

      const {sortBy, sortDesc, page, itemsPerPage} = options;
      const onlyTrashed = only_trashed ? `&only_trashed=1` : '';
      let data = {};
      search ? data.search = {value: search} : null;
      sortBy && sortBy[0] ? data.sort = [{field: sortBy[0], direction: sortDesc[0] ? 'desc' : 'asc'}] : null;

      await axiosInstance.post(`/users/search?limit=${itemsPerPage}&page=${page}${onlyTrashed}`, data).then((response) => {
        commit('setFetchedListing', response.data.data);
        commit('setListingTotalItems', response.data.meta.total);
      });

      commit('setIsListingLoad', false);
    },
    async destroy({dispatch}, id) {
      await User.$query().destroy(id).then(() => {
        dispatch('snackbar/showSuccessfullyDelete', null, {root: true});
      }).catch(() => {
        dispatch('snackbar/showDeleteError', null, {root: true});
      });
    },
    async fetchDetail({ commit }, id) {
      await User.$query().find(id).then(async (response) => {
        const user = response.$attributes;
        await response.roles().get().then((response) => {
          user.roles = response.map(el => el.$attributes.id);
        });
        commit('setFetchedDetail', user);
      })
    },
    async update({ commit, dispatch }, {roles, ...user}) {
      commit('setIsSaveButtonDisabled', true);

      await User.$query().update(user.id, user).then(async (response) => {
          await response.roles().sync(roles).then(() => {
            dispatch('snackbar/showSuccessfullySaved', null, {root: true});
          }).catch(() => {
            dispatch('snackbar/showSaveError', null, {root: true});
          });
        }).catch(() => {
        dispatch('snackbar/showSaveError', null, {root: true});
      });

      commit('setIsSaveButtonDisabled', true);
    },
    async store({ commit, dispatch }, { roles, ...user }) {
      commit('setIsSaveButtonDisabled', true);

      await User.$query().store(user).then(async (response) => {
        await response.roles().sync(roles).then(() => {
          dispatch('snackbar/showSuccessfullySaved', null, {root: true});
        }).catch(() => {
          dispatch('snackbar/showSaveError', null, {root: true});
        });
      }).catch(() => {
        dispatch('snackbar/showSaveError', null, {root: true});
      });

      commit('setIsSaveButtonDisabled', true);
    }
  }

}
