<template>
  <v-container fluid class="down-top-padding" v-if="fetchDetail">
    <BaseBreadcrumb :title="page.title" :icon="page.icon" :breadcrumbs="breadcrumbs"></BaseBreadcrumb>
    <v-card>
      <v-card-text class="pa-5">
        <v-tabs
          v-model="tab"
          background-color="transparent"
          grow
          class="mb-5"
        >
          <v-tab>
            Основная информация
          </v-tab>
          <v-tab>
            Курсы
          </v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab">
          <v-tab-item>
            <UserForm
              :user="fetchedUser"
              @onSubmit="submit"
            />
          </v-tab-item>
          <v-tab-item>
            <v-btn
              class="ml-2"
              color="primary"
              @click="coursesDialog = true"
            >
              Добавить
            </v-btn>
            <v-data-table
              :headers="headers"
              :items="userCourses"
            >
              <template v-slot:item.actions="{ item }">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      icon
                      v-if="item.preview_url"
                      :href="item.preview_url"
                      target="_blank"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon color="primary">
                        mdi-eye
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>Предпросмотр курса</span>
                </v-tooltip>
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      icon
                      v-if="item.admin_attach_url"
                      v-bind="attrs"
                      v-on="on"
                      @click="attachItem(item)"
                    >
                      <v-icon color="primary">
                        mdi-eye-plus
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>Предпросмотр купленного курса</span>
                </v-tooltip>
                <v-btn
                  :href="editItem(item)"
                  icon
                >
                  <v-icon color="primary">
                    mdi-pencil
                  </v-icon>
                </v-btn>
                <v-icon
                  color="red"
                  @click="deleteItem(item)"
                >
                  mdi-delete
                </v-icon>
              </template>
            </v-data-table>
          </v-tab-item>
        </v-tabs-items>

        <v-dialog
          v-model="coursesDialog"
          max-width="600px"
        >
          <v-card>
            <v-container>
              <v-row>
                <v-col>
                  <h3>Добавление курса</h3>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-autocomplete
                    v-if="courses"
                    v-model="selectCourseId"
                    :items="courses"
                    item-text="title"
                    item-value="id"
                    label="Выберите курс"
                    name="course"
                    required
                  ></v-autocomplete>
                </v-col>
                <v-col>
                  <v-btn
                    color="primary"
                    @click="submitAddCourse"
                  >
                    Ок
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-card>
        </v-dialog>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import UserForm from "../../../components/users/UserForm";
import {mapActions, mapGetters} from "vuex";
import axiosInstance from "../../../axiosInstance";
import {Course} from "../../../models/course";

export default {
  components: {
    UserForm
  },
  name: "Edit",
  created() {
    this.fetchDetail(this.$route.params.id);
  },
  mounted() {
    this.getCourses();
    this.getUserCourses();
  },
  data() {
    return {
      headers: [
        {text: 'Id', value: 'id'},
        {text: 'Название', value: 'title'},
        {text: 'Slug', value: 'slug'},
        {text: 'Дата создания', value: 'created_at'},
        {text: 'Дата обновления', value: 'updated_at'},
        {text: 'Дата поднятия', value: 'pickup_at'},
        {text: '', value: 'actions'},
      ],
      userCourses: [],
      courses: [],
      coursesDialog: false,
      selectCourseId: null,
      tab: 0,
      page: {
        title: "Редактирование пользователя"
      },
      breadcrumbs: [
        {
          text: "Админ панель",
          disabled: false,
          to: '/'
        },
        {
          text: "Пользователи",
          disabled: true,
          to: '/users'
        },
        {
          text: "Редактирование",
          disabled: true,
          to: '/users/edit'
        },
      ]
    }
  },
  computed: {
    ...mapGetters({
      fetchedUser: 'Users/getFetchedDetail',
    })
  },
  methods: {
    ...mapActions({
      fetchDetail: 'Users/fetchDetail',
      update: 'Users/update'
    }),
    async submit(form) {
      await this.update(form).then(() => {
        this.$router.push({name: 'users'});
      });
    },
    async getUserCourses() {
      this.userCourses = (await axiosInstance.get(`/auth/user/${this.$route.params.id}/courses`)).data.data
    },
    async getCourses() {
      const courses = await Course.$query().get(10000);
      this.courses = courses.map((c) => c.$attributes);
    },
    editItem(item) {
      return this.$router.resolve({name: 'courses-edit', params: {id: item.id}}).href
    },
    deleteItem(item) {
      axiosInstance.get(`/auth/user/${this.fetchedUser.id}/course/${item.id}/detach`)
        .then(() => {
          this.getUserCourses();
        })
    },
    attachItem(item) {
      axiosInstance.get(`/auth/user/course/${item.id}/attach`)
        .then((response) => {
          window.open(response.data);
        });
    },
    submitAddCourse() {
      if (this.selectCourseId) {
        axiosInstance.get(`/auth/user/${this.fetchedUser.id}/course/${this.selectCourseId}/attach`)
          .then(() => {
            this.selectCourseId = null;
            this.getUserCourses();
            this.coursesDialog = false;
          })
      }
    },
  },
  watch: {
    'fetchedUser.full_name': {
      handler(newTitle) {
        this.page.title +=  ` "${newTitle}"`;
      }
    },
  }
}
</script>
