<template>
  <v-container>
    <v-row>
      <v-col>
        <h1>Промокоды</h1>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-btn color="primary" to="/promocodes/create">
          Создать промокод
        </v-btn>
      </v-col>
    </v-row>
    <v-card
      class="mt-3 mb-3"
      color="#e6e6e6"
    >
      <v-row class="ml-0 d-flex align-items-center">
        <v-col cols="5">
          <v-text-field
            v-model="search"
            label="Поиск..."
            name="title"
          >
            <v-btn
              slot="append"
              icon
              @click="search = null"
              color="red"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-text-field>
        </v-col>
        <v-col cols="3">
          <v-select
            v-if="types"
            v-model="query.type"
            :items="types"
            label="Тип"
            name="type"
          ></v-select>
        </v-col>
        <v-col cols="3">
          <v-checkbox
            v-model="only_trashed"
            label="Показать удаленные"
            name="only_trashed"
            value="1"
          ></v-checkbox>
        </v-col>
      </v-row>
      <v-row class="ml-0 mr-0 d-flex align-items-center">
        <v-col cols="3">
          <v-text-field
            v-model="dateQueryFrom.valid_from"
            label="Дата начала действия с"
            name="valid_from"
            type="date"
          />
        </v-col>
        <v-col cols="3">
          <v-text-field
            v-model="dateQueryTo.valid_from"
            label="по"
            name="valid_from"
            type="date"
          />
        </v-col>
        <v-col cols="3">
          <v-text-field
            v-model="dateQueryFrom.valid_to"
            label="Дата окончания действия с"
            name="valid_from"
            type="date"
          />
        </v-col>
        <v-col cols="3">
          <v-text-field
            v-model="dateQueryTo.valid_to"
            label="по"
            name="valid_from"
            type="date"
          />
        </v-col>
      </v-row>
    </v-card>
    <v-row>
      <v-col>
        <v-data-table
          :headers="headers"
          :items="items"
          :options.sync="options"
          :loading="loading"
          :server-items-length="total"
          :search="search"
        >
          <template v-slot:item.id="{ item }">
            {{ item.id }}
            <v-badge
              v-if="item.status"
              color="success"
              content="active"
              class="ml-1"
            />
            <v-badge
              v-else
              color="secondary"
              content="inactive"
              class="ml-1"
            />
          </template>
          <template v-slot:item.actions="{ item }">
            <v-btn
              v-if="!item.deleted_at"
              :href="editItem(item)"
              icon
              class="text-decoration-none"
            >
              <v-icon color="primary">
                mdi-pencil
              </v-icon>
            </v-btn>
            <v-btn
              v-if="item.deleted_at"
              @click="restoreItem(item)"
              icon
            >
              <v-icon color="green">
                mdi-undo
              </v-icon>
            </v-btn>
            <v-btn
              v-else
              @click="deleteItem(item)"
              icon
            >
              <v-icon color="red">
                mdi-delete
              </v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axiosInstance from '../../../axiosInstance';
import {Promocode} from "../../../models/promocode";

export default {
  name: 'Index',
  data: () => ({
    loading: true,
    total: 0,
    options: {
      sortBy: ['id'],
      sortDesc: [true],
    },
    query: {
      type: null,
    },
    dateQueryFrom: {
      valid_from: null,
      valid_to: null,
    },
    dateQueryTo: {
      valid_from: null,
      valid_to: null,
    },
    search: '',
    only_trashed: 0,
    headers: [
      {text: 'Id', value: 'id'},
      {text: 'Название', value: 'title'},
      {text: 'Начало действия', value: 'valid_from'},
      {text: 'Окончание действия', value: 'valid_to'},
      {text: 'Промокод', value: 'code'},
      {text: 'Лимит', value: 'activation_limit'},
      {text: 'Активаций', value: 'activations_count'},
      {text: 'Тип', value: 'type_title', sortable: false},
      {text: '', value: 'actions'},
    ],
    types: [
      {text: 'Все', value: null},
      {text: 'Скидка на сумму', value: 0},
      {text: 'Скидка на %', value: 1},
      {text: 'Доступ', value: 2},
    ],
    items: [],
  }),
  created() {
    this.get();
  },
  computed: {
    filters() {
      const filters = [];

      Object.keys(this.query).map((key) => {
        if (this.query[key]) {
          filters.push({field: key, operator: '=', value: this.query[key]});
        }
      });

      Object.keys(this.dateQueryFrom).map((key) => {
        if (this.dateQueryFrom[key]) {
          filters.push({field: key, operator: '>=', value: this.dateQueryFrom[key]});
        }
      });

      Object.keys(this.dateQueryTo).map((key) => {
        if (this.dateQueryTo[key]) {
          filters.push({field: key, operator: '<=', value: this.dateQueryTo[key]});
        }
      });

      return filters;
    },
  },
  methods: {
    editItem(item) {
      return this.$router.resolve({name: 'promocodes-edit', params: {id: item.id}}).href
    },
    async deleteItem(item) {
      this.$confirm('Вы уверены?', 'Удаление').then(() => {
        Promocode.$query().destroy(item.id, 0);
        this.get();
      });
    },
    async restoreItem(item) {
      await Promocode.$query().restore(item.id);
      await this.get();
    },
    async get() {
      this.loading = true;
      const {sortBy, sortDesc, page, itemsPerPage} = this.options;

      const data = {};
      this.search ? data.search = {value: this.search} : null;
      this.filters ? data.filters = this.filters : null;
      sortBy && sortBy[0] ? data.sort = [{field: sortBy[0], direction: sortDesc[0] ? 'desc' : 'asc'}] : null;

      const onlyTrashed = this.only_trashed ? `&only_trashed=1` : '';

      await axiosInstance.post(`/promocodes/search?limit=${itemsPerPage}&page=${page}${onlyTrashed}`, data)
        .then((response) => {
          this.items = response.data.data;
          this.total = response.data.meta.total;
        });

      this.loading = false;
    },
  },
  watch: {
    search: {
      handler() {
        this.get();
      }
    },
    only_trashed: {
      handler() {
        this.get();
      }
    },
    options: {
      handler() {
        this.get()
      },
      deep: true,
    },
    query: {
      handler() {
        this.get()
      },
      deep: true,
    },
    dateQueryFrom: {
      handler() {
        this.get()
      },
      deep: true,
    },
    dateQueryTo: {
      handler() {
        this.get()
      },
      deep: true,
    },
  }
};
</script>
