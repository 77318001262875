<template>
  <div>
    <v-row>
      <v-col>
        <h3>Сортировка Уроков</h3>
        <v-card style="max-height: 400px; overflow-y: scroll">
          <v-list>
            <v-list-item-group>
              <draggable v-model="selectedItems">
                <v-list-item
                  v-for="(item, i) in selectedItems"
                  :key="i"
                >
                  <v-list-item-icon>
                    <v-icon>mdi-arrow-all</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>
                      Порядковый номер:{{ i + 1}}
                      <br>
                      Урок:
                      <b>{{ item.title }}</b>
                    </v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-row>
                     <!--<v-btn
                        icon
                        v-if="item.preview_url"
                        :href="item.preview_url"
                        target="_blank"
                      >
                        <v-icon color="primary">
                          mdi-eye
                        </v-icon>
                      </v-btn>-->
                      <v-btn
                        @click="attachItem(item)"
                        icon
                        color="primary"
                      >
                        <v-icon>
                          mdi-eye-plus
                        </v-icon>
                      </v-btn>
                      <v-btn
                        :href="editItem(item)"
                        icon
                      >
                        <v-icon color="primary">
                          mdi-pencil
                        </v-icon>
                      </v-btn>
                      <v-btn
                        @click="deleteItem(item)"
                        icon
                      >
                        <v-icon color="red">
                          mdi-delete
                        </v-icon>
                      </v-btn>
                    </v-row>
                  </v-list-item-action>
                </v-list-item>
              </draggable>
            </v-list-item-group>
          </v-list>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-flex>
          <v-btn
            class="mr-4"
            color="primary"
            @click="submit"
          >
            Сохранить
          </v-btn>
          <v-btn
            class="mr-4"
            color="primary"
            :to="`/course/${course.id}/lessons/create`"
          >
            Новый урок
          </v-btn>
        </v-flex>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import draggable from "vuedraggable";
import {Lesson} from "../../models/lesson";
import {mapActions} from "vuex";
import axiosInstance from "../../axiosInstance";

export default {
  name: "CourseLessonsForm",
  components: {
    draggable,
  },
  props: {
    course: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      selectedItems: [],
    }
  },
  methods: {
    ...mapActions({
      onSuccess: 'snackbar/showSuccessfullyDelete',
      onError: 'snackbar/showDeleteError',
    }),
    submit() {
      const ids = {};

      this.selectedItems.map((item, index) => {
        ids[item.id.toString()] = {'position': `${index + 1}`}
      })

      this.$emit('onSubmit', ids);
    },
    attachItem(item) {
      axiosInstance.get(`/auth/user/course/${this.course.id}/attach`)
        .then((response) => {
          window.open(response.data + `/${item.slug}?preview_mode=true`);
        });
    },
    editItem(item) {
      return this.$router.resolve({name: 'lessons-edit', params: {id: item.id, course_id: this.course.id}}).href
    },
    deleteItem(item) {
      Lesson.$query().destroy(item.id).then(() => {
        this.onSuccess();
        this.$emit('reloadEntity');
      }).catch(() => {
        this.onError();
      });
    },
  },
  watch: {
    course: {
      handler(value) {
        if (value.lessons && value.lessons.length > 0) {
          this.selectedItems = value.lessons;
        }
      },
      deep: true,
      immediate: true,
    }
  }
}
</script>
