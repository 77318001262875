<template>
  <v-container>
    <v-card v-if="Object.keys(statistic).length > 0">
      <v-card-title>
        <v-row align="center">
          <v-col cols="3">
            <h1>Паспорт региона</h1>
          </v-col>
          <v-col cols="5">
            <v-autocomplete :items="regionalList" v-model="selectedRegions" multiple item-value="slug" item-text="title" chips deletable-chips>
              <template v-slot:prepend-item>
                <v-list-item ripple @mousedown.prevent @click="selectedRegionsToggle">
                  <v-list-item-action>
                    <v-icon :color="selectedRegions.length > 0 ? 'primary' : ''">
                      {{ autoCompleteIcon }}
                    </v-icon>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title>
                      Выбрать всё
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-divider class="mt-2"/>
              </template>
              <template v-slot:selection="{ item, index }">
                <v-chip v-if="index < 3">
                  <span>{{ item.title }}</span>
                </v-chip>
                <span
                  v-if="index === 3"
                  class="grey--text text-caption"
                >
                  (+{{ selectedRegions.length - 3 }} других)
               </span>
              </template>
            </v-autocomplete>
          </v-col>
          <v-col>
            <v-dialog
              ref="dialog"
              v-model="dateDialog"
              :return-value.sync="date"
              persistent
              width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="date"
                  label="Выберите промежуток"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                />
              </template>
              <v-date-picker
                v-model="date"
                range
                scrollable
                locale="ru-RU"
              >
                <v-spacer></v-spacer>
                <v-btn
                  text
                  color="primary"
                  @click="dateDialog = false"
                >
                  Закрыть
                </v-btn>
                <v-btn
                  text
                  color="primary"
                  @click="$refs.dialog.save(date)"
                >
                  Oк
                </v-btn>
              </v-date-picker>
            </v-dialog>
          </v-col>
        </v-row>
      </v-card-title>
    </v-card>
    <v-card class="mt-5" v-if="statistic.general">
      <v-card-title>
        <h2>Основные показатели</h2>
      </v-card-title>
      <v-card-text>
        <div>
          <v-row>
            <v-col offset="1">
              <div class="text text-subtitle-1">Пользователи</div>
              <div class="h2">{{ statistic.general.users }}</div>
            </v-col>
            <v-col>
              <div class="text text-subtitle-1">Просмотры страниц</div>
              <div class="h2">{{ statistic.general.pageviews }}</div>
            </v-col>
            <v-col>
              <div class="text text-subtitle-1">Сред. длительность просмотра страницы</div>
              <div class="h2">{{ statistic.general.avrDuration }}</div>
            </v-col>
            <v-col>
              <div class="text text-subtitle-1">Показатель отказов</div>
              <div class="h2">{{ statistic.general.bouncerate }}%</div>
            </v-col>
          </v-row>
        </div>
      </v-card-text>
    </v-card>
    <v-card class="mt-5" v-show="statistic.user_graphic">
      <v-card-title>
        Пользователи
      </v-card-title>
      <v-card-text>
        <div>
          <apexchart ref="firstChar" type="line" height="320" :options="userChartOptions" :series="userChartSeries"/>
          <apexchart ref="secondChar" type="line" height="320" :options="userPreviousChartOptions" :series="userPreviousChartSeries"/>
        </div>
      </v-card-text>
    </v-card>
    <v-card class="mt-2" v-if="statistic.regions && isMounted">
      <v-card-title>
        Пользователи
      </v-card-title>
      <v-card-text>
        <apexchart type="bar" height="400" :series="statistic.regions.series" :options="regionsGraphic"/>
      </v-card-text>
    </v-card>
    <v-card class="mt-2" v-if="statistic.appeals">
      <v-card-title>
        Обращение к контактам
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col>
            <v-data-table
              :headers="appealsEmailHeaders"
              :items="statistic.appeals.email"
              :items-per-page="3"/>
          </v-col>
          <v-col>
            <v-data-table
              :headers="appealPhoneHeaders"
              :items="statistic.appeals.phone"
              :items-per-page="3"/>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-card class="mt-2" v-if="statistic.documentClick">
      <v-card-title>
        Клики на документы
      </v-card-title>
      <v-card-text>
        <v-row>
            <v-data-table
              :headers="documentClicksHeaders"
              :items="statistic.documentClick"
              :items-per-page="3"/>
        </v-row>
      </v-card-text>
    </v-card>
    <v-card class="mt-2" v-if="statistic.externalClicks">
      <v-card-title>
        Клики на внешние ссылки
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-data-table
            :headers="documentClicksHeaders"
            :items="statistic.externalClicks"
            :items-per-page="3"/>
        </v-row>
      </v-card-text>
    </v-card>
    <v-card class="mt-5" v-if="statistic.requestsStaff">
      <v-card-title>
        Запросы о кадрах
      </v-card-title>
      <v-card-title>
        <v-row>
          <v-col offset="1">
            <div class="text text-subtitle-1">Запросы о кадрах</div>
            <div class="h2">{{ statistic.requestsStaff }}</div>
          </v-col>
        </v-row>
      </v-card-title>
    </v-card>
  </v-container>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import moment from "moment";

export default {
  name: "Regional",
  data: () => ({
    dateDialog: false,
    date: [],
    isMounted: false,
    appealsEmailHeaders: [
      {text: 'Текст ссылки', value: 'linkText'},
      {text: 'Email', value: 'email'},
      {text: 'Всего событий', value: 'events'},
    ],
    appealPhoneHeaders: [
      {text: 'Текст ссылки', value: 'linkText'},
      {text: 'Телефон', value: 'telefon'},
      {text: 'Всего событий', value: 'events'},
    ],
    documentClicksHeaders: [
      { text: 'Текст ссылки', value: 'linkText' },
      { text: 'URL', value: 'URL' },
      { text: 'Всего событий', value: 'events' },
    ],
    externalClicksHeaders: [
      { text: 'Текст ссылки', value: 'linkText' },
      { text: 'URL', value: 'URL' },
      { text: 'Всего событий', value: 'events' },
    ],
    selectedRegions: [],
  }),
  async created() {
    const currentDate = new Date();
    const currentMonth = currentDate.getMonth() < 10 ? `0${currentDate.getMonth() + 1}` : currentDate.getMonth() + 1;
    const lastDay = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0)

    this.date = [
      `${lastDay.getFullYear()}-${currentMonth}-01`,
      `${lastDay.getFullYear()}-${currentMonth}-${lastDay.getDate()}`
    ];

    await this.fetch({
      interval: {
        from: this.date[0],
        to: this.date[1],
      },
      list: this.selectedAllRegions ? '' : this.selectedRegions,
    })
  },
  mounted() {
    this.isMounted = true;
  },
  computed: {
    ...mapGetters({
      "statistic": "Statistics/getRegionalStatistic",
    }),
    payload() {
      return {
        interval: {
          from: this.date[0],
          to: this.date[1],
        },
        list: this.selectedAllRegions ? '' : this.selectedRegions
      }
    },
    regionsGraphic() {
      return {
        chart: {
          type: 'bar',
          height: 380
        },
        plotOptions: {
          bar: {
            borderRadius: 4,
            horizontal: true,
          }
        },

        dataLabels: {
          enabled: true,
          formatter: (val) => {
            return val;
          },
        },
        xaxis: this.statistic.regions.xaxis,
      }
    },
    userChartSeries() {
      const series = this.statistic?.user_graphic?.currentIntervalData;
      if (series) {
        return [
          series,
        ]
      } else {
        return [];
      }
    },
    userPreviousChartSeries() {
      const series = this.statistic?.user_graphic?.previousIntervalData;
      if (series) {
        return [
          series,
        ]
      } else {
        return [];
      }
    },
    userChartOptions() {
      return {
        chart: {
          id: 'current',
          // group: 'users',
          type: 'line',
          height: 320,
        },
        title: {
          text: 'Текущий период',
          align: 'left',
        },
        colors: ['#008FFB'],
        xaxis: {

        }
      }
    },
    userPreviousChartOptions() {
      return {
        chart: {
          id: 'previous',
          group: 'users',
          type: 'line',
          height: 320,
        },
        title: {
          text: 'Предыдущий месяц текущего периода',
          align: 'left',
        },
        colors: ['#00FF66FF'],
        xaxis: {

        }
      }
    },
    regionalList() {
      if (this.statistic.regionalList) {
        return this.statistic.regionalList;
      }

      return [];
    },
    selectedAllRegions() {
      return this.regionalList.length === this.selectedRegions.length;
    },
    likesSomeRegion() {
      return this.selectedRegions.length > 0 && !this.selectedAllRegions;
    },
    autoCompleteIcon() {
      if (this.selectedAllRegions) return 'mdi-close-box'
      if (this.likesSomeRegion) return 'mdi-minus-box'
      return 'mdi-checkbox-blank-outline';
    }
  },
  methods: {
    ...mapActions({
      "fetch": "Statistics/fetchRegionalStatistic",
    }),
    selectedRegionsToggle() {
      this.$nextTick(() => {
        if (this.selectedAllRegions) {
          this.selectedRegions = [];
        } else {
          this.selectedRegions = this.regionalList.map((item) => item.slug);
        }
      })
    },
    formatDate(date) {
      const currentMonth = date.getMonth() < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1;
      const currentDay = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate();
      return `${date.getFullYear()}-${currentMonth}-${currentDay}`
    }
  },
  watch: {
    date: {
      async handler() {
        if (this.date.length === 2) {
          const firstDate = new Date(this.date[0]);
          const secondDate = new Date(this.date[1]);

          if (firstDate > secondDate) {
            this.date[0] = this.formatDate(secondDate);
            this.date[1] = this.formatDate(firstDate);
          }

          await this.fetch(this.payload)
        }
      }
    },
    selectedRegions: {
      async handler() {
        await this.fetch({
          interval: {
            from: this.date[0],
            to: this.date[1] || moment().format('YYYY-MM-DD'),
          },
          list: this.selectedAllRegions ? '' : this.selectedRegions,
        })
      }
    },
    statistic: {
      handler() {
        if(this.statistic.user_graphic) {
          this.$refs.firstChar.updateOptions({
            xaxis: {
              categories: this.statistic?.user_graphic?.currentInterval
            }
          })
          this.$refs.secondChar.updateOptions({
            xaxis: {
              categories: this.statistic?.user_graphic?.previousInterval,
            }
          })
        }
      },
      deep: true,
      immediate: true,
    }
  }
}
</script>

<style scoped>

</style>
