<template>
  <v-form>
    <v-row>
      <v-col>
        <v-text-field
          v-model="formData.title"
          label="Название формы"
          required
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-select
          v-model="formData.entity"
          item-text="title"
          item-value="value"
          label="Выберите класс сущность"
          :items="entities"
        />
      </v-col>
    </v-row>
    <v-row v-if="formData.entity">
      <v-col>
        <v-select
          multiple
          v-model="formData.entity_ids"
          label="Выберите сущность"
          item-text="title"
          item-value="value"
          :items="entitiesTitle"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card>
          <v-card-title>Структура</v-card-title>
          <div class="ml-2">
            <h4>Вид отображения</h4>
            <v-radio-group row v-model="options.structure.mode" class="mt-0 pt-0">
              <v-radio label="Деревом" value="tree"/>
              <v-radio label="Кодом" value="code"/>
              <v-radio label="Формой" value="form"/>
              <v-radio label="Текстом" value="text"/>
            </v-radio-group>
          </div>
          <v-card-text class="pl-0 pr-0 pb-0">
            <VJsonEditor
              v-model="formData.structure"
              :plus="true"
              class="mb-2"
              :options="options.structure"
              ref="jsonEditor"
            />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card>
          <v-card-title>Структура кнопок</v-card-title>
          <div class="ml-2">
            <h4>Вид отображения</h4>
            <v-radio-group row v-model="options.buttons.mode" class="mt-0 pt-0">
              <v-radio label="Деревом" value="tree"/>
              <v-radio label="Кодом" value="code"/>
              <v-radio label="Формой" value="form"/>
              <v-radio label="Текстом" value="text"/>
            </v-radio-group>
          </div>
          <v-card-text class="pl-0 pr-0 pb-0">
            <VJsonEditor
              v-model="formData.buttons"
              :plus="true"
              class="mb-2"
              :options="options.buttons"
              ref="jsonEditor"
            />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-btn
      color="primary"
      @click="submit"
    >
      Сохранить
    </v-btn>
  </v-form>
</template>

<script>
import VJsonEditor from 'v-jsoneditor/src/index';
import {mapActions, mapGetters} from "vuex";

export default {
  name: 'BlockForm',
  components: {
    VJsonEditor,
  },
  props: {
    formProp: {
      default: () => ({})
    },
  },
  data: () => ({
    formData: {
      title: null,
      structure: null,
      buttons: [
        {
          "label": "Сохранить",
          "class": "btn-primary w-100"
        }
      ],
      entity: null,
      entity_ids: null,
    },
    options: {
      structure: {
        mode: "tree",
      },
      buttons: {
        mode: "tree",
      }
    },
    mode: {
      structure: {
        mode: "tree",
      },
      buttons: {
        mode: "tree",
      }
    },
  }),
  computed: {
    ...mapGetters({
      entities: 'getListEntityClasses',
      entitiesTitle: 'getListEntityTitles',
    }),
  },
  methods: {
    ...mapActions({
      fetchEntities: 'fetchEntityClasses',
      fetchEntitiesTitle: 'fetchEntityTitles',
    }),
    submit() {
      this.$emit('submit', this.formData);
    }
  },
  created() {
    this.fetchEntities();
  },
  watch: {
    formProp: {
      handler() {
        if (Object.keys(this.formProp).length > 0) {
          this.formData = JSON.parse(JSON.stringify(this.formProp))
        }
      },
      intermediate: true,
    },
    'formData.entity': {
      handler() {
        if (this.formData.entity) {
          this.fetchEntitiesTitle(this.formData.entity);
        }
      }
    }
  }
};
</script>
<style scoped>
.jsoneditor-container {
  height: 800px;
}
</style>
