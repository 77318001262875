import Index from '../../views/main/teasers/Index'
import Create from '../../views/main/teasers/Create'
import Edit from '../../views/main/teasers/Edit';

export default [
  {
    component: Index,
    name: 'teasers-index',
    path: '/teasers',
    meta: {
      layout: 'main-layout',
      requiresAuth: true,
      module: 'teasers',
    }
  },
  {
    component: Create,
    name: 'teasers-create',
    path: '/teasers/create',
    meta: {
      layout: 'main-layout',
      requiresAuth: true,
      module: 'teasers',
    }
  },
  {
    component: Edit,
    name: 'teasers-edit',
    path: '/teasers/edit/:id',
    meta: {
      layout: 'main-layout',
      requiresAuth: true,
      module: 'teasers',
    }
  },
]
