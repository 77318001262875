<template>
  <v-app-bar
    app
    clipped-left
    clipped-right
    :color="navbarColor"
    :dark="navbarColor !== 'white'"
    :style="{
      background: 'rgb(124,58,237)',
      background: 'linear-gradient(90deg, rgba(124,58,237,1) 0%, rgba(248,113,113,1) 73%)',
      boxShadow: '0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)',
    }"
  >
    <!---Logo part -->
    <v-toolbar-title
      class="align-center d-flex logo-width"
      :class="`${showLogo ? 'logo-width' : ''}`"
      v-if="navbarColor !== 'white'"
    >
<!--      <span class="logo-icon">-->
<!--        <img src="../../../assets/images/logo-light-icon.png" class="mt-2" />-->
<!--      </span>-->
      <span class="logo-text ml-2" :class="`${showLogo ? '' : 'hidelogo'}`">
        <img
          v-if="currentSite.site_logo"
          class="mt-2"
          :src="currentSite.site_logo"
          :alt="getOption('siteName', '')"
        />
      </span>
    </v-toolbar-title>
    <!---Dark Logo part -->
    <v-toolbar-title
      class="align-center d-flex logo-width"
      :class="`${showLogo ? 'logo-width' : ''}`"
      v-else
    >
<!--      <span class="logo-icon">-->
<!--        <img src="../../../assets/images/logo-icon.png" class="mt-2" />-->
<!--      </span>-->
      <span class="logo-text ml-2" :class="`${showLogo ? '' : 'hidelogo'}`">
        <img
          v-if="currentSite.site_logo"
          class="mt-2"
          :src="currentSite.site_logo"
          :alt="getOption('siteName', '')"
        />
      </span>
    </v-toolbar-title>
    <!---Logo part -->
    <!---/Toggle sidebar part -->
    <div @click="showhideLogo">
      <v-app-bar-nav-icon
        @click="
          $vuetify.breakpoint.smAndDown
            ? setSidebarDrawer(!Sidebar_drawer)
            : $emit('input', !value)
        "
      />
    </div>
    <!---/Toggle sidebar part -->
    <!---Search part -->
<!--    <v-btn dark icon class="mr-1 d-sm-block d-none" @click="searchbox">-->
<!--      <v-icon>mdi-magnify</v-icon>-->
<!--    </v-btn>-->

    <v-card v-if="showSearch" class="searchinput">
      <template>
        <v-text-field
          placeholder="Search & hit enter"
          class="mt-3 mb-0"
          append-icon="mdi-close"
          @click:append="searchbox"
        ></v-text-field>
      </template>
    </v-card>
    <!---/Search part -->

    <!---Custom buttons part -->
    <v-btn
      to="/medias"
      color="primary"
      class="mr-2"
    >
      Медиа библиотека
    </v-btn>
    <v-btn
      @click="redirectToPublicSite"
      icon
      class="mr-2"
      title="Публичная версия сайта"
    >
      <v-icon>mdi-earth</v-icon>
    </v-btn>
    <template v-if="isAdministrator" class="mt-5">
      <SiteSwitcher/>
    </template>
    <v-btn
      icon
      style="text-decoration: none"
      class="mr-2"
      href="/customers"
      v-if="isVisibleCustomers"
    >
      <v-icon>
        mdi-face-agent
      </v-icon>
    </v-btn>
    <v-btn
      v-if="hasBetaRole"
      icon
      color="primary"
      style="text-decoration: none"
      to="/logs"
    >
      <v-icon>
        mdi-math-log
      </v-icon>
    </v-btn>
    <v-spacer />
    <v-btn
      v-if="isVisibleExcel"
      class="mr-2"
      color="primary"
      to="/partners/excelV2?tab=0&entity=partners"
    >
      Excel-таблицы
    </v-btn>
    <v-btn v-if="isLegasyMinfin" color="primary" href="/temp/region-statistic">
      Регионы Excel
    </v-btn>
    <!---/Custom buttons part -->

    <v-spacer />
    <!---right part -->
    <!---Notification -->
<!--    <v-menu-->
<!--      bottom-->
<!--      left-->
<!--      offset-y-->
<!--      origin="top right"-->
<!--      transition="scale-transition"-->
<!--    >-->
<!--      <template v-slot:activator="{ on }">-->
<!--        <v-btn dark icon v-on="on" class="mr-1">-->
<!--          <v-badge color="red" dot>-->
<!--            <v-icon-->
<!--              color="#757575"-->
<!--            >mdi-message</v-icon>-->
<!--          </v-badge>-->
<!--        </v-btn>-->
<!--      </template>-->

<!--      <v-list>-->
<!--        <h4 class="px-5 py-3 pt-2 font-weight-medium title">Notifications</h4>-->
<!--        <v-divider></v-divider>-->
<!--        <v-list-item v-for="(item, i) in notifications" :key="i" @click="href">-->
<!--          <v-list-item-title>-->
<!--            <div class="d-flex align-center py-3">-->
<!--              <div class>-->
<!--                <v-btn-->
<!--                  class="mr-3"-->
<!--                  depressed-->
<!--                  fab-->
<!--                  small-->
<!--                  dark-->
<!--                  :color="item.iconbg"-->
<!--                >-->
<!--                  <v-icon>{{ item.icon }}</v-icon>-->
<!--                </v-btn>-->
<!--              </div>-->
<!--              <div>-->
<!--                <h4 class="font-weight-medium">{{ item.title }}</h4>-->
<!--                <span-->
<!--                  class="text&#45;&#45;secondary caption descpart d-block text-truncate"-->
<!--                  >{{ item.desc }}</span-->
<!--                >-->
<!--                <small class="text&#45;&#45;secondary">{{ item.time }}</small>-->
<!--              </div>-->
<!--            </div>-->
<!--          </v-list-item-title>-->
<!--        </v-list-item>-->
<!--      </v-list>-->
<!--    </v-menu>-->
    <!---Notification -->
    <!---Messages -->
<!--    <v-menu-->
<!--      bottom-->
<!--      left-->
<!--      offset-y-->
<!--      origin="top right"-->
<!--      transition="scale-transition"-->
<!--    >-->
<!--      <template v-slot:activator="{ on }">-->
<!--        <v-btn dark icon v-on="on" class="mr-2">-->
<!--          <v-badge color="red" dot>-->
<!--            <v-icon-->
<!--              color="#757575"-->
<!--            >mdi-bell</v-icon>-->
<!--          </v-badge>-->
<!--        </v-btn>-->
<!--      </template>-->

<!--      <v-list>-->
<!--        <h4 class="px-5 pt-2 py-3 font-weight-medium title">Messages</h4>-->
<!--        <v-divider></v-divider>-->
<!--        <v-list-item v-for="(item, i) in messages" :key="i" @click="href">-->
<!--          <v-list-item-title>-->
<!--            <div class="d-flex align-center py-3">-->
<!--              <div class="mr-3">-->
<!--                <v-badge-->
<!--                  bordered-->
<!--                  bottom-->
<!--                  :color="item.avatarstatus"-->
<!--                  dot-->
<!--                  offset-x="10"-->
<!--                  offset-y="10"-->
<!--                >-->
<!--                  <v-avatar>-->
<!--                    <img-->
<!--                      :src="-->
<!--                        'https://www.wrappixel.com/demos/admin-templates/materialpro-bootstrap-latest/material-pro/src/assets/images/users/' +-->
<!--                        item.avatar +-->
<!--                        '.jpg'-->
<!--                      "-->
<!--                      :alt="item.title"-->
<!--                    />-->
<!--                  </v-avatar>-->
<!--                </v-badge>-->
<!--              </div>-->
<!--              <div>-->
<!--                <h4 class="font-weight-medium">{{ item.title }}</h4>-->
<!--                <span-->
<!--                  class="text&#45;&#45;secondary caption descpart d-block text-truncate"-->
<!--                  >{{ item.desc }}</span-->
<!--                >-->
<!--                <small class="text&#45;&#45;secondary">{{ item.time }}</small>-->
<!--              </div>-->
<!--            </div>-->
<!--          </v-list-item-title>-->
<!--        </v-list-item>-->
<!--      </v-list>-->
<!--    </v-menu>-->
    <!---Messages -->
    <v-menu
      bottom
      left
      offset-y
      origin="top right"
      transition="scale-transition"
    >
      <template v-slot:activator="{ on }">
        <v-btn dark icon v-on="on" class="mr-2">
          <v-icon
          >mdi-cog</v-icon>
        </v-btn>
      </template>

      <v-list>
        <h4 class="px-5 pt-2 py-3 font-weight-medium title">Настройки</h4>
        <v-divider></v-divider>
        <v-list-item v-for="(item, i) in filtrableSettingMenuItems" :key="i" @click="href" :to="item.to">
          <v-list-item-icon class="mr-2">
            <v-icon v-text="item.icon"></v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title v-text="item.title"></v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-menu>
    <v-menu
      bottom
      left
      offset-y
      origin="top right"
      transition="scale-transition"
    >
      <template v-slot:activator="{ on }">
        <v-btn dark icon v-on="on" class="mr-2">
            <v-icon
            >mdi-book-open-page-variant</v-icon>
        </v-btn>
      </template>

      <v-list>
        <h4 class="px-5 pt-2 py-3 font-weight-medium title">Справочник</h4>
        <v-divider></v-divider>
        <v-list-item v-for="(item, i) in filtrableReferenceMenuItems" :key="i" @click="href" :to="item.to">
          <v-list-item-icon class="mr-2">
            <v-icon v-text="item.icon"></v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title v-text="item.title"></v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-menu>
    <UserMenu/>
  </v-app-bar>
</template>
<script>
// Utilities
import {mapState, mapMutations, mapGetters} from "vuex";

import SiteSwitcher from "@/components/sites/SiteSwitcher";
import UserMenu from "./UserMenu";

export default {
  name: "VerticalHeader",

  components: {
    SiteSwitcher,
    UserMenu
  },

  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    showLogo: true,
    showSearch: false,
    notifications: [
      {
        title: "Launch Admin",
        iconbg: "error",
        icon: "mdi-link-variant",
        desc: "Just see the my new admin!",
        time: "9:30AM",
      },
      {
        title: "Event today",
        iconbg: "success",
        icon: "mdi-calendar-check",
        desc: "Just a reminder that you have event",
        time: "10:30AM",
      },
      {
        title: "Settings",
        iconbg: "info",
        icon: "mdi-cog",
        desc: "You can customize this template as you want",
        time: "11:30AM",
      },
      {
        title: "Pavan Kumar",
        iconbg: "indigo",
        icon: "mdi-account",
        desc: "Sent you an notification",
        time: "12:30AM",
      },
    ],
    messages: [
      {
        title: "Sonu Nigam",
        avatar: "1",
        avatarstatus: "success",
        desc: "Singing Show tonigh at 9pm!",
        time: "9:30AM",
      },
      {
        title: "Sonu Nigam",
        avatar: "2",
        avatarstatus: "error",
        desc: "The greate malody songs ever sung",
        time: "10:30AM",
      },
      {
        title: "Arijit singh",
        avatar: "3",
        avatarstatus: "warning",
        desc: "You can customize this template as you want",
        time: "11:30AM",
      },
      {
        title: "Pavan Kumar",
        avatar: "4",
        avatarstatus: "success",
        desc: "Sent you an notification",
        time: "12:30AM",
      },
    ],
    userprofile: [
      { title: "My Contacts", to: "/apps/contact" },
      { title: "My Balance", to: "/apps/contact-grid" },
      { title: "Inbox", to: "/apps/email/inbox" },
      { title: "Account Setting", to: "/form-layouts/flformbasic" },
      { title: "Logout", to: "/authentication/boxedlogin" },
    ],
    href() {
      return undefined;
    },
    referenceMenuItems: [
      {
        title: "Источники",
        to: "/sources",
        icon: "mdi-feature-search-outline",
        module: 'sources',
      },
      { title: "Персоны", to: "/persons", icon: "mdi-account", module: 'persons', },
      { title: "Саджесты", to: "/suggests", icon: "mdi-head-lightbulb", module: 'suggests', },
      { title: 'Партнеры', to: '/partners', icon: 'mdi-hand-peace', module: 'partners' },
    ],
    settingsMenuItems: [
      {title: 'Пользователи', to: '/users', icon: 'mdi-account-multiple', module: 'users'},
      { title: 'Редактировать системные фразы', to: '/system-phrases', icon: 'mdi-translate' },
      { title: 'Планировщик заданий', to: '/cron', icon: 'mdi-timer' },
      { title: 'Каталоги', to: '/catalogs', icon: 'mdi-store-check' },
      { title: "Права", to: "/permissions", icon: "mdi-account-key", module: 'permissions' },
      { title: "Динамические поля", to: "/fields", icon: "mdi-form-textbox", module: 'fields' },
      { title: "Группы полей", to: "/field-groups", icon: "mdi-form-select", module: 'fields' },
      { title: "Блоки", to: "/blocks", icon: "mdi-dots-square", module: 'blocks' },
      { title: "Группы блоков", to: "/block-groups", icon: "mdi-format-list-bulleted-square", module: 'block_groups'},
      { title: 'Настройки блоков', to: "/block-settings", icon: 'mdi-wrench', module: 'blocks' },
      { title: "Роли", to: "/roles", icon: "mdi-account-supervisor", module: 'roles', needAccess: 'permission.view' },
      { title: 'Партнерские ресурсы', to: '/partner-resources', icon: 'mdi-handshake', module: 'partner_resources' },
      { title: 'Сайты', to: '/sites', icon: 'mdi-web', module: 'sites' },
      { title: 'Типы отзывов', to: '/reviews/types', icon: 'mdi-message-cog-outline', module: 'review_types' },
      {title: 'Формы', to: '/forms', icon: 'mdi-form-select', module: 'forms'},
      {title: 'Фасетная таксономия', to: '/collections', icon: 'mdi-dns', module: 'collections'},
      {title: 'История', to: '/histories', icon: 'mdi-notebook-edit', module: 'history'},
    ],
    filtrableReferenceMenuItems: [],
    filtrableSettingMenuItems: []
  }),

  computed: {
    ...mapState(["navbarColor", "Sidebar_drawer"]),
    ...mapGetters({
      getOption: 'options/getOption',
      isAdministrator: 'auth/isAdministrator',
      hasBetaRole: 'auth/hasBetaRole',
      currentSite: 'SiteSwitcher/getSelectedSite',
    }),
    isVisibleExcel() {
      return process.env.VUE_APP_IS_PRYAMAYA !== 'true';
    },
    isVisibleCustomers() {
      return process.env.VUE_APP_M_CUSTOMERS == 'true';
    },
    isLegasyMinfin() {
      return process.env.VUE_APP_LEGASY_MINFIN == 'true';
    }
  },
  created() {
    this.filterMenu();
  },
  methods: {
    ...mapMutations({
      setSidebarDrawer: "SET_SIDEBAR_DRAWER",
    }),
    showhideLogo: function () {
      this.showLogo = !this.showLogo;
    },
    searchbox: function () {
      this.showSearch = !this.showSearch;
    },
    redirectToPublicSite() {
      window.open(process.env.VUE_APP_CLIENT_URL, "_blank");
    },
    filterMenu() {
      this.filtrableReferenceMenuItems = this.referenceMenuItems.filter((item) => {
        if (item.needAccess && this.can(item.needAccess) && item.module && process.env['VUE_APP_M_'+item.module.toUpperCase()] === "true") {
          return true;
        } else if (!item.needAccess && item.module && process.env['VUE_APP_M_'+item.module.toUpperCase()] === "true") {
          return true;
        } else if (!item.needAccess && !item.module) {
          return true;
        }

        return false
      });

      this.filtrableSettingMenuItems = this.settingsMenuItems.filter((item) => {
        if (item.needAccess && this.can(item.needAccess) && item.module && process.env['VUE_APP_M_'+item.module.toUpperCase()] === "true") {
          return true;
        } else if (!item.needAccess && item.module && process.env['VUE_APP_M_'+item.module.toUpperCase()] === "true") {
          return true;
        } else if (!item.needAccess && !item.module) {
          return true;
        }

        return false
      });
    }
  },
};
</script>

<style lang="scss">
.hidelogo {
  display: none;
}
.searchinput {
  position: absolute;
  width: 100%;
  margin: 0;
  left: 0;
  z-index: 10;
  padding: 0 15px;
}
.descpart {
  max-width: 220px;
}
</style>
