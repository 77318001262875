<template>
  <v-form>
    <v-text-field
        v-model="form.title"
        :error-messages="errors.title"
        label="Название"
        name="title"
        required
    ></v-text-field>
    <v-text-field
        v-model="form.slug"
        :error-messages="errors.slug"
        label="Slug"
        name="slug"
        :disabled="!(Object.keys(collection).length === 0)"
    ></v-text-field>
    <v-autocomplete
      v-if="partnerResources"
      v-model="form.partner_resource_id"
      :error-messages="errors.partner_resource_id"
      :items="partnerResources"
      item-text="title"
      item-value="id"
      label="Выберите партнерский ресурс"
      name="partner_resource_id"
      required
    >
    </v-autocomplete>
    <v-autocomplete
      v-model="form.modules"
      :error-messages="errors.modules"
      :items="modules"
      label="Модули"
      name="modules"
      required
      multiple
      chips
    ></v-autocomplete>
    <v-checkbox
      v-model="form.is_use_filter"
      :error-messages="errors.is_use_filter"
      label="Использовать в фильтрации"
      name="is_use_filter"
    ></v-checkbox>
    <v-checkbox
      v-model="form.is_main"
      :error-messages="errors.is_main"
      label="Главная коллекция"
      name="is_main"
    ></v-checkbox>
    <v-checkbox
      v-model="form.is_personalization"
      :error-messages="errors.is_personalization"
      label="Участвует в персонализации"
      name="is_personalization"
    ></v-checkbox>
    <v-btn
        color="primary"
        @click="submit"
        :disabled="isSaveDisabled"
        :loading="isSaveDisabled"
    >
      Сохранить
    </v-btn>
  </v-form>
</template>

<script>
import {PartnerResource} from "../../models/partnerResource";
import modules_statuses from '../../../../modules_statuses.json';
import slugify from "slugify";
import optionsMixin from "../../mixins/optionsMixin";

export default {
  name: "CollectionForm",
  mixins: [optionsMixin],
  props: {
    collection: {
      type: Object,
      default: () => ({})
    },
    errors: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    form: {
      id: null,
      title: null,
      slug: null,
      is_use_filter: false,
      language: null,
      is_main: false,
      partner_resource_id: null,
      is_personalization: false,
    },
    languages: [
      { text: 'Русский', value: 'ru' },
      { text: 'English', value: 'en' },
    ],
    partnerResources: [],
    modules: [],
    isSaveDisabled: false,
  }),
  created() {
    if (this.$route.params.data) {
      this.form = JSON.parse(this.$route.params.data);
    }
    this.form.language = this.language;

    this.getPartnerResources();

    this.modules = Object.keys(modules_statuses);
  },
  methods: {
    submit() {
      this.isSaveDisabled = true;
      this.$emit('onSubmit', this.form);
    },
    async getPartnerResources() {
      try {
        const partnerResources = await PartnerResource.$query().get(10000);
        this.partnerResources = partnerResources.map(partnerResource => partnerResource.$attributes);
      } catch (e) {
        throw e;
      }
    },
  },
  watch: {
    collection: {
      handler(collection) {
        if (collection && Object.keys(collection).length > 0) {
          this.form = collection;
          this.form.language = this.language;
          this.isSaveDisabled = false;
        }
      },
      immediate: true,
      deep: true,
    },
    'form.title': {
      handler(value) {
        if (value && Object.keys(this.collection).length === 0) {
          this.form.slug = slugify(value.toLowerCase()).match(/[a-z]|[0-9]|[-]/gm).join('');
        }
      }
    }
  },
}
</script>
